
import React, { Component } from 'react';
import Card from '../common/Card.js';
import { HEADING } from '../../utils/strings.utils';
//import Chart from '../common/Chart';
import { getDashboardDetails, getGamesDetails, getScrollingText, getTournamentDetails, routeTo, getScrollingNormalText } from '../../utils/common.utils';
//import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import { BREADCRUMBS } from '../../utils/breadcrumbs.utils';
import Graphs from '../common/Graphs';
import { Dropdown } from 'react-bootstrap';
import { TableComponent } from '../common/DataTable';
import LineCharts from '../common/LineCharts';

const disputeRanges = [
    {
        name : 'ALL',
        disputeUpperRange : null,
        disputeLowerRange : null,
    },
    {
        name : 'Dispute : 0 to 10',
        disputeUpperRange : 10,
        disputeLowerRange : 0,
    },
    {
        name : 'Dispute : 11 to 20',
        disputeUpperRange : 20,
        disputeLowerRange : 11
    },
    {
        name : 'Dispute : 21 to 30',
        disputeUpperRange : 30,
        disputeLowerRange : 21
    },
    {
        name : 'Dispute : 31 to 40',
        disputeUpperRange : 40,
        disputeLowerRange : 31
    },
    {
        name : 'Dispute : 41 to 50',
        disputeUpperRange : 50,
        disputeLowerRange : 41
    },
    {
        name : 'Dispute : 51 and above',
        disputeUpperRange : 100,
        disputeLowerRange : 51
    }
];

const ratingRanges = [
    {
        name : "ALL",
        overallRatingLowerRange : null,
        overallRatingUpperRange : null
    },
    {
        name : "Rating : 0 to 1",
        overallRatingLowerRange : 0,
        overallRatingUpperRange : 1
    },
    {
        name : "Rating : 1 to 2",
        overallRatingLowerRange : 1,
        overallRatingUpperRange : 2
    },
    {
        name : "Rating : 2 to 3",
        overallRatingLowerRange : 2,
        overallRatingUpperRange : 3
    },
    {
        name : "Rating : 3 to 4",
        overallRatingLowerRange : 3,
        overallRatingUpperRange : 4
    },
    {
        name : "Rating : 4 to 5",
        overallRatingLowerRange : 4,
        overallRatingUpperRange : 5
    },
    {
        name : "Rating : 5 Start",
        overallRatingLowerRange : 5,
        overallRatingUpperRange : 5
    }
]

export default class Home extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            dashboardDetails : null,
            gameDetails: null,
            selectedGameindex : 0,
            tournamentDetails : null,
            selectedDisputeRange : disputeRanges[0],
            selectedRating : ratingRanges[0],
            selectedGameIndex : null,
            searchValue : null,
            totalResult : 0,
            queryParams : {
                limit : 10,
                pageNo : 1,
                gameId : null,
                disputeUpperRange : null,
                disputeLowerRange : null,
                overallRatingLowerRange : null,
                searchValue : null
            }
        };
    }

    componentDidMount(){
        setTimeout(()=>{
            getDashboardDetails(this.setDashboardDetails);
            getGamesDetails(this.setGamesDetails);
            this.applyFilter(this.state.queryParams);
        }, 1000);
    }

    setGamesDetails = (gameDetails) =>{
        this.setState({
          gameDetails: gameDetails
        });
    }

    setDashboardDetails = (dashboardDetails) =>{
        
        var arr = ["Espotz Users", "Tournament Operators", "Teams", "Den Users"]
        var arr2 = ["REGULAR","PREMIUM","DISPUTE","AVERAGE"];
        if(dashboardDetails){
            dashboardDetails.usersCount.forEach((element,i) => {
                element.type = element.name ;
                element.name = arr[i]
            });
            dashboardDetails.tournamentsCount.forEach((element,i) => {
                element.type = arr2[i];
                //element.type = arr2[i]
            });
        }
        //dashboardDetails.usersCount = dashboardDetails.Usercount;
        delete dashboardDetails.Usercount;
        this.setState({
            dashboardDetails: dashboardDetails
        })
    
    }

    setTournamentDetails= (tournamentDetails,totalResult) =>{
        this.setState({
            tournamentDetails : tournamentDetails,
            totalResult: totalResult

        })
    }

    filter = (type, value) => {
        var queryParams = this.state.queryParams;
        switch (type) {
            case 'disputeRange':
                this.setState({
                    selectedDisputeRange: value
                }); 
                queryParams.disputeLowerRange = value.disputeLowerRange;
                queryParams.disputeUpperRange = value.disputeUpperRange;
                this.applyFilter(queryParams);
                break;
            case 'overallRating':
                this.setState({
                    selectedRating: value
                });    
                queryParams.overallRatingLowerRange = value.overallRatingLowerRange;
                queryParams.overallRatingUpperRange = value.overallRatingUpperRange;
                this.applyFilter(queryParams);
                break;
            case 'gameIndex':
                this.setState({
                    selectedGameIndex: value
                });
                queryParams.gameId =  this.state.gameDetails[value]._id;
                this.applyFilter(queryParams);
                break;
            default:
                break;
        }
    }

    applyFilter = (queryParams) => {
        this.setState({
            queryParams : queryParams
        });
        
        Object.keys(queryParams).map((key,i)=>{
            if(queryParams[key] === null){
                delete queryParams[key]
            }
        });
        getTournamentDetails(queryParams, this.setTournamentDetails);
    }

    changeValue = (val) => {
        var queryParams = this.state.queryParams;
        queryParams.searchValue = val ? val : null;
        this.applyFilter(queryParams);
    }

    onPagination = (i, key) => {
        var queryParams = this.state.queryParams;
        queryParams[key] = ((key === 'pageNo') ? (i + 1) : i);
        this.applyFilter(queryParams);
    }

    render() {
   

        if(this.state.dashboardDetails && this.state.tournamentDetails && this.state.gameDetails){
           

            const columns = [
                {
                    name: getScrollingText(HEADING.TOURNAMENTS.toUpperCase()),
                    selector: (row) => [row.tournamentName?.length < 8 ? row.tournamentName : getScrollingNormalText(row.tournamentName)],
                    sortable: true,
                },
                {
                    name: HEADING.GAME.toUpperCase(),
                    selector: (row) => [row.gameName],
                    sortable: true,
                },
                {
                    name: getScrollingText(HEADING.TOTAL_REG_USERS.toUpperCase()),
                    selector: (row) => [row.totalRegisteredUsers],
                    sortable: true,
                },
                {
                    name: getScrollingText(HEADING.TOTAL_DISPUTES.toUpperCase()),
                    selector: (row) => [row.totalDisputes],
                    sortable: true,
                },
                {
                    name: getScrollingText(HEADING.RESOLVED_DISPUTES.toUpperCase()),
                    selector: (row) => [row.resolvedDisputes],
                    sortable: true,
                },
                {
                    name: getScrollingText(HEADING.TOURNAMENTS_ENTRY_FEES.toUpperCase()),
                    selector: (row) => [row.tournamentEntryFee],
                    sortable: true,
                },
                {
                    name: getScrollingText(HEADING.MIN_TICKET_FEE.toUpperCase()),
                    selector: (row) => [row.minBettingTicketFee],
                    sortable: true,
                },
                {
                    name: getScrollingText(HEADING.TO_TOURNAMENT_DEPOSIT.toUpperCase()),
                    selector: (row) => [row.toTournamentDeposit],
                    sortable: true,
                },
                {
                    name: getScrollingText(HEADING.TO_BET_DEPOSIT.toUpperCase()),
                    selector: (row) => [row.toBettingDeposit],
                    sortable: true,
                },
                {
                    name: getScrollingText(HEADING.BET_COLLECTION.toUpperCase()),
                    selector: (row) => [row.bettingCollection],
                    sortable: true,
                },
                {
                    name: getScrollingText(HEADING.TOURNAMENTS_PRICE_POOL.toUpperCase()),
                    selector: (row) => [row.tournamentPrizePool],
                    sortable: true,
                },
                {
                    name: getScrollingText(HEADING.BET_PRICE_POOL.toUpperCase()),
                    selector: (row) => [row.bettingPrizePool],
                    sortable: true,
                },
                {
                    name: HEADING.REFUND.toUpperCase(),
                    selector: (row) => [row.refunds],
                    sortable: true,
                },
                {
                    name: HEADING.RATING.toUpperCase(),
                    selector: (row) => [row.rating],
                    sortable: true,
                },
                
            ]

            return (
                <div className="app-main__inner">
                    <div className="d-flex flex-column flex-lg-row">
                        <div className="flex-grow-1">
                            <h3 className='text-white'>{BREADCRUMBS.HOME.HEADING}</h3>
                        </div>
                        <div className="">
                            <ol className="breadcrumb color-white" style={{ background: 'none', padding: '0px' }}>
                                {BREADCRUMBS.HOME.PATH.map((route,i)=>{
                                    return <li key={i} className="breadcrumb-item cursor-pointer" onClick={() => { routeTo('/','dashboard')  }}>{route}</li>
                                })}
                            </ol>
                        </div>
                    </div>
                    <Card dashboardDetails={this.state.dashboardDetails} />
                    
                    <div className="row">
                        <div className="col-md-12 col-lg-9 mb-5">
                            <div className='card card transperent-card'  style={{minHeight:'475px'}}>
                                <h4 className='my-2 text-center text-white'>Upcoming Events</h4>
                                <hr class="seperator my-0" />
                                <div className="p-4">
                                    <LineCharts dashboardDetails={this.state.dashboardDetails} />
                                </div>
                                <div className="p-2 d-flex justify-content-center">
                                    <p className='my-2 fw-bold sub-text opacity-75 text-center' style={{width: "80%"}}>Representation of all the winning and losing number of bets that has been recorded on our espotz platform on. On X-axis you can find Months plotted, and on Y-axis you will find number of bets recorded.</p>
                                </div>

                                {/* <Chart dashboardDetails={this.state.dashboardDetails} /> */}
                            </div>
                        </div>

                        <div className="col-md-12 col-lg-3 mb-5">
                            <div className="card transperent-card" style={{minHeight:'475px'}}>
                                <h4 className='my-2 text-center text-white'>Upcoming Events</h4>
                                <hr class="seperator my-0" />
                                <div className="card-body d-flex flex-column align-items-center">
                                    <Graphs percentage={parseFloat(this.state.dashboardDetails.populerStage[this.state.selectedGameindex].percent)} />
                                    <Dropdown className='my-2 w-100'>
                                        <Dropdown.Toggle variant="primary" id="dropdown-basic" className='w-100'>
                                            {this.state.dashboardDetails.populerStage[this.state.selectedGameindex].name}
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            {this.state.dashboardDetails.populerStage.map((stage,i)=>{
                                                if(this.state.selectedGameindex !== i){
                                                    return <Dropdown.Item key={i} onClick={()=>this.setState({ selectedGameindex: i})}> {stage.name} </Dropdown.Item>
                                                }
                                            })}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    <p className='my-2 fw-bold sub-text opacity-75'>{this.state.dashboardDetails.populerStage[this.state.selectedGameindex].stage}</p>
                                    <p className='my-2 opacity-50 fw-bold sub-text opacity-50'>last 6 months</p>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="row">
                            

                        <div className="col-md-12">
                            <div className="main-card mb-3 card transperent-card text-white p-4">
                                <div className='row'>
                                    <div className='col-lg-2 col-md-12 mb-2'>
                                        <label>Games</label>
                                        <Dropdown>
                                            <Dropdown.Toggle variant="success" className='form-control rounded transperent-box light-border-around text-white w-100' id="dropdown-basic">
                                                <b >{this.state.selectedGameIndex !== null ? this.state.gameDetails[this.state.selectedGameIndex].name : 'ALL'}</b>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item key={-1} onClick={()=>this.filter('gameIndex', null)}>ALL</Dropdown.Item>
                                                {this.state.gameDetails.map((game,i)=>{
                                                    return <Dropdown.Item className={this.state.selectedGameIndex === i && 'selected-option'} disabled={this.state.selectedGameIndex === i} key={i} onClick={()=>this.filter('gameIndex', i)}>{game.name}</Dropdown.Item>
                                                })}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                    <div className='col-lg-2 col-md-12 mb-2'>
                                    <label>Ratings</label>
                                        <Dropdown>
                                            <Dropdown.Toggle variant="success" className='form-control rounded transperent-box light-border-around text-white w-100' id="dropdown-basic">
                                                <b >{this.state.selectedRating.name}</b>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                {ratingRanges.map((rating,i)=>{
                                                    return <Dropdown.Item className={this.state.selectedRating === rating && 'selected-option'} disabled={this.state.selectedRating === rating}  key={i} onClick={()=>this.filter('overallRating', rating)}>{rating.name}</Dropdown.Item>
                                                })}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                    <div className='col-lg-2 col-md-12 mb-2'>
                                    <label>Disputes</label>
                                        <Dropdown>
                                            <Dropdown.Toggle variant="success" className='form-control rounded transperent-box light-border-around text-white w-100' id="dropdown-basic">
                                                <b >{this.state.selectedDisputeRange.name}</b>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                {disputeRanges.map((range,i)=>{
                                                    return <Dropdown.Item className={this.state.selectedDisputeRange === range && 'selected-option'} disabled={this.state.selectedDisputeRange === range}  key={i} onClick={()=>this.filter('disputeRange', range)}>{range.name}</Dropdown.Item>
                                                })}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                    <div className='col-lg-2 col-md-12 mb-2'>

                                    </div>
                                    <div className='col-lg-4 col-md-12 mb-2'>
                                    <label>Search</label>
                                        <div className="btn-actions-pane-right">
                                            <div className="input-group icons search-box">
                                                <input onChange={(e)=>this.changeValue(e.target.value)} defaultValue={this.state.searchValue} type="search" className="form-control rounded text-white transperent-box light-border-around" placeholder="Search in table ...." />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                              
                                
                                <div className='row'>
                                    <TableComponent  column={columns} data={this.state.tournamentDetails} paginationTotalRows={this.state.totalResult} onPagination={this.onPagination} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            
            );
        }else{
            return(
                <div>

                </div>
            )
        }
    }
}