import * as axios from "axios";
import { token } from "../utils/common.utils";
//import axios from "axios";

export default class NewFeatureRequest {
    constructor() {
        this.client = null;
        this.api_token = null;
        this.api_url = process.env.REACT_APP_API_URL;
    }
    queryParams(params) {
        return Object.keys(params)
            .map(key => `${key}=${params[key]}`)
            .join('&');
    }
    init = () => {
        this.api_token = token();
        let headers = {
            Accept: "application/json",
        };

        if (this.api_token) {
            headers.Authorization = this.api_token;
        }

        this.client = axios.create({
            baseURL: this.api_url,
            timeout: 31000,
            headers: headers,
        });

        return this.client;
    };

      
    getFeatureRequests = () => {
        return this.init().get(`/admin/api/v1/featureRequest/getFeatureRequest`);
    };

    deleteFeatureRequest = (id) => { 
        return this.init().delete(`/admin/api/v1/featureRequest/deleteFeatureRequest/${id}`);
    }
 
}