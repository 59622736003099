import { Component } from 'react'
import { TableComponent } from '../common/DataTable';
import UserService from '../../services/user.service';
import { BREADCRUMBS } from '../../utils/breadcrumbs.utils';
import { convertDateTime, errorHandler, getClanOrgMembers, routeTo, updateLoader } from '../../utils/common.utils';
import Dropdown from 'react-bootstrap/Dropdown';
import { DebounceInput } from 'react-debounce-input';
import { createdAt } from '../../utils/dateTime.utils';

const DROPDOWN_OPTIONS = [
    // { name: 'Org ID', term: '_id' },
    { name: 'Org Name', term: 'name' },
]
export default class Organisation extends Component {
    constructor(props) {
        super(props)
        this.state = {
            organisations: [],
            totalResult: 0,
            params: {
                pageNo: 0,
                limit: 10
            },
            selectedIndex: 0,
        }
        this.authApi = new UserService();
        this.columns = [
            // {
            //     name: "ORG ID",
            //     selector: (row) => [row._id],
            //     sortable: false,
            // },
            {
                name: "ORG'S NAME",
                selector: (row) => [row.name],
                sortable: false,
            },
            {
                name: "DEN",
                selector: (row) => [row.den ? 'Yes' : 'No'],
                sortable: false,
            },
            {
                name: "KYC",
                selector: (row) => [row.kycVerified ? 'Yes' : 'No'],
                sortable: false,
            },
            {
                name: "VERIFIED",
                selector: (row) => [(row.verificationStatus === 'VERIFIED') ? 'Yes' : 'No'],
                sortable: false,
            },
            {
                name: "RATING",
                selector: (row) => [row.organizationRating ? row.organizationRating : 'NA'],
                sortable: false,
            },
            // {
            //     name: "CONTACT PERSON",
            //     selector: (row) => [row.contactPersonName],
            //     sortable: false,
            // },
            {
                name: "TOURNAMENTS",
                selector: (row) => [row.pastTournamentCount],
                sortable: false,
            },
            // {
            //     name: "WEBSITE",
            //     selector: (row) => [row.microWebsiteLink],
            //     sortable: false,
            // },
            {
                name: "MEMBERS",
                selector: (row) => [getClanOrgMembers(row.members)],
                sortable: false,
            },
            {
                name: "ORG'S AGE",
                selector: (row) => [createdAt(row.createdAt)],
                sortable: false,
            },
        ]
    }
    componentDidMount() {
        this.getOrgs(this.state.params)
    }
    onPagination = (i, key) => {
        let paramsTemp = this.state.params
        paramsTemp[key] = i
        this.setState({ params: paramsTemp })
        console.log(`${key}`, paramsTemp)
        this.getOrgs(paramsTemp)
    }
    onSearch = (val) => {
        let params = {
            [DROPDOWN_OPTIONS[this.state.selectedIndex].term]: val,
            pageNo: 0,
            limit: this.state?.params?.limit ?this.state.params.limit : 10
        }
        if (!val) delete params[DROPDOWN_OPTIONS[this.state.selectedIndex].term];
        this.setState({ params: params })
        this.searchOrgs(params)
    }
    getOrgs = async (params) => {
        updateLoader(true)
        this.authApi.getAllOrgs(params).then(
            (res) => {
                let organisations = res.data.result.map((el, i) => { el.index = i; return el })
                this.setState({ organisations: organisations, totalResult: res.data.totalResults })
                console.log("res - ", res.data.result)
                updateLoader(false)
            }
        ).catch(
            (err) => {
                console.error(err)
                updateLoader(false)
                errorHandler(err)
            }
        )
    };
    searchOrgs = async (params) => {
        updateLoader(true)
        this.authApi.getAllOrgs(params).then(
            (res) => {
                let organisations = res.data.result.map((el, i) => { el.index = i; return el })
                this.setState({ organisations: organisations, totalResult: res.data.totalResults })
                updateLoader(false)
            }
        ).catch(
            (err) => {
                console.error(err)
                updateLoader(false)
                errorHandler(err)
            }
        )
    };
    render() {
        return (
            <div className="app-main__inner">
                <div className="d-flex flex-column flex-lg-row">
                    <div className="flex-grow-1">
                        <h3 className='text-white'>{BREADCRUMBS.USERS_ORGS.HEADING}</h3>


                    </div>
                    <div className="">
                        <ol className="breadcrumb color-white" style={{ background: 'none', padding: '0px' }}>
                            {BREADCRUMBS.USERS_ORGS.PATH.map((route, i) => {
                                return <li key={i} className="breadcrumb-item cursor-pointer" onClick={() => { routeTo('/', 'dashboard') }}>{route}</li>
                            })}
                        </ol>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="main-card mb-3 card transperent-card text-white p-4">
                            <div className="card-header no-background p-0 mb-2">
                                <div className="btn-actions-pane-right">
                                    <div className="input-group icons search-box">
                                        <Dropdown>
                                            <Dropdown.Toggle variant="success" className='h-45 transperent-box light-border-around text-white' id="dropdown-basic">
                                                <b className='px-5'>{DROPDOWN_OPTIONS[this.state.selectedIndex].name}</b>

                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                {
                                                    DROPDOWN_OPTIONS.map((game, i) => {
                                                        return <Dropdown.Item key={i} onClick={() => this.setState({ selectedIndex: i })}>{game.name} </Dropdown.Item>
                                                    })
                                                }
                                            </Dropdown.Menu>
                                        </Dropdown>
                                        <DebounceInput minLength={3}
                                            debounceTimeout={500}
                                            type="search"
                                            maxLength={100}
                                            className="ml-2 form-control rounded text-white transperent-box light-border-around"
                                            placeholder="Search in table ...."
                                            onChange={(e) => { this.onSearch(e.target.value) }} />
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <TableComponent column={this.columns} data={this.state.organisations} paginationTotalRows={this.state.totalResult} onPagination={this.onPagination} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
