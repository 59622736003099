
import React, { Component } from 'react';
import { WEBSITE } from '../../utils/strings.utils';
import { getMaxLenght, getWebsiteDetails, routeTo, updateWebsiteDetails, uploadSingleImg } from '../../utils/common.utils';
import { BREADCRUMBS } from '../../utils/breadcrumbs.utils';
import "../../assets/css/switch.css";
import "../../assets/css/game.css";
import { toast } from 'material-react-toastify';
import { Modal } from 'react-bootstrap';
import Confirmation from '../common/Confirmation';
import { AiFillCloseCircle } from 'react-icons/ai';

export default class News extends Component {

    constructor(props) {
        super(props);
        this.state = { 
          websiteDetails : null,
          selectedChoice : 0,
          updateValue : null,
          openConfirmationModal : false,
          deleteIndex : -1,
          editQuestionIndex: '0.0',
          selectedGameId: null
        };
    }
  
    componentDidMount(){
      getWebsiteDetails(`type=NEWS`,this.setwebsiteDetails);
    }

    setwebsiteDetails = (websiteDetails) =>{
      if(websiteDetails){
        websiteDetails = websiteDetails.sort((a, b) => a.subType.toLowerCase().localeCompare(b.subType.toLowerCase()))
        this.setState({
          websiteDetails: websiteDetails,
          updateValue : null,
          openConfirmationModal : false,
          deleteIndex : -1,
          editQuestionIndex : '0.0',
          selectedGameId: null
        });
      }
    }

    // Save chaged value in state (temporary)
    changeValue = (val) =>{
      this.setState({
        updateValue: (val ? val : "")
      });
    }

    addAnotherNews = () => {
      var updatedNEWS = this.state.websiteDetails[this.state.selectedChoice].NEWS;
      let news = {
        NEWS_BANNER: null,
        NEWS_HEADING: null,
        NEWS_SOURCE: null,
        NEWS_SUMMARY: null
      }
      updatedNEWS.push(news);
      updateWebsiteDetails(this.state.websiteDetails[this.state.selectedChoice]._id, {NEWS : updatedNEWS}, this.setwebsiteDetails);
    }

    enableEditOption = (index, i, key) =>{
      this.setState({
        editQuestionIndex: index,
        updateValue : null
      });
    }

    saveAndCloseEditOption = (index, i, key) =>{
      if(this.state.updateValue){
        var updatedNEWS= this.state.websiteDetails[this.state.selectedChoice].NEWS;
        updatedNEWS[i][key] = this.state.updateValue;
        updateWebsiteDetails(this.state.websiteDetails[this.state.selectedChoice]._id, {NEWS : updatedNEWS}, this.setwebsiteDetails);
      }else{
        this.setState({
          editQuestionIndex: '0.0'
        });
      }
    }

    // On Delete confirmation
    confirmDelete = () => {
      var updatedNEWS = this.state.websiteDetails[this.state.selectedChoice].NEWS;
      updatedNEWS.splice((this.state.deleteIndex), 1);
      updateWebsiteDetails(this.state.websiteDetails[this.state.selectedChoice]._id, {NEWS : updatedNEWS}, this.setwebsiteDetails);
    }

    onImageChange = event => {
      this.setState({
        invalidImage: null,
      });
  
      if (event.target.files && event.target.files[0]) {
        let img = event.target.files[0];
        
        if (!img.name.match(/\.(jpg|jpeg|png)$/)) {
          toast.warning('Please select a valid image type.');
          return false;
        }
        const formData = new FormData();
        formData.append('file', event.target.files[0]);
        uploadSingleImg(formData, this.setUpdatedImage);
      }
    
    }

    setUpdatedImage = (data, index?) => {
      var updatedNEWS = this.state.websiteDetails[this.state.selectedChoice].NEWS;
      if(index !== undefined){
        updatedNEWS[index]["NEWS_BANNER"] = data;
      }else{
        updatedNEWS[this.state.selectedGameId]["NEWS_BANNER"] = data;
      }
      updateWebsiteDetails(this.state.websiteDetails[this.state.selectedChoice]._id, {NEWS : updatedNEWS}, this.setwebsiteDetails);
    }

    setGamesDetails = (gameDetails) =>{
      if(gameDetails){
        this.setState({
          gameDetails: gameDetails,
          selectedGameId: null
        });
      }
    }

    triggerInputFile = (index) => {
      this.setState({
        selectedGameId : index
      });
      this.fileInput.click();
    };

    render() {
        

        if(this.state.websiteDetails){
          const {editQuestionIndex} = this.state;
      
          return (
            <div className="app-main__inner">
                {/* HEAD */}
                <div className="d-flex flex-column flex-lg-row">
                    {/* MAIN-HEAD */}
                    <div className="flex-grow-1">
                        <h3 className='text-light-white'>{BREADCRUMBS.NEWS.HEADING}</h3>
                    </div>
                    {/* BRAD-CRUMB */}
                    <div className="">
                        <ol className="breadcrumb color-white" style={{ background: 'none', padding: '0px' }}>
                            {BREADCRUMBS.NEWS.PATH.map((route,i)=>{
                                return <li key={i} className="breadcrumb-item cursor-pointer" onClick={() => { routeTo('/','dashboard')  }}>{route}</li>
                            })}
                        </ol>
                    </div>
                </div>
                {/* BODY */}
                <div className="row">
                  <div className="col-md-12">
                    <div className="main-card mb-3 card transperent-card text-white p-4">
                      {/* SUB-HEAD */}
                      <div className="card-header no-background p-0 mb-2 ">   Information about current events
                          {/*ADD FILTER */}
                          
                      </div>
                      {/* MAIN_BODY */}
                      {this.state.websiteDetails[this.state.selectedChoice].NEWS.map((news,i)=>{
                        return(
                          <div className='col-12 my-2 question-answer' key={i}>
                            {Object.keys(this.state.websiteDetails[this.state.selectedChoice].NEWS[i]).map((key,index)=>{
                              const currentIndex = `${i+1}.${index+1}`;
                              if(key !== "NEWS_BANNER"){
                                
                                const isEditEnabled = (editQuestionIndex === currentIndex);
                                const actionLabel = isEditEnabled ? 'Save' : 'Edit';
                                const action = isEditEnabled ? this.saveAndCloseEditOption : this.enableEditOption;
                                console.log('NEWS KEY ::', key);
                                return (
                                  <div className='my-3' key={index}>
                                    <label><span className=' font-bold'>News #{i+1} - {WEBSITE.NEWS[key]}</span></label>
                                    <div className="input-group">
                                     <input type="text" 
                                        id={key+`${i+1}.1`}
                                        readOnly={!isEditEnabled}
                                        defaultValue={news[key] ? news[key] : ""}
                                        className="form-control transperent-box light-border-around text-gray"
                                        style={{borderTopLeftRadius:'10px', borderBottomLeftRadius: '10px'}}
                                        placeholder={`Enter ${WEBSITE.NEWS[key]} of news no. ${i+1}`} 
                                        onChange={(e)=>this.changeValue(e.target.value)} 
                                        autoComplete="off"
                                        maxLength={getMaxLenght(key)}
                                      />
                                      {(key === "NEWS_HEADING") ? 
                                        <div className="input-group-append ">
                                          <button className="btn btn-primary light-border-around fw-bold" onClick={()=> action(`${i+1}.${index+1}`, i, key) } type="button">{actionLabel}</button>
                                          <button onClick={()=>this.setState({ deleteIndex: i, openConfirmationModal: true })} className="btn btn-primary light-border-around fw-bold rounded-border-right" type="button">Delete</button>
                                        </div>
                                      :
                                        <div className="input-group-append">
                                          <button onClick={()=> action(`${i+1}.${index+1}`, i, key) } className="btn edt-btn light-border-around rounded-border-right" type="button" > {actionLabel} </button>
                                        </div>
                                      }
                                    </div>
                                   </div>
                                )
                              }
                               
                            })}  

                            {/* Banner */}
                            {news?.NEWS_BANNER?.url ?
                              <div className='my-3' key={i}>
                                <label><span className=' font-bold'>News #{i+1} - {WEBSITE.NEWS["NEWS_BANNER"]}</span></label>
                                <div  className='d-flex flex-column'>
                                  <div className='d-flex flex-column mx-auto'>
                                    <AiFillCloseCircle className='cursor-pointer ms-auto' onClick={()=> this.setUpdatedImage(null,i)} style={{height:'20px', width: '20px', marginBottom: '-10px', marginRight: '-10px', zIndex: 1}}  />
                                    <img src={news?.NEWS_BANNER?.url} className='mx-auto' alt='' style={{height:'200px', width:'auto'}} />
                                  </div>
                                </div>
                                {/* <div className='camera-container ms-auto cursor-pointer' style={{zIndex:'1'}} onClick={()=> this.setUpdatedImage(null,i)}>
                                  <AiFillCloseCircle className='close-icon'  />
                                </div> */}
                              </div>
                              
                              
                            :
                              <div className='my-3' key={i}>
                                <label><span className=' font-bold'>News #{i+1} - {WEBSITE.NEWS["NEWS_BANNER"]}</span></label>
                                <input type="file" id="upload_file" accept=".png, .jpg, .jpeg" hidden onChange={this.onImageChange} ref={fileInput => this.fileInput = fileInput} name="" className="profile-picker" />
                                <div className="input-group cursor-pointer" onClick={()=>this.triggerInputFile(i)}>
                                  <div className='form-control transperent-box  light-border rounded-border-left rounded-border-right text-gray d-flex flex-column justify-content-center align-items-center' style={{height: "150px"}} >
                                    <p className='text-white-50 text-center my-0' style={{maxWidth:'280px'}}>Please click here to select and upload banner image.(.png, .jpg, .jpeg)</p>
                                  </div>
                                </div>
                              </div>
                            }   
                          </div>  
                        )


                      })}
                      <div className='d-flex '>
                        <div className="my-2 ms-2" >
                          <button type="button" onClick={this.addAnotherNews} className="btn btn-primary">{WEBSITE.NEWS.ADD_MORE}</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Modal show={this.state.openConfirmationModal} centered dialogClassName=''>
                  <Modal.Body>
                    <Confirmation  
                      message={`Are you sure you want to delete this news no. ${this.state.deleteIndex+1} from News section ?`} 
                      onConfirm={this.confirmDelete} onClose={()=>this.setState({openConfirmationModal: false, deleteIndex: -1})} 
                    />
                  </Modal.Body>
                </Modal>
            </div>
          );
        }else{
          return null;
        }
       
    }
}