import { Component } from 'react';
import { TableComponent } from '../common/DataTable';
import { BREADCRUMBS } from '../../utils/breadcrumbs.utils';
import { convertDateTime, errorHandler, getAllMembersIdFromTeam, routeTo, updateLoader } from '../../utils/common.utils';
import VerificationService from '../../services/verification.service';
import { Button, Modal } from 'react-bootstrap'
import Confirmation from '../common/Confirmation';
import UserService from '../../services/user.service';
import Dropdown from 'react-bootstrap/Dropdown';
import { DebounceInput } from 'react-debounce-input';
import { createdAt } from '../../utils/dateTime.utils';

const DROPDOWN_OPTIONS = [
  // { name: 'Team ID', term: '_id' },
  { name: 'Team Name', term: 'name' },
]

export default class BlockBanTeams extends Component {
  constructor(props) {
    super(props)
    this.state = {
      teams: [],
      totalResult: 0,
      params: {
        pageNo: 0,
        limit: 10
      },
      selectedIndex: 0,
      action: null,
    }
    this.verificationApi = new VerificationService();
    this.authApi = new UserService();
    this.columns = [
      // {
      //   name: "TEAM ID",
      //   selector: (row) => [row._id],
      //   sortable: false,
      // },
      {
        name: "TEAM NAME",
        selector: (row) => [row.name],
        sortable: false,
      },
      {
        name: "MEMBERS",
        selector: (row) => [getAllMembersIdFromTeam(row.members)],
        sortable: false,
      },
      {
        name: "TOURNAMENTS",
        selector: (row) => [row.tournament],
        sortable: false,
      },
      {
        name: "TEAM'S AGE",
        selector: (row) => [createdAt(row.createdAt)],
        sortable: false,
      },
      {
        cell: (row) => <span> <span> <button className={`${row?.block ? 'es-squre-success-btn' : 'es-squre-primary-btn'}`} onClick={() => { this.action(row._id, 'block', row?.block ? false : true) }} id={row.id}><i className={`${row?.block ? 'fa fa-circle' : 'fa fa-lock'}`} title=""></i></button> </span>
          <span> <button disabled={row?.ban} className='es-squre-danger-btn' onClick={() => { this.action(row._id, 'ban', true) }} id={row.id}><i className="fa fa-ban" title=""></i></button> </span></span>,
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
        name: 'ACTION'
      },
    ]
  }

  componentDidMount() {
    this.getTeams(this.state.params)
  }

  action = (id, key, value) => {
    let msg = (key === 'ban') ? 'Are you sure, want to Ban this team?' : value ? 'Are you sure, want to Block this team?' : 'Are you sure, want to Unblock this team?'
    let data = {
      id: id,
      msg: msg,
      key: key,
      value: value
    }
    this.setState({ action: data })
  }

  onPagination = (i, key) => {
    let paramsTemp = this.state.params
    paramsTemp[key] = i
    this.setState({ params: paramsTemp })
    console.log(`${key}`, paramsTemp)
    this.getTeams(paramsTemp)
  }

  performAction = () => {
    let data = {
      [this.state.action.key]: this.state.action.value
    }
    updateLoader(true)
    this.verificationApi.updateTeam(this.state.action.id, data).then(
      (res) => {
        updateLoader(false)
        this.getTeams(this.state.params)
      }
    ).catch(
      (err) => {
        console.error(err)
        updateLoader(false)
        errorHandler(err)
      }
    )
  }

  onSearch = (val) => {
    let params = {
      [DROPDOWN_OPTIONS[this.state.selectedIndex].term]: val,
      pageNo: 0,
      limit: this.state?.params?.limit ?this.state.params.limit : 10
    }
    if (!val) delete params[DROPDOWN_OPTIONS[this.state.selectedIndex].term];
    this.setState({ params: params })
    this.searchTeams(params)
  }

  getTeams = (params) => {
    updateLoader(true)
    this.authApi.getAllTeams(params).then(
      (res) => {
        let teams = res.data.result.map((el, i) => { el.index = i; return el })
        this.setState({ teams: teams, totalResult: res.data.totalResults, action: null })
        console.log("res - ", res.data.result)
        updateLoader(false)
      }
    ).catch(
      (err) => {
        console.error(err)
        updateLoader(false)
        errorHandler(err)
      }
    )
  };

  searchTeams = (params) => {
    updateLoader(true)
    this.authApi.getAllTeams(params).then(
      (res) => {
        let teams = res.data.result.map((el, i) => { el.index = i; return el })
        this.setState({ teams: teams, totalResult: res.data.totalResults, action: null })
        updateLoader(false)
      }
    ).catch(
      (err) => {
        console.error(err)
        updateLoader(false)
        errorHandler(err)
      }
    )
  };

  render() {
    return (
      <div className="app-main__inner">
        <div className="d-flex flex-column flex-lg-row">
          <div className="flex-grow-1">
            <h3 className='text-white'>{BREADCRUMBS.USERS_BLOCK_TEAMS.HEADING}</h3>
          </div>
          <div className="">
            <ol className="breadcrumb color-white" style={{ background: 'none', padding: '0px' }}>
              {BREADCRUMBS.USERS_BLOCK_TEAMS.PATH.map((route, i) => {
                return <li key={i} className="breadcrumb-item cursor-pointer" onClick={() => { routeTo('/', 'dashboard') }}>{route}</li>
              })}
            </ol>
          </div>
        </div>

        <div className="row my-2">
          <div className="col-6 col-md-3 my-1">
            <button className='es-squre-primary-btn'><i className='fa fa-lock' title=""></i></button>
            <label className='fw-bold mx-2'>BLOCK</label>
          </div>
          <div className="col-6 col-md-3 my-1">
            <button className='es-squre-success-btn'><i className='fa fa-circle' title=""></i></button>
            <label className='fw-bold mx-2'>UNBLOCK</label>
          </div>
          <div className="col-6 col-md-3 my-1">
            <button className='es-squre-danger-btn cursor-pointer'><i className="fa fa-ban" title=""></i></button>
            <label className='fw-bold mx-2'>BAN</label>
          </div>
        </div>
        
        <div className="row">
          <div className="col-md-12">
            <div className="main-card mb-3 card transperent-card text-white p-4">
              <div className="card-header no-background p-0 mb-2">
                <div className="btn-actions-pane-right">
                  <div className="input-group icons search-box">
                    <Dropdown>
                      <Dropdown.Toggle variant="success" className='h-45 transperent-box light-border-around text-white' id="dropdown-basic">
                        <b className='px-5'>{DROPDOWN_OPTIONS[this.state.selectedIndex].name}</b>

                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {
                          DROPDOWN_OPTIONS.map((game, i) => {
                            return <Dropdown.Item key={i} onClick={() => this.setState({ selectedIndex: i })}>{game.name} </Dropdown.Item>
                          })
                        }
                      </Dropdown.Menu>
                    </Dropdown>
                    <DebounceInput minLength={3}
                      debounceTimeout={500}
                      type="search"
                      maxLength={100}
                      className="ml-2 form-control rounded text-white transperent-box light-border-around"
                      placeholder="Search in table ...."
                      onChange={(e) => { this.onSearch(e.target.value) }} />
                  </div>
                </div>
              </div>
              <div className='row'>
                <TableComponent column={this.columns} data={this.state.teams} paginationTotalRows={this.state.totalResult} onPagination={this.onPagination} />
              </div>
            </div>
          </div>
        </div>
        <Modal show={this.state.action} centered dialogClassName=''>
          <Modal.Body>
            <Confirmation
              message={this.state.action?.msg}
              onConfirm={this.performAction} btnText={this.state.action?.key === 'ban' ? 'Ban' : this.state.action?.value ? 'Block' : 'Unblock'} onClose={() => this.setState({ action: null })}
            />
          </Modal.Body>
        </Modal>
      </div>
    )
  }
}
