import { toast } from 'material-react-toastify'
import React, { Component } from 'react'
import { Button, Dropdown, Modal } from 'react-bootstrap'
import { PARTICIPANTS_TYPE } from '../../utils/strings.utils'

export default class AddStatement extends Component {

    constructor(props) {
        super(props)
        this.state = {
            question : null,
            selectedChoice : "CUSTOM"
        }
    }

    changeValue = (label, val) =>{
        this.setState({
          [label]: (val ? val : 0)
        });
    }


    onSubmit = () => {
        if(this.state.question === undefined || this.state.question === null || this.state.question.trim() === ""){
            toast.warning("Please enter a valid question !")
        }else{
            var data = {
                gameIds : [this.props.gameDetails._id],
                statement: this.state.question,
                type: this.state.selectedChoice
            } 
            this.props.addNewQuestion(data)
        }

    }
  

    render() {
        
        return (
            <div className='modal-content' style={{border:'none', borderRadius:'0'}}>
                <h5 className='text-center text-white fw-bold mb-0'>Add new betting statement</h5>
                <p className='text-center fs-12 text-white-50 my-0'>Participant type ( {this.props.gameDetails.name} )</p>
                {/* <div className='my-2 text-center text-white-50 fw-500'>
                    {this.props.message}
                </div> */}
                <div className='p-2'>
                    <div className='my-2'>
                        <label><span className='text-white font-bold'>Qustion #{this.props.number}</span></label>
                        <div className="input-group">
                            <input type="text" 
                                className="form-control transperent-box light-border-around text-gray"
                                placeholder='Enter your qustion' 
                                style={{borderRadius:'10px'}}
                                onChange={(e)=>this.changeValue("question",e.target.value)}
                            />
                        </div>
                    </div>
                    <div className='mt-4 mb-2'>
                        <div className='row d-flex align-items-center'>
                            <div className='col-5'>
                            <label><span className='text-white font-bold'>Select answer type </span></label>
                            </div>
                            <div className='col-7'>
                            <div className="input-group">
                            <Dropdown className='w-100'>
                                <Dropdown.Toggle variant="success" className='transperent-box light-border-around text-white w-100' id="dropdown-basic">
                                    <b className='px-5'>{PARTICIPANTS_TYPE[this.state.selectedChoice]}</b>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                {Object.keys(PARTICIPANTS_TYPE).map((key,i)=>{
                                    if(this.state.selectedChoice !== key){
                                        return (
                                            <Dropdown.Item key={i} onClick={()=>this.setState({selectedChoice : key})}>
                                            <b className='px-4'>{PARTICIPANTS_TYPE[key]}</b> 
                                            </Dropdown.Item>
                                        )
                                    }
                                })}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                            </div>
                        </div>
                        
                        
                    </div>
                </div>
                
                <div className='d-flex justify-content-center my-3'>
                    <Button onClick={() => { this.onSubmit() }} className="btn btn-primary fw-bold me-1">
                        Save
                    </Button>
                    <Button onClick={() => { this.props.onClose() }} className="btn-primary fw-bold me-1">
                        Close
                    </Button>
                </div>
            </div>
        )
    }
}
