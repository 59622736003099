import { Component } from 'react'
import { TableComponent } from '../common/DataTable';
import WalletService from '../../services/wallet.service';
import { BREADCRUMBS } from '../../utils/breadcrumbs.utils';
import { errorHandler, routeTo, updateLoader, withRouter } from '../../utils/common.utils';
import Dropdown from 'react-bootstrap/Dropdown';
import { DebounceInput } from 'react-debounce-input';

const DROPDOWN_OPTIONS = [
  { name: 'User Id', term: 'userId' },
  { name: 'Id', term: '_id' }
]
export default class EcDepositeAndWithdrawl extends Component {
  constructor(props) {
    super(props)
    this.state = {
      transaction: [],
      totalResult: 0,
      params: {
        pageNo: 0,
        limit: 10
      },
      selectedIndex: 0,
    }
    this.walletApi = new WalletService();
    this.columns = [
      {
        name: "USER ID",
        selector: (row) => [row.userId],
        sortable: false,
      },
      {
        name: "NAME",
        selector: (row) => [row.name],
        sortable: false,
      },
      {
        name: "KYC",
        selector: (row) => [row.kyc ? 'YES' : 'NO'],
        sortable: false,
      },
      {
        name: "EMAIL",
        selector: (row) => [row.email],
        sortable: false,
      },
      // {
      //   name: "PHONE",
      //   selector: (row) => [row.resolvedDisputes],
      //   sortable: true,
      // },
      {
        name: "AMOUNT(EC)",
        selector: (row) => [row.amount],
        sortable: false,
      },
      {
        name: "DATE",
        selector: (row) => [row.date],
        sortable: false,
      },
      {
        name: "TIME",
        selector: (row) => [row.time],
        sortable: false,
      }
    ]
  }

  componentDidMount() {
    this.getTournament(this.state.params)
  }

  onPagination = (i, key) => {
    let paramsTemp = this.state.params
    paramsTemp[key] = i
    this.setState({ params: paramsTemp })
    console.log(`${key}`, paramsTemp)
    this.getTournament(paramsTemp)
  }
  onSearch = (val) => {
    let params = {
      [DROPDOWN_OPTIONS[this.state.selectedIndex].term]: val,
      pageNo: 0,
      limit: this.state?.params?.limit ?this.state.params.limit : 10
    }
    if (!val) delete params[DROPDOWN_OPTIONS[this.state.selectedIndex].term];
    this.setState({ params: params })
    this.searchTournament(params)
  }
  getTournament = (params) => {
    updateLoader(true)
    this.walletApi.getAllTransaction(params).then(
      (res) => {
        let transaction = res.data.result.map((el, i) => { el.index = i; return el })
        this.setState({ transaction: transaction, totalResult: res.data.totalResults })
        console.log("res - ", res.data.result)
        updateLoader(false)
      }
    ).catch(
      (err) => {
        console.error(err)
        updateLoader(false)
        errorHandler(err)
      }
    )
  };
  searchTournament = (params) => {
    updateLoader(true)
    this.walletApi.getAllTransaction(params).then(
      (res) => {
        let transaction = res.data.result.map((el, i) => { el.index = i; return el })
        this.setState({ transaction: transaction, totalResult: res.data.totalResults })
        updateLoader(false)
      }
    ).catch(
      (err) => {
        console.error(err)
        updateLoader(false)
        errorHandler(err)
      }
    )
  };
  render() {
    return (
      <div className="app-main__inner">
        <div className="d-flex flex-column flex-lg-row">
          <div className="flex-grow-1">
            <h3 className='text-white'>{BREADCRUMBS.WALLETS_TRANSACTION.HEADING}</h3>


          </div>
          <div className="">
            <ol className="breadcrumb color-white" style={{ background: 'none', padding: '0px' }}>
              {BREADCRUMBS.WALLETS_TRANSACTION.PATH.map((route, i) => {
                return <li key={i} className="breadcrumb-item cursor-pointer" onClick={() => { routeTo('/', 'dashboard') }}>{route}</li>
              })}
            </ol>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="main-card mb-3 card transperent-card text-white p-4">
              <div className="card-header no-background p-0 mb-2">
                <div className="btn-actions-pane-right">
                  <div className="input-group icons search-box">
                    <Dropdown>
                      <Dropdown.Toggle variant="success" className='h-45 transperent-box light-border-around text-white' id="dropdown-basic">
                        <b className='px-5'>{DROPDOWN_OPTIONS[this.state.selectedIndex].name}</b>

                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {
                          DROPDOWN_OPTIONS.map((game, i) => {
                            return <Dropdown.Item key={i} onClick={() => this.setState({ selectedIndex: i })}>{game.name} </Dropdown.Item>
                          })
                        }
                      </Dropdown.Menu>
                    </Dropdown>
                    <DebounceInput minLength={3}
                      debounceTimeout={500}
                      type="search"
                      maxLength={100}
                      className="ml-2 form-control rounded text-white transperent-box light-border-around"
                      placeholder="Search in table ...."
                      onChange={(e) => { this.onSearch(e.target.value) }} />
                  </div>
                </div>
              </div>
              <div className='row'>
                <TableComponent column={this.columns} data={this.state.transaction} paginationTotalRows={this.state.totalResult} onPagination={this.onPagination} />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
