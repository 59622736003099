import { Component } from 'react'
import AuthService from '../../services/auth.service';
import Button from 'react-bootstrap/Button';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { errorHandler, profile, updateLoader, withRouter } from '../../utils/common.utils';
import { toast } from 'material-react-toastify';
import { FaLock, FaUserCircle } from "react-icons/fa";

class ChangePassword extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }

        this.authApi = new AuthService();
    }

    onSubmit = (values) => {
        values.email = profile().email;
        updateLoader(true)
        this.authApi.changePassword(values).then(
            (res) => {
                updateLoader(false)
                toast.success('Password updated successfully')
            }
        ).catch(
            (err) => {
                console.error(err)
                updateLoader(false)
                errorHandler(err)
            }
        )
    }

    render() {
        return (
            <div className='container my-4'>
                <div className='text-center'>
                    <h3 className='text-white'>Change Password</h3>
                </div>
                <Formik
                    enableReinitialize
                    initialValues={{
                        email: '',
                        oldPassword: '',
                        newPassword: ''
                    }}
                    validationSchema={Yup.object({
                        oldPassword: Yup.string().required("Old password is required"),
                        newPassword: Yup.string().required("New password is required"),
                    })}
                    onSubmit={(values, { setSubmitting }) => {
                        setTimeout(() => {
                            this.onSubmit(values)
                        }, 400);
                    }}
                >
                    <Form className="validate-form">
                        <div className="wrap-input100 validate-input">
                            <label className='text-white mt-3'>Old Password</label>
                            <div className="form-group d-flex align-items-center my-0">
                                <FaLock style={{height: '30px', width:'30px'}} />
                                <Field name="oldPassword" type="password" className="ms-2 form-control" placeholder="Old Password" />
                            </div>
                            <ErrorMessage component="span" className="error-msg" name="oldPassword" /> <br/>
                            <label className='text-white mt-3'>New Password</label>
                            <div className="form-group d-flex align-items-center my-0">
                                <FaLock style={{height: '30px', width:'30px'}} />
                                <Field name="newPassword" type="password" className="ms-2 form-control" placeholder="newPassword" />
                            </div>
                            <ErrorMessage component="span" className="error-msg" name="newPassword" />
                        </div>
                        <div className='mt-4'>
                            <Button type='submit' variant="secondary" className="me-1">
                                Change Password
                            </Button>
                        </div>
                    </Form>
                </Formik>
            </div>
        )
    }
}
export default withRouter(ChangePassword)