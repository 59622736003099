import React, { Component } from 'react'

import "../../assets/css/graph.css";
import "../../assets/css/demo.css";

export default class Graphs extends Component {
  
    render() {
        
       
        var remaining = (100 - parseInt(this.props.percentage))
        var strokeDasharray = `${parseInt(this.props.percentage)} ${remaining}`;
        return (
            <div class="svg-item my-4">
                <svg width="100%" height="100%" viewBox="0 0 40 40" class="donut">
                    <circle class="donut-hole" cx="20" cy="20" r="15.91549430918954" fill="#fff"></circle>
                    <circle class="donut-ring" cx="20" cy="20" r="15.91549430918954" fill="transparent" stroke-width="3.5"></circle>
                    <circle class="donut-segment donut-segment-4" cx="20" cy="20" r="15.91549430918954" fill="transparent" stroke-width="3.5" stroke-dasharray={strokeDasharray} stroke-dashoffset="25"></circle>
                    <g class="donut-text donut-text-3">

                    <text y="50%" transform="translate(0, 2)">
                        <tspan x="50%" text-anchor="middle" class="donut-percent">{this.props.percentage}%</tspan>   
                    </text>
                    <text y="60%" transform="translate(0, 2)">
                        <tspan x="50%" text-anchor="middle" class="donut-data">percentage</tspan>   
                    </text>
                    </g>
                </svg>
            </div>
        )
    }
}
