import * as axios from "axios";
import { token } from "../utils/common.utils";

export default class RulesService {
    constructor() {
        this.client = null;
        this.api_token = null;
        this.api_url = process.env.REACT_APP_API_URL;
    }
    queryParams(params) {
        return Object.keys(params)
            .map(key => `${key}=${params[key]}`)
            .join('&');
    }
    init = () => {
        this.api_token = token();
        let headers = {
            Accept: "application/json",
        };

        if (this.api_token) {
            headers.Authorization = this.api_token;
        }

        this.client = axios.create({
            baseURL: this.api_url,
            timeout: 31000,
            headers: headers,
        });

        return this.client;
    };
    updateTnC = (id, data) => {
        return this.init().put(`/admin/api/v1/termsAndConditions/updateTermsAndConditions/${id}`, data);
    };
    getTnC = () => {
        return this.init().get(`/admin/api/v1/termsAndConditions/getTermsAndConditions`);
    };
    getRules = () => {
        return this.init().get(`/admin/api/v1/rules/getRules`);
    };
    updateRules = (id, data) => {
        return this.init().put(`/admin/api/v1/rules/updateRules/${id}`, data);
    };
}
