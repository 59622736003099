import * as axios from "axios";
import { token } from "../utils/common.utils";
//import axios from "axios";

export default class BettingService {
    constructor() {
        this.client = null;
        this.api_token = null;
        this.api_url = process.env.REACT_APP_API_URL;
    }
    queryParams(params) {
        return Object.keys(params)
            .map(key => `${key}=${params[key]}`)
            .join('&');
    }
    init = () => {
        this.api_token = token();
        let headers = {
            Accept: "application/json",
        };

        if (this.api_token) {
            headers.Authorization = this.api_token;
        }

        this.client = axios.create({
            baseURL: this.api_url,
            timeout: 31000,
            headers: headers,
        });

        return this.client;
    };

      
    getBettingDetails = () => {
        return this.init().get(`/admin/api/v1/betting/getAllBettingCriteria`);
    };

    updateBettingDetails = (id, data) => {
        console.log(`id ${id} data ${data}`);
        return this.init().put(`/admin/api/v1/betting/updateBettingCriteria/${id}`,data);
    };

    getBetOrgDetails = (params) => {
        const searchString = this.queryParams(params)
        return this.init().get(`/admin/api/v1/betting/getOrganizations?${searchString}`);
    };

    getBetTournamentDetails = (params) => {
        const searchString = this.queryParams(params)
        return this.init().get(`/admin/api/v1/betting/getTournament?${searchString}`);
    };

    getBetWinners = (params) => {
        const searchString = this.queryParams(params)
        return this.init().get(`/admin/api/v1/betting/getBetWinners?${searchString}`);
    };

    getBettingStatements = (params) => {
        const searchString = this.queryParams(params)
        return this.init().get(`/admin/api/v1/betting/getAllBettingStatements?${searchString}`);
    };

    addBettingStatements = (data) => {
        return this.init().post(`/admin/api/v1/betting/createBettingStatement`,data);
    };

    updateBettingStatement = (id, data) => {
        return this.init().put(`/admin/api/v1/betting/updateBettingStatement/${id}`,data);
    };

    deleteBettingStatements = (id) =>{
        return this.init().delete(`/admin/api/v1/betting/deleteBettingStatements/${id}`);
    }


 
 
}