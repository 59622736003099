
import { Component } from 'react';
import Logo_Text from '../assets/images/eSpotz_logo.png';
import { profile, withRouter } from '../utils/common.utils';
import { getAvatarForLetter } from '../utils/images.utils';
import AuthService from '../services/auth.service';
import { Dropdown } from 'react-bootstrap';

class Header extends Component {

    constructor(props) {
        super(props);
        this.state = {};
        this.authApi = new AuthService();
    }

    logout = () => {
        this.authApi.signOut().then(
            (res) => {
                localStorage.removeItem('user');
                this.props.router.navigate('/login');
            }
        ).catch(
            (err) => {
                console.error(err);
            }
        )
    }

    render() {
        var defaultImage = getAvatarForLetter(profile()?.name?.charAt(0).toLowerCase());

        return (
            <div className="app-header header-shadow">

                <div className="app-header__logo d-flex justify-content-center">
                    <span className="brand-title">
                        <img className="company-title-img" src={Logo_Text} alt='' />
                    </span>
                </div>

                <div className="app-header__mobile-menu">
                    <div>
                        <button type="button" className="hamburger hamburger--elastic mobile-toggle-nav">
                            <span className="hamburger-box">
                                <span className="hamburger-inner"></span>
                            </span>
                        </button>
                    </div>
                </div>
                <div className="app-header__menu">
                    <Dropdown>
                        <Dropdown.Toggle id="dropdown-basic" style={{backgroundColor:'transparent', border:'none', outline:'none'}}>
                            <img src={defaultImage} className="rounded-circle" height="40" width="40" alt="" />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => { this.props.router.navigate('/user/profile') }}>Profile</Dropdown.Item>
                            <Dropdown.Item onClick={() => { this.props.router.navigate('/user/change-password') }}>Change Password</Dropdown.Item>
                            <Dropdown.Item onClick={this.logout}>Logout</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                
    
                <div className="app-header__content">
                    <div className="app-header-right">
                        <Dropdown>
                            <Dropdown.Toggle id="dropdown-basic" style={{backgroundColor:'transparent', border:'none', outline:'none'}}>
                                <img src={defaultImage} className="rounded-circle" height="40" width="40" alt="" />
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() => { this.props.router.navigate('/user/profile') }}>Profile</Dropdown.Item>
                                <Dropdown.Item onClick={() => { this.props.router.navigate('/user/change-password') }}>Change Password</Dropdown.Item>
                                <Dropdown.Item onClick={this.logout}>Logout</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
            </div>
        );
    }

}

export default withRouter(Header)








