import React, { Component } from 'react'
import { Button, Modal } from 'react-bootstrap'

export default class Confirmation extends Component {

    constructor(props) {
        super(props)
        this.state = {
        }
    }

    render() {
        return (
            <div className='modal-content' style={{border:'none', borderRadius:'0'}}>
                <h3 className='text-center text-white fw-bold'>Confirm</h3>
                <div className='my-2 text-center text-white fw-500'>
                    {this.props.message}
                </div>
                <div className='d-flex justify-content-center my-3'>
                    <Button onClick={() => { this.props.onConfirm() }} className="btn btn-primary fw-bold me-1">
                        
                        {
                            this.props?.btnText?this.props?.btnText:'Remove'
                        }
                    </Button>
                    <Button onClick={() => { this.props.onClose() }} className="btn-primary fw-bold me-1">
                        Close
                    </Button>
                </div>
            </div>
        )
    }
}
