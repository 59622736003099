import { Component } from 'react'
import {
    Routes,
    Route,
    Navigate,
    Outlet
} from "react-router-dom";
import ChangePassword from '../components/auth/ChangePassword';
import Login from '../components/auth/Login';
import Profile from '../components/auth/Profile';
import BettingCriteria from '../components/betting/BettingCriteria';
import BettingData from '../components/betting/BettingData';
import BettingDataWinners from '../components/betting/BettingDataWinners';
import BettingIndex from '../components/betting/BettingIndex';
import BettingOrg from '../components/betting/BettingOrg';
import BettingStatment from '../components/betting/BettingStatment';
import GamesBetting from '../components/betting/GamesBetting';
import BugReport from '../components/bug-report/BugReport';
import CommissionIndex from '../components/commission/CommissionIndex';
import EcCurrency from '../components/currency/EcCurrency';
import Home from '../components/Dashboard/Home';
import DenAchievementRankCriteria from '../components/den/DenAchievementRankCriteria';
import DenAchievementRankTitle from '../components/den/DenAchievementRankTitle';
import DenIndex from '../components/den/DenIndex';
import DenJoiningInteractionBonus from '../components/den/DenJoiningInteractionBonus';
import DenProfileCriteria from '../components/den/DenProfileCriteria';
import DenProfileRank from '../components/den/DenProfileRank';
import DenReportedPosts from '../components/den/DenReportedPosts';
import DenSpinWheel from '../components/den/DenSpinWheel';
import DenSpotlight from '../components/den/DenSpotlight';
import DenStar from '../components/den/DenStar';
import Hashtags from '../components/den/Hashtags';
import FeatureRequest from '../components/feature-requests/FeatureRequest';
import Forms from '../components/forms/Forms';
import GameIndex from '../components/games/GameIndex';
import Games from '../components/games/Games';
import PointMatrix from '../components/games/PointMatrix';
import BettingNotifications from '../components/notifications/BettingNotifications';
import ClanNotifications from '../components/notifications/ClanNotifications';
import DenNotifications from '../components/notifications/DenNotifications';
import NotificationIndex from '../components/notifications/NotificationIndex';
import OrgNotification from '../components/notifications/OrgNotification';
import TeamNotification from '../components/notifications/TeamNotification';
import TournamentNotifications from '../components/notifications/TournamentNotifications';
import UsersNotifications from '../components/notifications/UsersNotifications';
import WalletNotifications from '../components/notifications/WalletNotifications';
import PageNotFound from '../components/PageNotFound';
import Parent from '../components/Parent';
import GamesRules from '../components/rules/GamesRules';
import LandingPageRules from '../components/rules/LandingPageRules';
import RulesIndex from '../components/rules/RulesIndex';
import TermsNConditions from '../components/terms-conditions/TermsNConditions';
import TournamentCancellation from '../components/tournament-cancel/TournamentCancellation';
import AdminList from '../components/Users/AdminList';
import BlockBanBrands from '../components/Users/BlockBanBrands';
import BlockBanTeams from '../components/Users/BlockBanTeams';
import BlockBanTo from '../components/Users/BlockBanTo';
import BlockBanUsers from '../components/Users/BlockBanUsers';
import Clans from '../components/Users/Clans';
import Organisation from '../components/Users/Organisation';
import Teams from '../components/Users/Teams';
import UserIndex from '../components/Users/UserIndex';
import UsersList from '../components/Users/UsersList';
import ClanVerification from '../components/verification/ClanVerification';
import ManageCriteriaVerification from '../components/verification/ManageCriteriaVerification';
import OrganizationVerification from '../components/verification/OrganizationVerification';
import UserVerification from '../components/verification/UserVerification';
import VerificationIndex from '../components/verification/VerificationIndex';
import AccountPayments from '../components/wallet/AccountPayments';
import BettingWinners from '../components/wallet/BettingWinners';
import EcDepositeAndWithdrawl from '../components/wallet/EcDepositeAndWithdrawl';
import TournamentTransaction from '../components/wallet/TournamentTransaction';
import TournamentWinners from '../components/wallet/TournamentWinners';
import WalletIndex from '../components/wallet/WalletIndex';
import ContactSocialMedia from '../components/website/ContactSocialMedia';
import InformationText from '../components/website/InformationText';
import LandingPage from '../components/website/LandingPage';
import News from '../components/website/News';
import StaticPages from '../components/website/StaticPages';
import WebsiteIndex from '../components/website/WebsiteIndex';
import { isLoggedIn, Role } from '../utils/common.utils';

const AdminProtectedRoute = () => {
    if (!isLoggedIn()) {
        return <Navigate id="RouterNavLink" style={{}} to={'/login'} replace />;
    }

    return <Outlet />;
};
const IsAdminRoute = ({ children }) => {
    if (Role() !== 'OWNER') {
        return <Navigate id="RouterNavLink2" style={{}} to={'/'} replace />;
    }
    return children;
}
export default class MainRoutes extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }

    }

    render() {
        return (
            <div>
                <Routes>
                    <Route element={<AdminProtectedRoute />}>
                        <Route path="/" element={<Parent />} >
                            <Route index element={<Home />} />
                            <Route path="/user" element={<UserIndex />} >
                                <Route path="users" element={<UsersList />} />
                                <Route path="admins" element={
                                    <IsAdminRoute>
                                        <AdminList />
                                    </IsAdminRoute>
                                } />
                                <Route path="clans" element={<Clans />} />
                                <Route path="teams" element={<Teams />} />
                                <Route path="organisations" element={<Organisation />} />
                                <Route path="block-ban-users" element={<BlockBanUsers />} />
                                <Route path="block-ban-teams" element={<BlockBanTeams />} />
                                <Route path="block-ban-To" element={<BlockBanTo />} />
                                <Route path="block-ban-brands" element={<BlockBanBrands />} />
                                <Route path="change-password" element={<ChangePassword />} />
                                <Route path="profile" element={<Profile />} />
                            </Route>
                            <Route path="/games" element={<GameIndex />} >
                                <Route path="point-matrix" element={<PointMatrix />} />

                                {/* <Route path="point-matrix" element={<Games page='POINT_MATRIX' />} /> */}
                                <Route path="all-games" element={<Games page='ALL_GAMES' />} />
                                <Route path="betting" element={<Games page='BETTING' />} />
                                <Route path="entry-fees" element={<Games page='ENTRY_FEE' />} />
                                <Route path="price-pool" element={<Games page='PRICE_POOL' />} />

                            </Route>
                            <Route path="/verification" element={<VerificationIndex />} >
                                <Route path="user-verification" element={<UserVerification />} />
                                <Route path="clan-verification" element={<ClanVerification />} />
                                <Route path="organization-verification" element={<OrganizationVerification />} />
                                <Route path="verification-criteria" element={<ManageCriteriaVerification />} />
                            </Route>
                            <Route path="/website" element={<WebsiteIndex />} >
                                <Route path="landing-page" element={<LandingPage />} />
                                <Route path="information-text" element={<InformationText />} />
                                <Route path="static-pages" element={<StaticPages />} />
                                <Route path="news" element={<News />} />
                                <Route path="contact-media" element={<ContactSocialMedia />} />
                            </Route>
                            <Route path="/wallet" element={<WalletIndex />} >
                                <Route path="tournament-transaction" element={<TournamentTransaction />} />
                                <Route path="tournament-winners/:id" element={<TournamentWinners />} />
                                <Route path="betting-winners/:id" element={<BettingWinners />} />
                                <Route path="ec-deposite-withdrawls" element={<EcDepositeAndWithdrawl />} />
                                <Route path="account-payments" element={<AccountPayments />} />
                            </Route>
                            <Route path="/commission" element={<CommissionIndex />} >
                                <Route index element={<CommissionIndex />} />
                            </Route>
                            <Route path="/notifications" element={<NotificationIndex />} >
                                <Route path="users-notifications" element={<UsersNotifications />} />
                                <Route path="clan-notifications" element={<ClanNotifications />} />
                                <Route path="team-notifications" element={<TeamNotification />} />
                                <Route path="organisation-notifications" element={<OrgNotification />} />
                                <Route path="tournament-notifications" element={<TournamentNotifications />} />
                                <Route path="betting-notifications" element={<BettingNotifications />} />
                                <Route path="den-notifications" element={<DenNotifications />} />
                                <Route path="wallet-notifications" element={<WalletNotifications />} />
                            </Route>
                            <Route path='/feature-request' element={<FeatureRequest />} />
                            <Route path='/bug-report' element={<BugReport />} />
                            <Route path='/tournament-cancellation' element={<TournamentCancellation />} />
                            <Route path='/forms' element={<Forms />} />
                            <Route path="/betting" element={<BettingIndex />} >
                                <Route path="games-betting" element={<GamesBetting  />} />
                                <Route path="betting-statements" element={<BettingStatment />} />
                                <Route path="betting-criteria" element={<BettingCriteria />} />
                                <Route path="betting-organisation" element={<BettingOrg />} />
                                <Route path="betting-data" element={<BettingData />} />
                                <Route path="betting-data-winners/:id" element={<BettingDataWinners />} />
                            </Route>
                            <Route path='/Ec-currency' element={<EcCurrency />} />
                            <Route path="/rules" element={<RulesIndex />} >
                                <Route path="landing-rules" element={<LandingPageRules />} />
                                <Route path="games-rules" element={<GamesRules />} />
                            </Route>
                            <Route path='/terms-conditions' element={<TermsNConditions />} />
                            <Route path="/den" element={<DenIndex />} >
                                <Route path="den-star" element={<DenStar />} />
                                <Route path="spotlight" element={<DenSpotlight />} />
                                <Route path="spin-wheel" element={<DenSpinWheel />} />
                                <Route path="joining-interaction-bonus" element={<DenJoiningInteractionBonus />} />
                                <Route path="Achievement-rank-title" element={<DenAchievementRankTitle />} />
                                <Route path="Den-profile-rank-title" element={<DenProfileRank />} />
                                <Route path="Achievement-rank-criteria" element={<DenAchievementRankCriteria />} />
                                <Route path="den-profile-criteria" element={<DenProfileCriteria />} />
                                <Route path="hashtags" element={<Hashtags />} />
                                <Route path="reported-posts" element={<DenReportedPosts />} />
                            </Route>
                        </Route>
                    </Route>
                    <Route path="login" element={<Login />} />
                    <Route path="*" element={<PageNotFound />} />
                </Routes>
            </div>
        )
    }
}
