
import React, { Component } from 'react';
import { HEADING, POINTS_RANK, WEBSITE } from '../../utils/strings.utils';
import { getGamesDetails, getWebsiteDetails, routeTo, updateWebsiteDetails } from '../../utils/common.utils';
import { BREADCRUMBS } from '../../utils/breadcrumbs.utils';

import "../../assets/css/switch.css";
import "../../assets/css/game.css";
import { toast } from 'material-react-toastify';
import { isValidLink } from '../../utils/validation.utils';



export default class ContactSocialMedia extends Component {

    constructor(props) {
        super(props);
        this.state = { 
          websiteDetails : null,
          selectedChoice : 0,
          editField : null,
          updateValue : null
        };
    }
  
    componentDidMount(){
      getWebsiteDetails(`type=CONTACT`,this.setwebsiteDetails);
    }

    setwebsiteDetails = (websiteDetails) =>{
      if(websiteDetails){
        websiteDetails = websiteDetails.sort((a, b) => a.subType.toLowerCase().localeCompare(b.subType.toLowerCase()))
        this.setState({
          websiteDetails: websiteDetails,
          editField : null,
          updateValue : null
        });
      }
    }


    enableEditOption = (key) =>{
      this.setState({
        editField: key,
        updateValue: this.state.websiteDetails[this.state.selectedChoice].questions[key]
      });
    }

    changeValue = (val) =>{
      this.setState({
        updateValue: (val ? val : 0)
      });
    }

    updateWebsiteDetails = (key) => {
      if(this.state.updateValue === null || this.state.updateValue === undefined || this.state.updateValue === ""){
        toast.warning("Please type something before saving the changes.")
      }else if(!isValidLink(key,this.state.updateValue)){
        toast.warning("Please enter a valid link")
      }else{
        let data = {};
        data[key] = this.state.updateValue;
        updateWebsiteDetails(this.state.websiteDetails[this.state.selectedChoice]._id, data, this.setwebsiteDetails);
      }

    }


    render() {

        const changeValue = this.changeValue;
        const {editField} = this.state;



        if(this.state.websiteDetails){
          console.log("--------- CONTACT :: ",this.state.websiteDetails[this.state.selectedChoice])

          return (
            <div className="app-main__inner">
                {/* HEAD */}
                <div className="d-flex flex-column flex-lg-row">
                    {/* MAIN-HEAD */}
                    <div className="flex-grow-1">
                        <h3 className='text-light-white'>{BREADCRUMBS.CONTACT.HEADING}</h3>
                    </div>
                    {/* BRAD-CRUMB */}
                    <div className="">
                        <ol className="breadcrumb color-white" style={{ background: 'none', padding: '0px' }}>
                            {BREADCRUMBS.CONTACT.PATH.map((route,i)=>{
                                return <li key={i} className="breadcrumb-item cursor-pointer" onClick={() => { routeTo('/','dashboard')  }}>{route}</li>
                            })}
                        </ol>
                    </div>
                </div>
                {/* BODY */}
                <div className="row">
                    <div className="col-md-12">
                        <div className="main-card mb-3 card transperent-card text-white p-4">
                            {/* SUB-HEAD */}
                            <div className="card-header no-background p-0 mb-2 ">  Contact us and social media presence
                                {/*ADD FILTER */}
                               
                            </div>
                            {/* MAIN_BODY */}
                            <div className='row'>

                              {Object.keys(this.state.websiteDetails[this.state.selectedChoice].questions).map((key, i)=>{
                                console.log(`key ${i+1} - ${key}`);
                                if(editField === key){
                                  return (
                                    <div className='col-12  my-2' key={key} >
                                        <label><span className=' font-bold'>{WEBSITE.CONTACT[key]}</span></label>                                  
                                        <div className="input-group" >
                                          <input type={ key === 'CONTACT_US_EMAIL' ? 'email' : 'url' }
                                            id={key}
                                            onChange={(e)=>changeValue(e.target.value)}
                                            defaultValue={this.state.websiteDetails[this.state.selectedChoice].questions[key]}
                                            placeholder={`Enter ${WEBSITE.CONTACT[key]}`}
                                            className="form-control transperent-box light-border-around text-gray" 
                                            aria-label="Recipient's username" aria-describedby="basic-addon2"
                                            style={{borderTopLeftRadius:'10px', borderBottomLeftRadius: '10px'}}
                                            maxLength={300}
                                          />
                                          <div className="input-group-append">
                                            <button className="btn edt-btn light-border-around" onClick={()=>this.updateWebsiteDetails(key)} type="button" style={{borderTopRightRadius:'10px', borderBottomRightRadius: '10px'}}>
                                              Save
                                            </button>
                                          </div>
                                        </div>
                                    </div>
                                  )
                                }else{
                                  
                                  return (
                                    <div className='col-12  my-2' key={key} >
                                        <label><span className=' font-bold'>{WEBSITE.CONTACT[key]}</span></label>                                  
                                        <div className="input-group" >
                                          <input type={ key === 'CONTACT_US_EMAIL' ? 'email' : 'url' }
                                            id={key}
                                            onChange={(e)=>changeValue(e.target.value)}
                                            readOnly
                                            defaultValue={this.state.websiteDetails[this.state.selectedChoice].questions[key]}
                                            placeholder={`Enter ${WEBSITE.CONTACT[key]}`}
                                            className="form-control transperent-box light-border-around text-gray" 
                                            aria-label="Recipient's username" aria-describedby="basic-addon2"
                                            style={{borderTopLeftRadius:'10px', borderBottomLeftRadius: '10px'}}
                                            maxLength={300}
                                          />
                                          <div className="input-group-append">
                                            <button className="btn edt-btn light-border-around" onClick={()=>this.enableEditOption(key)} type="button" style={{borderTopRightRadius:'10px', borderBottomRightRadius: '10px'}}>
                                              Edit
                                            </button>
                                          </div>
                                        </div>
                                    </div>
                                  )
                                }
                              })}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
          );
        }else{
          return null;
        }
       
    }
}