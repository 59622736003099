import * as axios from "axios";
import { token } from "../utils/common.utils";

export default class AuthService {
    constructor() {
        this.client = null;
        this.api_token = null;
        this.api_url = process.env.REACT_APP_API_URL;
    }
    init = () => {
        this.api_token = token();
        let headers = {
            Accept: "application/json",
        };
        if (this.api_token) {
            headers.Authorization = this.api_token;
        }

        this.client = axios.create({
            baseURL: this.api_url,
            timeout: 31000,
            headers: headers,
        });

        return this.client;
    };

    signIn = (data) => {
        return this.init().post("/public/api/v1/adminUserAuth/signin", data);
    };
    signOut = () => {
        return this.init().get("/admin/api/v1/adminUser/logOut");
    };
    createAdmin = (data) => {
        return this.init().post("/admin/api/v1/adminUser/createAdminUser", data);
    }
    removeAdmins = (data) => {
        return this.init().post("/admin/api/v1/adminUser/removeUser", data);
    }
    changePassword = (data) => {
        return this.init().post("/admin/api/v1/adminUser/changePassword", data);
    }

    resetPassword = (data) => {
        return this.init().post("/public/admin/api/v1/adminUser/resetPasswordThroughLink", data);
    }

}