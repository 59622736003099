
import React, { Component } from 'react';
import { HEADING } from '../../utils/strings.utils';
import { getGamesDetails, routeTo, updateGameDetails } from '../../utils/common.utils';
import { BREADCRUMBS } from '../../utils/breadcrumbs.utils';
import "../../assets/css/switch.css";
import "../../assets/css/game.css";

const GAMES_ENUM = {
    ALL_GAMES: 'isGameEnabled',
    POINT_MATRIX: 'pointMatrix',
    BETTING: 'isBettingEnabled',
    ENTRY_FEE: 'isEntryFeeEnabled',
    PRICE_POOL: 'isPrizePoolEnabled',
};

export default class Games extends Component {

    constructor(props) {
        super(props);
        this.state = { 
          gameDetails: null,
          page : props.page,
          searchParam: ""
        };
    }
  
    componentDidMount(){
      getGamesDetails(this.setGamesDetails);
    }

    componentWillReceiveProps(newProps){
        this.setState({
            page : newProps.page,
            searchParam: ""
        })
    }


    setGamesDetails = (gameDetails) =>{
      this.setState({
        gameDetails: gameDetails
      });
    }

    handleClick = (id, value) => {
        
      var data = {};
      data[GAMES_ENUM[this.state.page]] = value;

      updateGameDetails(id, data, this.setGamesDetails);
    }

    render() {

        console.log("------------active page :: ",this.state.page)

        if(this.state.gameDetails){

          const gameDetails = this.state.gameDetails.filter(game => (game.name.toLowerCase()).includes(this.state.searchParam.toLowerCase()));
          gameDetails.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
          return (
            <div className="app-main__inner">
                <div className="d-flex flex-column flex-lg-row">
                    <div className="flex-grow-1">
                        <h3 className='text-white'>{BREADCRUMBS[this.state.page].HEADING}</h3>
                       

                    </div>
                    <div className="">
                        <ol className="breadcrumb color-white" style={{ background: 'none', padding: '0px' }}>
                            {BREADCRUMBS[this.state.page].PATH.map((route,i)=>{
                                return <li key={i} className="breadcrumb-item cursor-pointer" onClick={() => { routeTo('/','dashboard')  }}>{route}</li>
                            })}
                        </ol>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="main-card mb-3 card transperent-card text-white p-4">
                            <div className="card-header no-background p-0 mb-2">Game Center
                                <div className="btn-actions-pane-right">
                                  <div className="input-group icons search-box">

                                
                                    <input type="search" onChange={e => this.setState({searchParam : e.target.value})} className="form-control rounded text-white transperent-box light-border-around" placeholder="Search in table ...."/>
                                    <div className="drop-down   d-md-none">
                                      <form action="#">
                                        <input type="text" className="form-control " placeholder="Search"/>
                                      </form>
                                    </div>
                                  </div>
                                </div>
                            </div>
                            <div className="table-responsive light-border-around lv-tb-bt text-white">
                                <table className="align-middle mb-0 table table-borderless table-striped table-hover">
                                    <thead className='text-white'>
                                        <tr>
                                            <th className="text-center">Sr. No.</th>
                                            <th className="">Game Name</th>
                                            <th className="text-center">Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                      {
                                        gameDetails.map((game,i)=>{
                                          return(
                                            <tr key={i}>
                                                <td className="text-center text-muted text-white">{i+1}</td>
                                                <td className="">
                                                    <div className="widget-content p-0 text-white">
                                                        <div className="widget-content-wrapper">
                                                            <div className="widget-content-left flex2">
                                                                <div className="widget-subheading opacity-7">{game.name}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="text-center">
                                                  <input type="checkbox" hidden="hidden" id={game?._id} checked={game[GAMES_ENUM[this.state.page]]} />
                                                  <label class="switch" htmlFor="username" checked={game[GAMES_ENUM[this.state.page]]} onClick={()=>this.handleClick(game._id,!game[GAMES_ENUM[this.state.page]])}></label>
                                                 
                                                </td>
                                            </tr>
                                          );
                                        })
                                      }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          );
        }else{
          return null;
        }
       
    }
}