import { toast } from 'material-react-toastify'
import React, { Component } from 'react'
import { Button, Dropdown, Modal } from 'react-bootstrap'
import { PARTICIPANTS_TYPE } from '../../utils/strings.utils'

export default class AddRule extends Component {

    constructor(props) {
        super(props)
        this.state = {
            rule : null
        }
    }

    changeValue = (label, val) =>{
        this.setState({
          [label]: (val ? val : 0)
        });
    }


    onSubmit = () => {
        if(this.state.rule === undefined || this.state.rule === null || this.state.rule.trim() === ""){
            toast.warning("Please enter a valid rule !")
        }else{
            var data = {
//                gameIds : [this.props.gameDetails._id],
                rule: this.state.rule,
                rules: this.props.rules
            } 

            var updatedRules = this.props.rules.concat("&&", this.state.rule);
            this.props.addUpdateRule(updatedRules)
        }

    }
  

    render() {
        
        return (
            <div className='modal-content' style={{border:'none', borderRadius:'0'}}>
                <h5 className='text-center text-white fw-bold mb-0'>Add new {this.props.type}</h5>
                <p className='text-center fs-12 text-white-50 my-0'>{this.props.label} ( {this.props.number} )</p>
                <div className='p-2'>
                    <div className='my-2'>
                        <label><span className='text-white font-bold'>Enter {this.props.label}</span></label>
                        <div className="input-group">
                            <textarea type="text" 
                                className="form-control transperent-box light-border-around text-gray"
                                placeholder='Enter your description' 
                                style={{borderRadius:'10px', minHeight: "150px"}}
                                onChange={(e)=>this.changeValue("rule",e.target.value)}
                            />
                        </div>
                    </div>
                  
                </div>
                
                <div className='d-flex justify-content-center my-3'>
                    <Button onClick={() => { this.onSubmit() }} className="btn btn-primary fw-bold me-1">
                        Save
                    </Button>
                    <Button onClick={() => { this.props.onClose() }} className="btn-primary fw-bold me-1">
                        Close
                    </Button>
                </div>
            </div>
        )
    }
}
