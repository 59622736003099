export const BREADCRUMBS = {
    HOME: {
        HEADING: "Dashboard",
        PATH: ["Dashboard", "Home"],
        PAGE: ["dashboard", "dashboard"],
        LINK: ["/", "/"],
        PARAMS: ["home", "dashboard", "espotz users", "tournament operators", "teams", "den users", "regular tournament", "premium tournament", "raised dispute", "average dispute resolved time", "bets", "popular stages", "all tournaments"]
    },
    ALL_GAMES: {
        HEADING: "All Games",
        PATH: ["Games", "All Games"],
        PAGE: ["all-games", "all-games"],
        LINK: ["/games/all-games", "/games/all-games"],
        PARAMS: ["games", "all-games", "all games", "game center", "dota 2", "pubg", "valorant", "cs-go", "cs go", "lol", "league of legends", "popular stages"]
    },
    POINT_MATRIX: {
        HEADING: "Point Matrix",
        PATH: ["Games", "Point Matrix"],
        PAGE: ["point-matrix", "point-matrix"],
        LINK: ["/games/point-matrix", "/games/point-matrix"],
        PARAMS: ["game", "point", "point-matrix", "point matrix", "matrix", "dota 2", "pubg", "valorant", "cs-go", "cs go", "lol", "league of legends", "popular stages"]
    },
    BETTING: {
        HEADING: "Betting",
        PATH: ["Games", "Betting"],
        PAGE: ["betting", "betting"],
        LINK: ["/games/betting", "/games/betting"],
        PARAMS: ["games", "betting", "bet", "game center", "dota 2", "pubg", "valorant", "cs-go", "cs go", "lol", "league of legends", "popular stages"]
    },
    ENTRY_FEE: {
        HEADING: "Entry Fees",
        PATH: ["Games", "Entry Fees"],
        PAGE: ["entry-fees", "entry-fees"],
        LINK: ["/games/entry-fees", "/games/entry-fees"],
        PARAMS: ["games", "entry-fees", "entry fees", "game center", "dota 2", "pubg", "valorant", "cs-go", "cs go", "lol", "league of legends", "popular stages"]
    },
    PRICE_POOL: {
        HEADING: "Price Pool",
        PATH: ["Games", "Price Pool"],
        PAGE: ["price-pool", "price-pool"],
        LINK: ["/games/price-pool", "/games/price-pool"],
        PARAMS: ["games", "price-pool", "price pool", "game center", "dota 2", "pubg", "valorant", "cs-go", "cs go", "lol", "league of legends", "popular stages"]
    },
    LANDING_PAGE: {
        HEADING: "Landing Page",
        PATH: ["Website", "Landing Page"],
        PAGE: ["landing-page", "landing-page"],
        LINK: ["/website/landing-page", "/website/landing-page"],
        PARAMS: ["website", "landing-pagel", "landing page", "landing", "page", "dota 2", "pubg", "valorant", "cs-go", "cs go", "lol", "league of legends", "popular stages"]
    },
    MANAGE_CRITERIA: {
        HEADING: "Manage Criteria",
        PATH: ["Verification", "Manage Criteria"],
        PAGE: ["point-matrix", "point-matrix"],
        LINK: ["/verification/verification-criteria", "/verification/verification-criteria"],
        PARAMS: ["criteria", "manage", "manage-criteria", "manage criteria"]
    },
    VERIFICATION_CLAN: {
        HEADING: "Clan Verification",
        PATH: ["Verification", "Clan Verification"],
        PAGE: ["clan-verification", "clan-verification"],
        LINK: ["/verification/clan-verification", "/verification/clan-verification"],
        PARAMS: ["verification", "clan", "clan-verification", "clan verification"]
    },
    VERIFICATION_USER: {
        HEADING: "User Verification",
        PATH: ["Verification", "User Verification"],
        PAGE: ["user-verification", "user-verification"],
        LINK: ["/verification/user-verification", "/verification/user-verification"],
        PARAMS: ["verification", "manage", "manage-criteria", "manage criteria"]
    },
    VERIFICATION_ORG: {
        HEADING: "Organization Verification",
        PATH: ["Verification", "Organization Verification"],
        PAGE: ["organization-verification", "organization-verification"],
        LINK: ["/verification/organization-verification", "/verification/organization-verification"],
        PARAMS: ["verification", "manage", "manage-criteria", "manage criteria"]
    },
    USERS_CLANS: {
        HEADING: "All Clans",
        PATH: ["Users", "All Clans"],
        PAGE: ["clans", "clans"],
        LINK: ["/user/clans", "/user/clans"],
        PARAMS: ["clans", "users", "all-clans", "all clans"]
    },
    USERS_ORGS: {
        HEADING: "All Organisations",
        PATH: ["Users", "All Organisations"],
        PAGE: ["organisations", "organisations"],
        LINK: ["/user/organisations", "/user/organisations"],
        PARAMS: ["organisations", "users", "all-organisations", "all organisations"]
    },
    USERS_ADMINS: {
        HEADING: "All Admins",
        PATH: ["Users", "All Admins"],
        PAGE: ["admins", "admins"],
        LINK: ["/user/admins", "/user/admins"],
        PARAMS: ["admins", "users", "all-admins", "all admins"]
    },
    USERS_TEAMS: {
        HEADING: "All Teams",
        PATH: ["Users", "All Teams"],
        PAGE: ["teams", "teams"],
        LINK: ["/user/teams", "/user/teams"],
        PARAMS: ["teams", "all teams", "all-teams"]
    },
    USERS_USERS: {
        HEADING: "All Users",
        PATH: ["Users", "All Users"],
        PAGE: ["users", "users"],
        LINK: ["/user/users", "/user/users"],
        PARAMS: ["all users", "users", "all-users"]
    },
    USERS_BLOCK_USER: {
        HEADING: "Block Users",
        PATH: ["Users", "Block Users"],
        PAGE: ["block-ban-users", "block-ban-users"],
        LINK: ["/user/block-ban-users", "/user/block-ban-users"],
        PARAMS: ["users", "block users", "block"]
    },
    USERS_BLOCK_ORG: {
        HEADING: "Block Organisations",
        PATH: ["Users", "Block Organisations"],
        PAGE: ["block-ban-To", "block-ban-To"],
        LINK: ["/user/block-ban-To", "/user/block-ban-To"],
        PARAMS: ["Organisations", "Block", "Block Organisations"]
    },
    USERS_BLOCK_TEAMS: {
        HEADING: "Block Teams",
        PATH: ["Users", "Block Teams"],
        PAGE: ["block-ban-teams", "block-ban-teams"],
        LINK: ["/user/block-ban-teams", "/user/block-ban-teams"],
        PARAMS: ["teams", "block", "block-teams"]
    },
    USERS_BLOCK_BRANDS: {
        HEADING: "Block Brands",
        PATH: ["Users", "Block Brands"],
        PAGE: ["block-ban-brands", "block-ban-brands"],
        LINK: ["/user/block-ban-brands", "/user/block-ban-brands"],
        PARAMS: ["all users", "users", "all-users"]
    },
    INFORMATION_TEXT: {
        HEADING: "Information Text",
        PATH: ["Website", "Information Text"],
        PAGE: ["information-text", "information-text"],
        LINK: ["/website/information-text", "/website/information-text"],
        PARAMS: ["website", "information-text", "information text", "text", "information", "clan and organisation details", "clan", "organisation", "details", "stage details", "tournament", "create"]
    },
    STATIC_PAGES: {
        HEADING: "Static Pages",
        PATH: ["Website", "Static Pages"],
        PAGE: ["static-pages", "static-pages"],
        LINK: ["/website/static-pages", "/website/static-pages"],
        PARAMS: ["website", "static-pages", "static-pages", "static", "pages",]
    },
    COMMISSION: {
        HEADING: "Commission",
        PATH: ["Commission"],
        PAGE: ["Commission"],
        LINK: ["/commission"],
        PARAMS: ["commission", "Commission"]
    },
    EC_CURRENCY: {
        HEADING: "Commission",
        PATH: ["Commission"],
        PAGE: ["commission"],
        LINK: ["/commission"],
        PARAMS: ["commission", "Commission"]
    },
    NEWS: {
        HEADING: "News",
        PATH: ["Website", "News"],
        PAGE: ["news", "news"],
        LINK: ["/website/news", "/website/news"],
        PARAMS: ["website", "news", "",]
    },
    CONTACT: {
        HEADING: "Contact us",
        PATH: ["Website", "Contact Us"],
        PAGE: ["contact-media", "contact-media"],
        LINK: ["/website/contact-media", "/website/contact-media"],
        PARAMS: ["website", "contact-media", "contact", "media", "",]
    },
    USERS_NOTIFICATIONS: {
        HEADING: "All User's Notifications",
        PATH: ["Notifications", "All Users"],
        PAGE: ["users-notifications", "users-notifications"],
        LINK: ["/notifications/users-notifications", "/notifications/users-notifications"],
        PARAMS: ["notifications", "users-notifications", "users", "notifications", "all",]
    },
    CLANS_NOTIFICATIONS: {
        HEADING: "Clan's Notifications",
        PATH: ["Notifications", "Clan's Notification"],
        PAGE: ["clan-notifications", "clan-notifications"],
        LINK: ["/notifications/clan-notifications", "/notifications/clan-notifications"],
        PARAMS: ["notifications", "clan-notifications", "clans", "notifications", "clan",]
    },
    TEAMS_NOTIFICATIONS: {
        HEADING: "Team's Notifications",
        PATH: ["Notifications", "Team's Notification"],
        PAGE: ["team-notifications", "team-notifications"],
        LINK: ["/notifications/team-notifications", "/notifications/team-notifications"],
        PARAMS: ["notifications", "team-notifications", "teams", "notifications", "team",]
    },
    ORGANISATIONS_NOTIFICATIONS: {
        HEADING: "Organisation's Notifications",
        PATH: ["Notifications", "Organisation's Notification"],
        PAGE: ["organisation-notifications", "organisation-notifications"],
        LINK: ["/notifications/organisation-notifications", "/notifications/organisation-notifications"],
        PARAMS: ["notifications", "organisation-notifications", "organisations", "notifications", "organisation",]
    },
    TOURNAMENTS_NOTIFICATIONS: {
        HEADING: "Tournament's Notifications",
        PATH: ["Notifications", "Tournament's Notification"],
        PAGE: ["tournament-notifications", "tournament-notifications"],
        LINK: ["/notifications/tournament-notifications", "/notifications/tournament-notifications"],
        PARAMS: ["notifications", "tournament-notifications", "tournament", "notifications", "tournaments",]
    },
    BETTINGS_NOTIFICATIONS: {
        HEADING: "Betting's Notifications",
        PATH: ["Notifications", "Betting's Notification"],
        PAGE: ["betting-notifications", "betting-notifications"],
        LINK: ["/notifications/betting-notifications", "/notifications/betting-notifications"],
        PARAMS: ["notifications", "betting-notifications", "betting", "notifications", "bettings",]
    },
    DENS_NOTIFICATIONS: {
        HEADING: "Den's Notifications",
        PATH: ["Notifications", "Den's Notification"],
        PAGE: ["den-notifications", "den-notifications"],
        LINK: ["/notifications/den-notifications", "/notifications/den-notifications"],
        PARAMS: ["notifications", "den-notifications", "den", "notifications", "den's",]
    },
    WALLETS_NOTIFICATIONS: {
        HEADING: "Wallet's Notifications",
        PATH: ["Notifications", "Wallet's Notification"],
        PAGE: ["wallet-notifications", "wallet-notifications"],
        LINK: ["/notifications/wallet-notifications", "/notifications/wallet-notifications"],
        PARAMS: ["notifications", "wallet-notifications", "wallet", "notifications", "wallets",]
    },
    CANCEL_TOURNAMENT: {
        HEADING: "Tournament Cancel",
        PATH: ["Tournament Cancel"],
        PAGE: ["tournament-cancellation"],
        LINK: ["/tournament-cancellation"],
        PARAMS: ["tournament cancel", "cancel", "tournament"]
    },
    TERMS_CONDITIONS: {
        HEADING: "Terms and Conditions",
        PATH: ["Terms and Conditions"],
        PAGE: ["terms-conditions"],
        LINK: ["/terms-conditions"],
        PARAMS: ["terms-conditions", "terms", "conditions"]
    },
    RULES_LANDING: {
        HEADING: "Rules",
        PATH: ["Rules", "Landing Rules"],
        PAGE: ["landing-rules", "landing-rules"],
        LINK: ["/rules/landing-rules", '/rules/landing-rules'],
        PARAMS: ["landing-rules", "landing", "rules"]
    },
    RULES_GAMES: {
        HEADING: "Games Rules",
        PATH: ["Rule's", "Games Rules"],
        PAGE: ["games-rules", "games-rules"],
        LINK: ["/rules/games-rules"],
        PARAMS: ["games-rules", "games rules", "rules", "games"]
    },
    FORMS: {
        HEADING: "Forms",
        PATH: ["Forms"],
        PAGE: ["forms"],
        LINK: ["/forms"],
        PARAMS: ["tournament forms", "forms", "form", "dota 2", "pubg", "valorant", "cs-go", "cs go", "lol", "league of legends", "popular stages"]
    },
    FEATURE_REQUEST: {
        HEADING: "Feature Requests",
        PATH: ["Feature Requests"],
        PAGE: ["feature-request"],
        LINK: ["/feature-request"],
        PARAMS: ["feature-request"]
    },
    BUG_REPORT: {
        HEADING: "Bug Reports",
        PATH: ["Bug Reports"],
        PAGE: ["bug-report"],
        LINK: ["/bug-report"],
        PARAMS: ["bug-report"]
    },
    DEN_STAR: {
        HEADING: "Den Star",
        PATH: ["Den", "Den Star"],
        PAGE: ["den-star", "den-star"],
        LINK: ["/den/den-star", "/den/den-star"],
        PARAMS: ["den", "den star", "star"]
    },
    SPOTLIGHT: {
        HEADING: "Spotlight",
        PATH: ["Den", "Spotlight"],
        PAGE: ["spotlight", "spotlight"],
        LINK: ["/den/spotlight", "/den/spotlight"],
        PARAMS: ["den", "spotlight"]
    },
    SPIN_WHEEL: {
        HEADING: "Set spin wheel rewards",
        PATH: ["Den", "Spin wheel"],
        PAGE: ["spin-wheel", "spin-wheel"],
        LINK: ["/den/spin-wheel", "/den/spin-wheel"],
        PARAMS: ["den", "spin wheel", "spin", "wheel"]
    },
    JOINING_INTERACTION_BONUS: {
        HEADING: "Set up den sign up interaction bonus",
        PATH: ["Den", "Joining interaction bunus"],
        PAGE: ["joining-interaction-bonus", "joining-interaction-bonus"],
        LINK: ["/den/joining-interaction-bonus", "/den/joining-interaction-bonus"],
        PARAMS: ["den", "joining interaction bonus", "interaction bonus", "joining bonus"]
    },
    ACHIEVEMENT_RANK_CRITERIA: {
        HEADING: "Achievement Rank Criteria",
        PATH: ["Den", "Achievement rank criteria"],
        PAGE: ["Achievement-rank-criteria", "Achievement-rank-criteria"],
        LINK: ["/den/Achievement-rank-criteria", "/den/Achievement-rank-criteria"],
        PARAMS: ["den", "achievement rank criteria", "achievement", "rank criteria", "criteria", "rank"]
    },
    DEN_PROFILE_CRITERIA: {
        HEADING: "Den Profile Criteria",
        PATH: ["Den", "Den profile criteria"],
        PAGE: ["den-rank-criteria", "den-profile-criteria"],
        LINK: ["/den/den-rank-criteria", "/den/den-rank-criteria"],
        PARAMS: ["den", "den profile criteria", "den profile", "profile criteria"]
    },
    HASHTAGS: {
        HEADING: "Hashtags",
        SUB_HEADING: "Add Hashtag",
        PATH: ["Den", "Hashtags"],
        PAGE: ["hashtags", "hashtags"],
        LINK: ["/den/hashtags", "/den/hashtags"],
        PARAMS: ["den", "hashtags"]
    },
    ACHIEVE_RANK_TITLE: {
        HEADING: "Achievement rank title",
        PATH: ["Den", "Achievement rank title"],
        PAGE: ["Achievement-rank-title", "Achievement-rank-title"],
        LINK: ["/den/Achievement-rank-title", "/den/Achievement-rank-title"],
        PARAMS: ["den", "achievement rank title", "rank title", "achievement", "achievement title"]
    },
    DEN_PROFILE_RANK_TITLE: {
        HEADING: "Den profile rank title",
        PATH: ["Den", "Den profile rank title"],
        PAGE: ["Den-profile-rank-title", "Den-profile-rank-title"],
        LINK: ["/den/Den-profile-rank-title", "/den/Den-profile-rank-title"],
        PARAMS: ["den", "den profile rank title", "profile rank title", "rank title"]
    },
    REPORTED_POSTS: {
        HEADING: "Reported Posts",
        PATH: ["Den", "Reported Posts"],
        PAGE: ["reported-posts", "reported-posts"],
        LINK: ["/den/reported-posts", "/den/reported-posts"],
        PARAMS: ["den", "reported posts"]
    },
    BETTING_CRITERIA: {
        HEADING: "Set up betting elgibility",
        PATH: ["Betting", "Betting criteria"],
        PAGE: ["betting-criteria", "betting-criteria"],
        LINK: ["/betting/betting-criteria", "/betting/betting-criteria"],
        PARAMS: ["betting", "betting criteria"]
    },
    ORGANISATION: {
        HEADING: "Enable betting for organisation",
        PATH: ["Betting", "betting organisation"],
        PAGE: ["betting-organisation", "betting-organisation"],
        LINK: ["/betting/betting-organisation", "/betting/betting-organisation"],
        PARAMS: ["betting", "betting organisation"]
    },
    BETTING_DATA: {
        HEADING: "Database",
        PATH: ["Betting", "betting data"],
        PAGE: ["betting-data", "betting-data"],
        LINK: ["/betting/betting-data", "/betting/betting-data"],
        PARAMS: ["betting", "betting data"]
    },
    BETTING_STATEMENTS: {
        HEADING: "Betting Statments",
        SUB_HEADING: "Set up betting statements",
        PATH: ["Betting", "Betting Statements"],
        PAGE: ["betting-statements", "betting-statements"],
        LINK: ["/betting/betting-statements", "/betting/betting-statements"],
        PARAMS: ["betting", "betting statements", "statement", "Set up",]
    },
    WALLETS_TOURNAMENTS: {
        HEADING: "Tournaments",
        PATH: ["wallet", "tournament-transaction"],
        PAGE: ["tournament-transaction", "tournament-transaction"],
        LINK: ["/wallet/tournament-transaction", "/wallet/tournament-transaction"],
        PARAMS: ["wallet", "tournament", "wallet tournament"]
    },
    WALLETS_TOURNAMENT_WINNERS: {
        HEADING: "Tournament Winners",
        PATH: ["Wallet", "Tournament Winners"],
        PAGE: ["tournament-winners", "tournament-winners"],
        LINK: ["/wallet/tournament-winners", "/wallet/tournament-winners"],
        PARAMS: ["wallet", "transaction", "wallet transaction"]
    },
    WALLETS_TOURNAMENTS_BETTING: {
        HEADING: "Betting Winners",
        PATH: ["Wallet", "Betting Winners"],
        PAGE: ["betting-winners", "betting-winners"],
        LINK: ["/wallet/betting-winners", "/wallet/betting-winners"],
        PARAMS: ["wallet", "betting", "betting tournaments", "tournaments"]
    },
    WALLETS_TRANSACTION: {
        HEADING: "Transaction",
        PATH: ["Wallet", "EC Deposite Withdrawls"],
        PAGE: ["ec-deposite-withdrawls", "ec-deposite-withdrawls"],
        LINK: ["/wallet/ec-deposite-withdrawls", "/wallet/ec-deposite-withdrawls"],
        PARAMS: ["wallet", "transaction", "wallet transaction"]
    },
    BETTING_DATA_WINNERS: {
        HEADING: "Betting winners",
        PATH: ["Betting", "Betting Data", "Winners"],
        PAGE: ["betting-data-winners", "betting-data-winners"],
        LINK: ["/betting/betting-data-winners", "/betting/betting-data-winners"],
        PARAMS: ["betting", "betting winners", "user id", "name", "betting statements", "betting amount", "winning"]
    }

}