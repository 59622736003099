import * as axios from "axios";
import { queryParams, token } from "../utils/common.utils";

export default class DashboardService {
    constructor() {
        this.client = null;
        this.api_token = null;
        this.api_url = process.env.REACT_APP_API_URL;
    }
    init = () => {
        this.api_token = token();
        let headers = {
            Accept: "application/json",
        };
        if (this.api_token) {
            headers.Authorization = this.api_token;
        }

        this.client = axios.create({
            baseURL: this.api_url,
            timeout: 31000,
            headers: headers,
        });

        return this.client;
    };

    
    getDashboardDetails = () => {
        return this.init().get("/admin/api/v1/dashboard/getDashboardDetails");
    };

    getTournamentDetails = (params) => {
        const searchString = queryParams(params)
        return this.init().get(`/admin/api/v1/dashboard/getTournament?${searchString}`);
    };
    
}