
import React, { Component } from 'react';
import { FORM_DATATYPES, HEADING, PARTICIPANTS_TYPE, PLACEHOLDER, POINTS_RANK, WEBSITE } from '../../utils/strings.utils';
import { addBettingStatements, getBettingStatements, getGamesDetails, deleteBettingStatement, routeTo, updateBettingStatement, updateLoader, updateWebsiteDetails } from '../../utils/common.utils';
import { BREADCRUMBS } from '../../utils/breadcrumbs.utils';

import "../../assets/css/switch.css";
import "../../assets/css/game.css";
import { toast } from 'material-react-toastify';
import Dropdown from 'react-bootstrap/Dropdown';
import { Modal } from 'react-bootstrap';
import AddStatement from '../popup/AddStatement';
import Confirmation from '../common/Confirmation';

export default class BettingStatment extends Component {

    constructor(props) {
        super(props);
        this.state = { 
          gameDetails : null,
          bettingStatements : null,
          selectedChoice : 0,
          addNewQuestion : false,
          openConfirmationModal : false,
          deleteIndex : null,
          editIndex: -1,
          updateValue : null
        };

    }
    
  
    componentDidMount(){
      getGamesDetails(this.setGamesDetails);
      getBettingStatements({},this.setBettingStatements);
    }

    setGamesDetails = (gameDetails) =>{
      this.setState({
        gameDetails: gameDetails
      });
    }

  

    setBettingStatements = (bettingStatements) => {
      this.setState({
        bettingStatements: bettingStatements,
        addNewQuestion : false,
        editIndex: -1,
        updateValue : null,
        deleteIndex : null,
        openConfirmationModal : false
      });
    }

    // Save chaged value in state (temporary)
    changeValue = (val) =>{
      this.setState({
        updateValue: (val ? val : '')
      });
    }

    onSelectOption = (index) =>{
      this.setState({
        selectedChoice : index
      });
      this.setBettingStatements(this.state.bettingStatements)
    }

    
    addNewQuestion = (newStatement) => {
      addBettingStatements(newStatement, this.setBettingStatements)
    }

   
    // On Delete confirmation
    confirmDelete = () => {
      console.log("-deleteIndex------------",this.state.deleteIndex)
      deleteBettingStatement(this.state.deleteIndex, this.updateBettingStatement2)
    }

    updateBettingStatement2 = () => {
      getBettingStatements({},this.setBettingStatements);
    }

    enableEditOption = (index, statements) =>{
      console.log('index, statements', index, statements);
      this.setState({
        editIndex: index,
        updateValue : statements.statement ? statements.statement : ''
      });
    }

    saveAndCloseEditOption = (index, statement) =>{
      updateBettingStatement(statement._id, { statement : this.state.updateValue }, this.setBettingStatements)
    }

    onChangeOption = (choice, statement) =>{
      console.log("statements :: ", statement)
      console.log("Choice :: ", choice)
      updateBettingStatement(statement._id, { type : choice }, this.setBettingStatements) 
    }
  

    render() {

        if(this.state.gameDetails && this.state.bettingStatements){
        
          var filteredBettingStatements = this.state.bettingStatements.filter(statement => (statement.gameIds.includes(this.state.gameDetails[this.state.selectedChoice]._id)));
          console.log("filteredBettingStatements :: ",filteredBettingStatements);
          const {editIndex} = this.state;
          
          return (
            <div className="app-main__inner">
                {/* HEAD */}
                <div className="d-flex flex-column flex-lg-row">
                    {/* MAIN-HEAD */}
                    <div className="flex-grow-1">
                        <h3 className='text-light-white'>{BREADCRUMBS.BETTING_STATEMENTS.HEADING}</h3>
                    </div>
                    {/* BRAD-CRUMB */}
                    <div className="">
                        <ol className="breadcrumb color-white" style={{ background: 'none', padding: '0px' }}>
                            {BREADCRUMBS.BETTING_STATEMENTS.PATH.map((route,i)=>{
                                return <li key={i} className="breadcrumb-item cursor-pointer" onClick={() => { routeTo('/','dashboard')  }}>{route}</li>
                            })}
                        </ol>
                    </div>
                </div>
                {/* BODY */}
                <div className="row">
                    <div className="col-md-12 d-flex">
                        <div className="main-card mb-3 card transperent-card text-white p-4 mx-auto">
                            {/* SUB-HEAD */}
                            <div className="card-header no-background p-0 mb-2 ">  {BREADCRUMBS.BETTING_STATEMENTS.SUB_HEADING}
                                {/* FILTER */}
                                <div className="btn-actions-pane-right">
                                 
                                  <Dropdown>
                                    <Dropdown.Toggle variant="success" className='transperent-box light-border-around text-white' id="dropdown-basic">
                                      <b className='px-5'>{this.state.gameDetails[this.state.selectedChoice].name}</b>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                      {this.state.gameDetails?.map((game,i)=>{
                                        if(this.state.selectedChoice !== i){
                                          return (
                                            <Dropdown.Item key={i} onClick={()=>this.onSelectOption(i)}>
                                              <b className='px-4'>{game.name}</b> 
                                            </Dropdown.Item>
                                          )
                                        }
                                      })}
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                            </div>
                            {/* MAIN_BODY */}
                            <div className="container">
                              <div className="row">
                                {filteredBettingStatements.map((statementDetails, i)=>{
                                const isEditEnabled = (editIndex === i);
                                const actionLabel = isEditEnabled ? 'Save' : 'Edit';
                                const action = isEditEnabled ? this.saveAndCloseEditOption : this.enableEditOption;
                        
                                return (
                                  <div className='row' key={i}>
                                  <div className="col-12 col-md-10" >
                                    <div className='my-3' key={1}>
                                      <label><span className=' font-bold'>Question #{i+1}</span></label>
                                      <div className="input-group">
                                      {/* MAX LENGTH */}
                                      <input type="text" 
                                          className="form-control transperent-box light-border-around text-gray"
                                          style={{borderTopLeftRadius:'10px', borderBottomLeftRadius: '10px'}}
                                          value={isEditEnabled ? this.state.updateValue : statementDetails.statement}
                                          placeholder={PLACEHOLDER.ENTER_STATEMENT}
                                          autoComplete="off"
                                          readOnly={!isEditEnabled}
                                          onChange={(e)=>this.changeValue(e.target.value)} 
                                        />
                                        <div className="input-group-append ">
                                          <button onClick={()=> action(i, statementDetails) } className="btn btn-primary light-border-around fw-bold" type="button">{actionLabel}</button>
                                          <button onClick={()=>this.setState({ deleteIndex: statementDetails._id, openConfirmationModal: true })} className="btn btn-primary light-border-around fw-bold rounded-border-right" type="button">Delete</button>
                                        </div>
                                      </div>
                                      {/* INPUT TYPES */}
                                      
                                    
                                    </div>
                                  </div>
                                  <div className="col-12 col-md-2" key={statementDetails._id}>
                                    <div className='my-3'>
                                      <label><span className=' font-bold'>Option</span></label>
                                      <div className="input-group">
                                    
                                        <Dropdown>
                                          <Dropdown.Toggle variant="success" className='transperent-box light-border-around text-white' id="dropdown-basic">
                                            <b className='px-5'>{PARTICIPANTS_TYPE[statementDetails.type]}</b>
                                          </Dropdown.Toggle>

                                          <Dropdown.Menu>
                                            {Object.keys(PARTICIPANTS_TYPE).map((key,i)=>{
                                              if(statementDetails.type !== key){
                                                return (
                                                  <Dropdown.Item key={i} onClick={()=>this.onChangeOption(key, statementDetails)} >
                                                    <b className='px-4'>{PARTICIPANTS_TYPE[key]}</b> 
                                                  </Dropdown.Item>
                                                )
                                              }
                                            })}
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      </div>
                                    
                                    </div>
                                  </div>
                                </div>

                                )
                                })}
                                
                              </div>
                              {/* ADD MORE FIELDS */}
                              <div className='d-flex '>
                                <div className="my-2 ms-2" >
                                  <button type="button" onClick={()=>this.setState({addNewQuestion : true})} className="btn btn-primary">+ {WEBSITE.BETTING.BETTING_STATEMENTS.ADD_MORE}</button>
                                </div>
                              </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal show={this.state.addNewQuestion} centered dialogClassName=''>
                  <Modal.Body>
                    <AddStatement
                      message={`Are you sure you want to delete this reason no.  from reason's ?`}
                      gameDetails={this.state.gameDetails[this.state.selectedChoice]}
                      addNewQuestion={this.addNewQuestion}
                      number={filteredBettingStatements.length+1}
                      onConfirm={this.deleteReason} onClose={() => this.setState({ addNewQuestion : false })}
                    />
                  </Modal.Body>
                </Modal>
                <Modal show={this.state.openConfirmationModal} centered dialogClassName=''>
                  <Modal.Body>
                    <Confirmation  
                      message={`Are you sure you want to delete this question from betting statements ?`} 
                      onConfirm={this.confirmDelete} onClose={()=>this.setState({openConfirmationModal: false, deleteIndex: -1})} 
                    />
                  </Modal.Body>
                </Modal>
            </div>
          );
        }else{
          return null;
        }
       
    }
}