import React, { Component } from 'react';
import { HEADING, POINTS_RANK, DEN } from '../../utils/strings.utils';
import { getDenDetails, updateDenDetails, routeTo } from '../../utils/common.utils';
import { BREADCRUMBS } from '../../utils/breadcrumbs.utils';
import { DEN_ENUM } from '../../utils/strings.utils';
import "../../assets/css/switch.css";
import "../../assets/css/game.css";
import { toast } from 'material-react-toastify';
import { AiOutlineClose } from "react-icons/ai";
import user from '../../assets/images/profile/3.jpg';
import { Button, Carousel } from 'react-bootstrap';
import { BiBlock } from "react-icons/bi";
import media1 from "../../assets/images/media/media-1.png";
import media2 from "../../assets/images/media/media-2.png";
import media3 from "../../assets/images/media/media-3.png";

export default class DenReportedPosts extends Component {
  
  constructor(props) {
    super(props);
    this.state = { 
      denDetails : null,
      selectedChoice : 0,
      editLabel : ''
    };
  }

  componentDidMount(){
    getDenDetails(`type=REPORTED_POSTS`,this.setDenDetails);
  }

  setDenDetails = (denDetails) =>{
    if(denDetails){
      //denDetails = denDetails.sort((a, b) => a.subType.toLowerCase().localeCompare(b.subType.toLowerCase()))
      this.setState({
        denDetails: denDetails,
        updateValue : null,
        editLabel : ''
      });
    }
  }

  updateEditLabel = (label) => {
    console.log('Label ::', label);
    this.setState({editLabel : label})
  }

  render() {
    const changeValue = this.changeValue;
    const { denDetails, selectedChoice} = this.state;
    
    if(this.state.denDetails){
      //console.log('Questions ::', this.state.denDetails[0]?.questions)
      return (
        <div className="app-main__inner flex-grow-1 d-flex flex-column">
            {/* HEAD */}
            <div className="d-flex flex-column flex-lg-row">
                {/* MAIN-HEAD */}
                <div className="flex-grow-1">
                    <h3 className='text-light-white'>{BREADCRUMBS.REPORTED_POSTS.HEADING}</h3>
                </div>
                {/* BRAD-CRUMB */}
                <div className="">
                    <ol className="breadcrumb color-white" style={{ background: 'none', padding: '0px' }}>
                        {BREADCRUMBS.REPORTED_POSTS.PATH.map((route,i)=>{
                            return <li key={i} className="breadcrumb-item cursor-pointer" onClick={() => { routeTo('/','dashboard')  }}>{route}</li>
                        })}
                    </ol>
                </div>
            </div>
            {/* BODY */}
            <div className="main-card mb-3 card transperent-card text-white p-4 flex-grow-1 flex-shrink-0">

              <div className="row">
                  <div className="col-12 mb-2">
                      <div className="den-reported-post-tab p-2" style={{ minHeight: '150px'}}>
                          <div className='d-flex'>
                              <div className='d-flex flex-column flex-md-row flex-grow-1'>
                                  <div className='my-2 me-3'>
                                    <img src={user} alt='user' className='user-profile-image' />
                                    <span className='fs-16 fw-600 ms-2'>Denzel Washington DC</span>
                                  </div>
                                  <Button variant='danger' size='' className='text-white fs-bold me-3 my-2'><BiBlock className='me-2' />Block User</Button>
                                  <Button variant='warning' size='' className='text-white fs-bold my-2'>Take down post from Den</Button>
                              </div>
                              <div><AiOutlineClose className='close-icon' /></div>
                          </div>
                          <p className='fs-14 fw-500'>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. In dignissim consectetur lorem, vitae hendrerit dolor ultrices eu. Mauris eu feugiat massa. Interdum et malesuada fames ac ante ipsum primis in faucibus.
                            Nunc et congue ipsum. Fusce lacinia arcu in tempor auctor. Duis a lacus id risus efficitur tincidunt. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.

                            Maecenas ut ipsum vel sapien tincidunt bibendum et quis orci. Proin erat neque, consectetur ut felis ut, pretium pharetra nisl. Ut sem odio, viverra vel mattis vel, posuere a lectus. Pellentesque vitae eros euismod, vehicula tortor et, aliquam elit.
                            Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; 
                          </p>
                          <div className="row">
                            <div className="col-12 col-md-4">
                              <Carousel>
                                <Carousel.Item><img src={media1} alt='' className='w-100' /></Carousel.Item>
                                <Carousel.Item><img src={media2} alt='' className='w-100' /></Carousel.Item>
                                <Carousel.Item><img src={media3} alt='' className='w-100' /></Carousel.Item>
                              </Carousel>
                            </div>
                            <div className="col-12 col-md-8">
                              <p className='fs-16 fw-600'>Total reports: {459}</p>

                              <div className='row'>
                                <div className='col-12 col-md-6'>
                                  <p className='fs-14 fw-500 text-white-50'>Sexual content:  45</p>
                                </div>
                                <div className='col-12 col-md-6'>
                                  <p className='fs-14 fw-500 text-white-50'>Violent or repulsive content:  21</p>
                                </div>
                                <div className='col-12 col-md-6'>
                                  <p className='fs-14 fw-500 text-white-50'>Hateful or abusive content:  22</p>
                                </div>
                                <div className='col-12 col-md-6'>
                                  <p className='fs-14 fw-500 text-white-50'>Harmful or dangerous acts:  99</p>
                                </div>
                                <div className='col-12 col-md-6'>
                                  <p className='fs-14 fw-500 text-white-50'>Spam or misleading:  87</p>
                                </div>
                                <div className='col-12 col-md-6'>
                                  <p className='fs-14 fw-500 text-white-50'>Child abuse:  54</p>
                                </div>
                              </div>

                              <p className='fs-14 fw-500 text-white-50'>Others:  2</p>
                              <ul>
                                <li>Spoling the new generation</li>
                                <li>Illegal act 497, no one should post with abusive pictures in social platform</li>
                              </ul>
                            </div>
                          </div>
                      </div>
                  </div>
                  <div className="col-12 mb-2">
                      <div className="den-reported-post-tab p-2" style={{ minHeight: '150px'}}>
                          <div className='d-flex'>
                              <div className='d-flex flex-column flex-md-row flex-grow-1'>
                                  <div className='my-2 me-3'>
                                    <img src={user} alt='user' className='user-profile-image' />
                                    <span className='fs-16 fw-600 ms-2'>Denzel Washington DC</span>
                                  </div>
                                  <Button variant='danger' size='' className='text-white fs-bold me-3 my-2'><BiBlock className='me-2' />Block User</Button>
                                  <Button variant='warning' size='' className='text-white fs-bold my-2'>Take down post from Den</Button>
                              </div>
                              <div><AiOutlineClose className='close-icon' /></div>
                          </div>
                          <p className='fs-14 fw-500'>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. In dignissim consectetur lorem, vitae hendrerit dolor ultrices eu. Mauris eu feugiat massa. Interdum et malesuada fames ac ante ipsum primis in faucibus.
                            Nunc et congue ipsum. Fusce lacinia arcu in tempor auctor. Duis a lacus id risus efficitur tincidunt. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.

                            Maecenas ut ipsum vel sapien tincidunt bibendum et quis orci. Proin erat neque, consectetur ut felis ut, pretium pharetra nisl. Ut sem odio, viverra vel mattis vel, posuere a lectus. Pellentesque vitae eros euismod, vehicula tortor et, aliquam elit.
                            Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; 
                          </p>
                          <div className="row">
                            <div className="col-12 col-md-4">
                              <Carousel>
                                <Carousel.Item><img src={media1} alt='' className='w-100' /></Carousel.Item>
                                <Carousel.Item><img src={media2} alt='' className='w-100' /></Carousel.Item>
                                <Carousel.Item><img src={media3} alt='' className='w-100' /></Carousel.Item>
                              </Carousel>
                            </div>
                            <div className="col-12 col-md-8">
                              <p className='fs-16 fw-600'>Total reports: {459}</p>

                              <div className='row'>
                                <div className='col-12 col-md-6'>
                                  <p className='fs-14 fw-500 text-white-50'>Sexual content:  45</p>
                                </div>
                                <div className='col-12 col-md-6'>
                                  <p className='fs-14 fw-500 text-white-50'>Violent or repulsive content:  21</p>
                                </div>
                                <div className='col-12 col-md-6'>
                                  <p className='fs-14 fw-500 text-white-50'>Hateful or abusive content:  22</p>
                                </div>
                                <div className='col-12 col-md-6'>
                                  <p className='fs-14 fw-500 text-white-50'>Harmful or dangerous acts:  99</p>
                                </div>
                                <div className='col-12 col-md-6'>
                                  <p className='fs-14 fw-500 text-white-50'>Spam or misleading:  87</p>
                                </div>
                                <div className='col-12 col-md-6'>
                                  <p className='fs-14 fw-500 text-white-50'>Child abuse:  54</p>
                                </div>
                              </div>

                              <p className='fs-14 fw-500 text-white-50'>Others:  2</p>
                              <ul>
                                <li>Spoling the new generation</li>
                                <li>Illegal act 497, no one should post with abusive pictures in social platform</li>
                              </ul>
                            </div>
                          </div>
                      </div>
                  </div>
              </div>
              
            </div>
        </div>
      );
    }else{
      return null;
    }
   
}
}
