import { Component } from 'react';
import { TableComponent } from '../common/DataTable';
import UserService from '../../services/user.service';
import { BREADCRUMBS } from '../../utils/breadcrumbs.utils';
import { convertDateTime, routeTo, updateLoader } from '../../utils/common.utils';

export default class Teams extends Component {
    constructor(props) {
        super(props)
        this.state = {
            teams: [],
            totalResult: 0,
            params: {
                pageNo: 0,
                limit: 10
            }
        }
        this.authApi = new UserService();
        this.columns = [
            {
                name: "ID",
                selector: (row) => [row._id],
                sortable: false,
            },
            {
                name: "NAME",
                selector: (row) => [row.name],
                sortable: false,
            },
            {
                name: "USERNAME",
                selector: (row) => [row.username],
                sortable: false,
            },
            {
                name: "EMAIL VERIFIED",
                selector: (row) => [row.isEmailVerified ? 'YES' : 'ON'],
                sortable: false,
            },
            {
                name: "CREATED ON",
                selector: (row) => [convertDateTime(row.createdAt)],
                sortable: false,
            }
        ]
    }
    componentDidMount() {
        this.getTeams(this.state.params)
    }
    onPagination = (i, key) => {
        let paramsTemp = this.state.params
        paramsTemp[key] = i
        this.setState({ params: paramsTemp })
        console.log(`${key}`, paramsTemp)
        this.getTeams(paramsTemp)
    }

    getTeams = (params) => {
        updateLoader(true)
        this.authApi.getAllTeams(params).then(
            (res) => {
                let teams = res.data.result.map((el, i) => { el.index = i; return el })
                this.setState({ teams: teams, totalResult: res.data.totalResults })
                console.log("res - ", res.data.result)
                updateLoader(false)
            }
        ).catch(
            (err) => {
                console.error(err)
                updateLoader(false)
            }
        )
    };
    render() {
        return (
            <div className="app-main__inner">
                <div className="d-flex flex-column flex-lg-row">
                    <div className="flex-grow-1">
                        <h3 className='text-white'>{BREADCRUMBS.USERS_TEAMS.HEADING}</h3>


                    </div>
                    <div className="">
                        <ol className="breadcrumb color-white" style={{ background: 'none', padding: '0px' }}>
                            {BREADCRUMBS.USERS_TEAMS.PATH.map((route, i) => {
                                return <li key={i} className="breadcrumb-item cursor-pointer" onClick={() => { routeTo('/', 'dashboard') }}>{route}</li>
                            })}
                        </ol>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="main-card mb-3 card transperent-card text-white p-4">
                            <div className="card-header no-background p-0 mb-2">
                                <div className="btn-actions-pane-right">
                                    <div className="input-group icons search-box">
                                        <input type="search" className="form-control rounded text-white transperent-box light-border-around" placeholder="Search in table ...." />
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <TableComponent column={this.columns} data={this.state.teams} paginationTotalRows={this.state.totalResult} onPagination={this.onPagination} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
