import React, { Component } from 'react';
import { HEADING, POINTS_RANK, DEN } from '../../utils/strings.utils';
import { getDenDetails, updateDenDetails, routeTo } from '../../utils/common.utils';
import { BREADCRUMBS } from '../../utils/breadcrumbs.utils';
import { DEN_ENUM } from '../../utils/strings.utils';
import "../../assets/css/switch.css";
import "../../assets/css/game.css";
import { toast } from 'material-react-toastify';

export default class DenStar extends Component {
  
  constructor(props) {
    super(props);
    this.state = { 
      denDetails : null,
      selectedChoice : 0,
      editLabel : ''
    };
  }

  componentDidMount(){
    getDenDetails(`type=DEN_STAR`,this.setDenDetails);
  }

  setDenDetails = (denDetails) =>{
    if(denDetails){
      denDetails = denDetails.sort((a, b) => a.subType.toLowerCase().localeCompare(b.subType.toLowerCase()))
      this.setState({
        denDetails: denDetails,
        updateValue : null,
        editLabel : ''
      });
    }
  }

  changeValue = (val) =>{
    const {editLabel, denDetails} = this.state;
    //console.log('old denDetails', denDetails)
    denDetails[0].questions[editLabel] = val;
    //console.log('updated denDetails', denDetails);
    this.setState({ denDetails: denDetails })
  }

  updateData = () => {
    const id = this.state.denDetails[0]?._id;
    console.log('------ id', this.state.denDetails[0]?._id);

    if(isNaN(parseFloat(this.state.denDetails[0]?.questions[this.state.editLabel]))){
      toast.warning('Input value is required');
      return false;
    }

    if(!(parseFloat(this.state.denDetails[0]?.questions[this.state.editLabel])>0)){
      toast.warning('Input value should be greater than zero');
      return false;
    }
    const data = this.state.denDetails[0]?.questions;
    updateDenDetails(id, data, this.setDenDetails);
  }

  updateEditLabel = (label) => {
    console.log('Label ::', label);
    this.setState({editLabel : label})
  }

  render() {
    const changeValue = this.changeValue;
    const { denDetails, selectedChoice} = this.state;
    
    if(this.state.denDetails){
      //console.log('Questions ::', this.state.denDetails[0]?.questions)
      return (
        <div className="app-main__inner">
            {/* HEAD */}
            <div className="d-flex flex-column flex-lg-row">
                {/* MAIN-HEAD */}
                <div className="flex-grow-1">
                    <h3 className='text-light-white'>{BREADCRUMBS.DEN_STAR.HEADING}</h3>
                </div>
                {/* BRAD-CRUMB */}
                <div className="">
                    <ol className="breadcrumb color-white" style={{ background: 'none', padding: '0px' }}>
                        {BREADCRUMBS.DEN_STAR.PATH.map((route,i)=>{
                            return <li key={i} className="breadcrumb-item cursor-pointer" onClick={() => { routeTo('/','dashboard')  }}>{route}</li>
                        })}
                    </ol>
                </div>
            </div>
            {/* BODY */}
            <div className="row">
            <div className="col-md-12">
              <div className="main-card mb-3 card transperent-card text-white p-4">
                  
              {DEN_ENUM?.DEN_STAR.map((question,i) => {
                //console.log('value ::', this.state.denDetails[selectedChoice].questions[question.key]);
                return (
                  <div className='col-12  my-2' key={i}>
                    <label><span className=' font-bold'>{question.title}</span></label>
                  
                    <div className="input-group">
                      <input type="number"
                        onChange={(e)=>changeValue(parseInt(e.target.value))}
                        value={this.state.denDetails[selectedChoice]?.questions[question.key]} //denDetails[selectedChoice].questions[key]
                        placeholder={'Enter a number'} //`Enter ${WEBSITE.INFORMATION_TEXT[selectedOption][key]} `
                        className="form-control transperent-box light-border-around text-gray" 
                        aria-label="Recipient's username" aria-describedby="basic-addon2"
                        style={{borderTopLeftRadius:'10px', borderBottomLeftRadius: '10px'}}
                        disabled={this.state.editLabel === question.key ? false : true}
                        min={0} max={10000} step={1} onWheel={(e) => e.target.blur()}
                      />
                      <div className="input-group-append">
                        <button className="btn edt-btn light-border-around" type="button" style={{borderTopRightRadius:'10px', borderBottomRightRadius: '10px'}}
                          onClick={(e) => {this.state.editLabel === question.key ? this.updateData(e.target.value) : this.updateEditLabel(question.key)}}
                        >
                          {this.state.editLabel === question.key ? 'Save' : 'Edit'} 
                        </button>
                      </div>
                    </div>
                  </div>
                )
              })}
              </div>
              </div>
            </div>
        </div>
      );
    }else{
      return null;
    }
   
}
}
