import { Component } from 'react';
import { TableComponent } from '../common/DataTable';
import { getBetTournamentDetails, routeTo, withRouter } from '../../utils/common.utils';
import { BREADCRUMBS } from '../../utils/breadcrumbs.utils';
import { Dropdown } from 'react-bootstrap';
import { DebounceInput } from 'react-debounce-input';

const DROPDOWN_OPTIONS = [
    { name: 'TO Name', term: 'tournamentOperatorName' },
    { name: 'Tournament', term: 'tournamentName' },
    { name: 'Game', term: 'game' },
]

class BettingData extends Component {
    constructor(props) {
        super(props)
        this.state = {
            organisations: [],
            totalResult: 0,
            params: {
                pageNo: 0,
                limit: 10
            },
            selectedIndex: 0
        }
        
        this.columns = [
            {
                name: "T.O",
                selector: (row) => [row.organizationName],
                sortable: true,
            },
            {
                name: "TOURNAMENT",
                selector: (row) => [row.tournamentName],
                sortable: true,
            },
            {
                name: "GAME",
                selector: (row) => [row.game],
                sortable: true,
            },
            {
                name: "BET MATCHES",
                selector: (row) => [row.betMatches],
                sortable: true,
            },
            {
                name: "DISPUTES",
                selector: (row) => [row.dispute],
                sortable: true,
            },
            {
                name: "BETTING WINNERS",
                cell: (row) => {
                    return <span> <button className='mr-2 btn btn-link' onClick={() => { this.viewAllBetting( row.tournamentId ) }} id={row.id}>View all</button></span>
                },
                ignoreRowClick: true,
                allowOverflow: true,
                button: true,
            },
            {
              name: "BETTING COLLECTION",
              selector: (row) => [row.bettingCollection],
              sortable: true,
            },
            {
              name: "RATING",
              selector: (row) => [row.rating],
              sortable: true,
            },
            {
              name: "BETTING PRIZE POOL",
              selector: (row) => [row.bettingPricePool],
              sortable: true,
            },
            {
              name: "TO's COMMISSION",
              selector: (row) => [row.toCommissions],
              sortable: true,
            },
            {
              name: "ESPOTZ's COMMISSION",
              selector: (row) => [row.espotzCommissions],
              sortable: true,
            },
        ]
    }

    componentDidMount() {
      getBetTournamentDetails(this.state.params, this.setBettingDataDetails)
    }

    viewAllBetting = (id) => {
        this.props.router.navigate(`/betting/betting-data-winners/${id}`)
    }

    setBettingDataDetails = (res) => {
        if(res){
            let organisations = res.data?.result.map((el, i) => { el.index = i; return el })
            this.setState({ organisations: organisations, totalResult: res.data.totalResults })
        }
    }

    onPagination = (i, key) => {
      let paramsTemp = this.state.params
      paramsTemp[key] = i
      this.setState({ params: paramsTemp })
      //console.log(`${key}`, paramsTemp)
      getBetTournamentDetails(paramsTemp, this.setBettingDataDetails)
    }

    onSearch = (val) => {
        let params = {
            [DROPDOWN_OPTIONS[this.state.selectedIndex].term]: val,
            pageNo: 0,
            limit: this.state?.params?.limit ?this.state.params.limit : 10
        }
        if (!val) delete params[DROPDOWN_OPTIONS[this.state.selectedIndex].term];
        this.setState({ params: params })
        console.log('parmas ::', params);
        getBetTournamentDetails(params, this.setBettingDataDetails);
    }

    render() {
        return (
            <div className="app-main__inner">
                <div className="d-flex flex-column flex-lg-row">
                    <div className="flex-grow-1">
                        <h3 className='text-white'>{BREADCRUMBS.BETTING_DATA.HEADING}</h3>


                    </div>
                    <div className="">
                        <ol className="breadcrumb color-white" style={{ background: 'none', padding: '0px' }}>
                            {BREADCRUMBS.BETTING_DATA.PATH.map((route, i) => {
                                return <li key={i} className="breadcrumb-item cursor-pointer" onClick={() => { routeTo('/', 'dashboard') }}>{route}</li>
                            })}
                        </ol>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="main-card mb-3 card transperent-card text-white p-4">
                            <div className="card-header no-background p-0 mb-2">
                                <div className="btn-actions-pane-right">
                                    {/* <div className="input-group icons search-box">
                                        <input type="search" className="form-control rounded text-white transperent-box light-border-around" placeholder="Search in table ...." />
                                    </div> */}
                                    <div className="input-group icons search-box">
                                        <Dropdown>
                                            <Dropdown.Toggle variant="success" className='h-45 transperent-box light-border-around text-white' id="dropdown-basic">
                                                <b className='px-5'>{DROPDOWN_OPTIONS[this.state.selectedIndex].name}</b>

                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                {
                                                    DROPDOWN_OPTIONS.map((game, i) => {
                                                        return <Dropdown.Item key={i} onClick={() => this.setState({ selectedIndex: i })}>{game.name} </Dropdown.Item>
                                                    })
                                                }
                                            </Dropdown.Menu>
                                        </Dropdown>
                                        <DebounceInput minLength={3}
                                            debounceTimeout={500}
                                            type="search"
                                            maxLength={100}
                                            className="ml-2 form-control rounded text-white transperent-box light-border-around"
                                            placeholder="Search in table ...."
                                            onChange={(e) => { this.onSearch(e.target.value) }} />
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <TableComponent column={this.columns} data={this.state.organisations} paginationTotalRows={this.state.totalResult} onPagination={this.onPagination} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(BettingData)

