import { Component } from 'react'
import { Card, Modal } from 'react-bootstrap';
import { AiOutlineClose } from "react-icons/ai";
import { Link } from 'react-router-dom';
import { BREADCRUMBS } from '../../utils/breadcrumbs.utils';
import { getFeatureRequests, deleteFeatureRequests, routeTo } from '../../utils/common.utils';
import Confirmation from '../common/Confirmation';

export default class FeatureRequest extends Component {

  constructor(props) {
    super(props);
    this.state = {
      featureRequests: [],
      deleteId: null,
      openConfirmationModal : false
    }
    getFeatureRequests(this.setFeatureRequest);
  }
  componentDidMount(){}

  setFeatureRequest = (data) => { 
    //console.log('setFeatureRequest ::', data);
    this.setState({featureRequests: data});
  }

  setDeleteIndex = (id) => { 
    this.setState({ 
      openConfirmationModal: true,
      deleteId: id 
    });
  }

  resetDeleteIndex = () => { 
    this.setState({
      openConfirmationModal: false,
      deleteId: null
    })
  }

  deleteReason = () => { 
    deleteFeatureRequests(this.state.deleteId, this.setFeatureRequest);
    this.resetDeleteIndex()
  }

  render() {
    return (
      <div className="app-main__inner">
        {/* HEAD */}
        <div className="d-flex flex-column flex-lg-row">
            {/* MAIN-HEAD */}
            <div className="flex-grow-1">
                <h3 className='text-light-white'>{BREADCRUMBS.FEATURE_REQUEST.HEADING}</h3>
            </div>
            {/* BRAD-CRUMB */}
            <div className="">
                <ol className="breadcrumb color-white" style={{ background: 'none', padding: '0px' }}>
                    {BREADCRUMBS.FEATURE_REQUEST.PATH.map((route,i)=>{
                        return <li key={i} className="breadcrumb-item cursor-pointer" onClick={() => { routeTo('/','dashboard')  }}>{route}</li>
                    })}
                </ol>
            </div>
        </div>
        <div className="row">
          {
            this.state.featureRequests?.length ? 
            this.state.featureRequests.map((request,i) => { 
              return (
                <div className="col-12 col-md-6 col-lg-4" key={i}>
                  <Card style={{ background: '#6259CA'}}>
                    <Card.Body className='p-3'>
                      <div className="row">
                        <div className="col-10"><Card.Title className='feature-card-title'>{request.featureName}</Card.Title></div>
                        <div className="col-2 text-end"><AiOutlineClose className="cursor-pointer" style={{height:'20px', width:'20px', color:'white'}} onClick={() => this.setDeleteIndex(request._id)} /></div>
                      </div>
                      <Card.Text className='feature-card-text my-2'>
                        {request.description}
                      </Card.Text>
                      
                      {request.references !== '' ?
                        <Card.Text>
                          <span className='fs-12 fw-bold text-success me-2'>Ref:</span>
                          <Link target="_blank" to={`//${request.references}` } style={{color:'#DEFDE3'}}>{request.references}</Link>
                        </Card.Text>
                        :
                        null
                      }
                      
                      <Card.Text>
                        -<span className='fs-12 fw-bold text-warning'>{request.email}</span>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>
              )
            })
            :
            <h4 className='text-white-50'>No Feature Requests Found</h4>
          }
          </div>
          <Modal show={this.state.openConfirmationModal} centered dialogClassName=''>
          <Modal.Body>
            <Confirmation
              message={`Are you sure you want to remove this feature from list ?`}
              onConfirm={this.deleteReason} onClose={this.resetDeleteIndex}
            />
          </Modal.Body>
        </Modal>  
      </div>
    )
  }
}
