import { toast } from 'material-react-toastify'
import React, { Component } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { FORM_DATATYPES } from '../../utils/strings.utils'

export default class FormField extends Component {

    constructor(props) {
        super(props)
        this.state = {
            title : null,
            maxLength : null,
            selctedCases : [],
            fixedLength : false
        }
    }

    changeValue = (label, val) =>{
        this.setState({
          [label]: (val ? val : 0)
        });
    }

    selecteCase = (value, isChecked) =>{
        var selctedCases = this.state.selctedCases;
        if(isChecked){
            selctedCases.push(value)
        }else{
            selctedCases = selctedCases.filter(item => item !== value)
        }
        this.setState({
            selctedCases: selctedCases
        })
    }

    onSubmit = () => {
        if(this.state.title === undefined || this.state.title === null || this.state.title.trim() === ""){
            toast.warning("Please enter a valid label name !")
        }else if(this.state.maxLength === undefined || this.state.maxLength === null || parseInt(this.state.title.maxLength) === 0){
            toast.warning("Please enter a valid max length !")
        }else if(this.state.selctedCases.length === 0){
            toast.warning("Please select at least one datatype !")
        }else{
            var selectedForm = this.props.selectedForm;
            var data = {
                LABEL : this.state.title,
                CASE: this.state.selctedCases
            }
            data[this.state.fixedLength ? 'FIX_CHAR' : 'MAX_CHAR'] = parseInt(this.state.maxLength);
            selectedForm.DESCRIPTION.push(data)
            this.props.addNewField(selectedForm)
        }

    }
  

    render() {
        return (
            <div className='modal-content' style={{border:'none', borderRadius:'0'}}>
                <h5 className='text-center text-white fw-bold mb-0'>Add new form field</h5>
                <p className='text-center fs-12 text-white-50 my-0'>( {this.props.gameDetails.name})</p>
                {/* <div className='my-2 text-center text-white-50 fw-500'>
                    {this.props.message}
                </div> */}
                <div className='p-2'>
                    <div className='my-2'>
                        <label><span className='text-white font-bold'>Enter field title</span></label>
                        <div className="input-group">
                            <input type="text" 
                                className="form-control transperent-box light-border-around text-gray"
                                placeholder='Enter field title' 
                                aria-label="Recipient's username" aria-describedby="basic-addon2"
                                style={{borderRadius:'10px'}}
                                onChange={(e)=>this.changeValue("title",e.target.value)}
                            />
                        </div>
                        <div className='row m-2'>
                            <div className="col-12 col-md-6 col-lg-4 form-check">
                                <input className="form-check-input" type="checkbox"  
                                    onChange={(e)=>this.setState({fixedLength : e.target.checked})}
                                    checked={this.state.fixedLength}  id="fixedLength" />
                                <label className="form-check-label fs-12 check-text" htmlFor="flexCheckChecked">
                                    Fixed length
                                </label>
                            </div>
                            <div className="col-12 col-md-6 col-lg-4 form-check">
                                <input className="form-check-input" type="checkbox" 
                                onChange={(e)=>this.setState({fixedLength : !e.target.checked })}
                                    checked={!this.state.fixedLength} id="maxLength" />
                                <label className="form-check-label fs-12 check-text" htmlFor="flexCheckChecked">
                                    Max length
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className='my-2'>
                        <label><span className='text-white font-bold'>Max Character</span></label>
                        <div className="input-group">
                            <input type="number" onWheel={(e) => e.target.blur()}
                                className="noscroll form-control transperent-box light-border-around text-gray"
                                
                                placeholder='Enter max length' 
                                aria-label="Recipient's username" aria-describedby="basic-addon2"
                                style={{borderRadius:'10px'}}
                                onChange={(e)=>this.changeValue("maxLength",e.target.value)}
                            />
                        </div>
                        <div className='row m-2'>
                            {FORM_DATATYPES.map((type,i)=>{
                            return (
                                <div className="col-12 col-md-6 col-lg-4 form-check" key={i}>
                                    <input className="form-check-input" type="checkbox" value="" id={type}   onChange={(e)=>this.selecteCase(type,e.target.checked)} />
                                    <label className="form-check-label fs-12 check-text" htmlFor="flexCheckChecked">
                                    {type} Character
                                    </label>
                                </div>
                                )
                            })}
                        </div>
                    </div>
                    
                    
                </div>
                
                <div className='d-flex justify-content-center my-3'>
                    <Button onClick={() => { this.onSubmit() }} className="btn btn-primary fw-bold me-1">
                        Save
                    </Button>
                    <Button onClick={() => { this.props.onClose() }} className="btn-primary fw-bold me-1">
                        Close
                    </Button>
                </div>
            </div>
        )
    }
}
