
import React, { Component } from 'react';
import { HEADING, POINTS_RANK, WEBSITE } from '../../utils/strings.utils';
import { getGamesDetails, getNotificationDetails, routeTo, updateNotificationDetails } from '../../utils/common.utils';
import { BREADCRUMBS } from '../../utils/breadcrumbs.utils';

import "../../assets/css/switch.css";
import "../../assets/css/game.css";
import { toast } from 'material-react-toastify';
import Dropdown from 'react-bootstrap/Dropdown';

export default class TeamNotification extends Component {

    constructor(props) {
        super(props);
        this.state = { 
          notificationDetails : null,
          selectedChoice : 0,
          editIndex : -1,
          updateValue : null
        };
    }
  
    componentDidMount(){
      getNotificationDetails(`type=TEAM`,this.setNotificationDetails);
    }

    setNotificationDetails = (notificationDetails) =>{
      if(notificationDetails){
        notificationDetails = notificationDetails.filter((mod, i) => mod.type === 'TEAM')
        this.setState({
          notificationDetails: notificationDetails,
          editIndex : -1,
          updateValue : null
        });
      }
    }

    onSelectOption = (index) =>{
      this.setState({
        selectedChoice : index
      });
    }


    enableEditOption = (index) =>{
      this.setState({
        editIndex: index
      });
    }

    changeValue = (val) =>{
      this.setState({
        updateValue: (val ? val : 0)
      });
    }

    updateNotificationDetails = (key) => {

      let data = {};
      data[key] = this.state.updateValue
      updateNotificationDetails(this.state.notificationDetails[this.state.selectedChoice]._id, data, this.setNotificationDetails);
            
    }

    render() {
        const enableEditOption = this.enableEditOption;
        const changeValue = this.changeValue;
        const updateNotificationDetails = this.updateNotificationDetails;
        const {editIndex, notificationDetails, selectedChoice} = this.state;
        const readOnlyFields = true;

        if(this.state.notificationDetails){
          
          return (
            <div className="app-main__inner">
                {/* HEAD */}
                <div className="d-flex flex-column flex-lg-row">
                    {/* MAIN-HEAD */}
                    <div className="flex-grow-1">
                        <h3 className='text-light-white'>{BREADCRUMBS.TEAMS_NOTIFICATIONS.HEADING}</h3>
                    </div>
                    {/* BRAD-CRUMB */}
                    <div className="">
                        <ol className="breadcrumb color-white" style={{ background: 'none', padding: '0px' }}>
                            {BREADCRUMBS.TEAMS_NOTIFICATIONS.PATH.map((route,i)=>{
                                return <li key={i} className="breadcrumb-item cursor-pointer" onClick={() => { routeTo('/','dashboard')  }}>{route}</li>
                            })}
                        </ol>
                    </div>
                </div>
                {/* BODY */}
                <div className="row">
                    <div className="col-md-12">
                        <div className="main-card mb-3 card transperent-card text-white p-4">
                            {/* SUB-HEAD */}
                            <div className="card-header no-background p-0 mb-2 ">  {WEBSITE.NOTIFICATIONS.TEAM.NAME}
                                {/* FILTER */}
                                <div className="btn-actions-pane-right">
                                  
                                </div>
                            </div>
                            {/* MAIN_BODY */}
                            <div className='row'>
                              {this.state.notificationDetails[this.state.selectedChoice]?.questions && Object.keys(this.state.notificationDetails[this.state.selectedChoice].questions).map(function(key, index) {
                                  const selectedOption = notificationDetails[selectedChoice].type;
                                  if(readOnlyFields){
                                    return (
                                      <div className='col-12  my-2' key={key}>
                                        <label><span className=' font-bold'>{WEBSITE.NOTIFICATIONS[selectedOption][key]}</span></label>
                                        <div className="input-group" >
                                          <input value={notificationDetails[selectedChoice].questions[key]} className="form-control transperent-box light-border-around text-gray rounded-input" type="text" readOnly id={key}  />
                                        </div>
                                      </div>
                                    )
                                  }else{
                                    if(editIndex === index){
                                      return (
                                        <div className='col-12  my-2' key={key}>
                                          <label><span className=' font-bold'>{WEBSITE.NOTIFICATIONS[selectedOption][key]}</span></label>
                                        
                                          <div className="input-group" >
                                            <input onChange={(e)=>changeValue(e.target.value)} defaultValue={notificationDetails[selectedChoice].questions[key]} placeholder={`Enter ${WEBSITE.NOTIFICATIONS[selectedOption][key]} message`} className="form-control transperent-box light-border-around text-gray" style={{borderTopLeftRadius:'10px', borderBottomLeftRadius: '10px'}} type="text" id={key} />
                                            <div className="input-group-append">
                                              <button className="btn edt-btn light-border-around" onClick={()=>updateNotificationDetails(key)} type="button" style={{borderTopRightRadius:'10px', borderBottomRightRadius: '10px'}}>
                                                Save
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      )
                                    }else{
                                      return (
                                        <div className='col-12  my-2' key={key}>
                                          <label><span className=' font-bold'>{WEBSITE.NOTIFICATIONS[selectedOption][key]}</span></label>
                                          <div className="input-group" >
                                            <input value={notificationDetails[selectedChoice].questions[key]} placeholder={`Enter ${WEBSITE.NOTIFICATIONS[selectedOption][key]} message`} className="form-control transperent-box light-border-around text-gray" style={{borderTopLeftRadius:'10px', borderBottomLeftRadius: '10px'}} type="text" readOnly id={key}  />
                                            <div className="input-group-append">
                                              <button className="btn edt-btn light-border-around" onClick={()=>enableEditOption(index)}  type="button" style={{borderTopRightRadius:'10px', borderBottomRightRadius: '10px'}}>
                                                Edit
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      )
                                    }
                                  }
                                })
                              }
                            
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          );
        }else{
          return null;
        }
       
    }
}