export const isValidLink = (mediaType, link) => {
    var pattern;
    switch (mediaType) {
        case 'CONTACT_US_EMAIL':
            console.log("CONTACT_US_EMAIL-----------",link)
            pattern =/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            return pattern.test(link);

        case 'ESPOTZ_TWITTER_LINK':
            console.log("ESPOTZ_TWITTER_LINK-----------",link)
            pattern =/http(?:s)?:\/\/(?:www\.)?twitter\.com\/([a-zA-Z0-9_]+)/;
            return pattern.test(link);
        
        case 'ESPOTZ_TELEGRAM_LINK':
            console.log("ESPOTZ_TELEGRAM_LINK-----------",link)
            pattern =/(https?:\/\/)?(www[.])?(telegram|t)\.me\/([a-zA-Z0-9_-]*)\/?$/;
            return pattern.test(link);
        
        case 'ESPOTZ_FACEBOOK_LINK':
            console.log("ESPOTZ_TWITTER_LINK-----------",link)
            pattern =/(?:https?:\/\/)?(?:www\.)?facebook\.com\/.(?:(?:\w)*#!\/)?(?:pages\/)?(?:[\w\-]*\/)*([\w\-\.]*)/;
            return pattern.test(link);

        case 'ESPOTZ_WHATSAPP_LINK':
            console.log("ESPOTZ_WHATSAPP_LINK-----------",link)
            pattern =/^(https?:\/\/)?chat(?:.whatsapp\.com\/)([a-zA-Z0-9_-]{22})$/;
            return pattern.test(link);

            
        default:
            break;
    }
   
 }

 export const hasWhiteSpace = (string) => {
    return /\s/g.test(string);
  }