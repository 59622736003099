import { Component } from 'react'
import { Outlet } from 'react-router-dom'

export default class GameIndex extends Component {
    render() {
        return (
            <div className='es-page-height'><Outlet></Outlet></div>
        )
    }
}
