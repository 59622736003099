import { Component } from 'react';
import AuthService from '../../services/auth.service';
import { errorHandler, updateLoader, withRouter, profile, uploadSingleImg } from '../../utils/common.utils';
import { toast } from 'material-react-toastify';
import camera from '../../assets/images/camera.png';
import { PROFILE_ENUM } from '../../utils/strings.utils';
import { getAvatarForLetter } from '../../utils/images.utils';

class Profile extends Component {
    constructor(props) {
        super(props)
        this.state = {
            profile: profile(),
            editLabel : ''
        };
        this.authApi = new AuthService();
    }

    componentDidMount(){
        this.setState({profile : profile()});
    }

    setUpdatedImage = (response) => {
        console.log('response ::', response);
        const {profile} = this.state;
        profile['profilePicture'] = response;
        this.setState({ profile : profile})
        updateLoader(false);
    }

    changeValue = (val) =>{
        const {editLabel, profile} = this.state;
        //console.log('old denDetails', denDetails)
        profile[editLabel] = val;
        //console.log('updated denDetails', denDetails);
        this.setState({ profile: profile })
    }

    updateData = () => {
        console.log('Update clicked')
        //const id = this.state.denDetails[0]?._id;
        //console.log('------ id', this.state.denDetails[0]?._id);
        //const data = this.state.denDetails[0]?.questions;
        //updateDenDetails(id, data, this.setDenDetails);
    }
    
    updateEditLabel = (label) => {
        console.log('Label ::', label);
        this.setState({editLabel : label})
    }

    addImg = (event, key) => {
        if (event.target.files && event.target.files[0]) {
            let img = event.target.files[0];
            if (!img.name.match(/\.(jpg|jpeg|png)$/)) {
                toast.warning('Please select a valid image type.');
                return false;
            }
            const formData = new FormData();
            formData.append("file", event.target.files[0]);
            console.log("---------- formData :: ",formData);
            updateLoader(true);
            uploadSingleImg(formData, this.setUpdatedImage);
        }
    }

    triggerInputFile = () => this.fileInput.click()

    render() {
        //console.log('Profile ::', this.state.profile);
        var defaultImage = getAvatarForLetter(profile()?.name?.charAt(0).toLowerCase());
        return (
            <div className="app-main__inner">
                <div className="">
                    <div className="container">
                        <div className='d-flex flex-column align-items-center'>
                            <div className='d-flex' style={{height: '120px', width:'120px'}}>
                                <input type="file" id="upload_file" accept=".png, .jpg, .jpeg" hidden onChange={(e) => this.addImg(e, 'profileLogo')} ref={fileInput => this.fileInput = fileInput} name="" className="" />
                                <img src={this.state.profile?.profilePicture ? this.state.profile?.profilePicture.url : defaultImage} alt='' className='rounded-circle' style={{height: '120px', width:'120px'}} />
                                <img src={camera} alt='' className='mt-auto' style={{marginLeft:'-30px', height: '25px', width: '25px'}} onClick={this.triggerInputFile} />
                            </div>
                        </div>
                        
                        <div className="row justify-content-center">
                        {PROFILE_ENUM.map((question,i) => {
                            //console.log('value ::', this.state.denDetails[selectedChoice].questions[question.key]);
                            return (
                            <div className='col-12 my-2' key={i}>
                                <label><span className='font-bold'>{question.label}</span></label>
                            
                                <div className="input-group">
                                <input type="text"
                                    onChange={(e)=>this.changeValue(e.target.value)}
                                    value={this.state.profile[question.key]}
                                    className="form-control transperent-box light-border-around text-gray" 
                                    aria-label="Recipient's username" aria-describedby="basic-addon2"
                                    style={{borderTopLeftRadius:'10px', borderBottomLeftRadius: '10px'}}
                                    disabled={this.state.editLabel === question.key ? false : true}
                                />
                                <div className="input-group-append">
                                    <button className="btn edt-btn light-border-around" type="button" style={{borderTopRightRadius:'10px', borderBottomRightRadius: '10px'}}
                                    onClick={(e) => {this.state.editLabel === question.key ? this.updateData(e.target.value) : this.updateEditLabel(question.key)}}
                                    >
                                    {this.state.editLabel === question.key ? 'Save' : 'Edit'} 
                                    </button>
                                </div>
                                </div>
                            </div>
                            )
                        })}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default withRouter(Profile);