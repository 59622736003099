import React, { Component } from 'react'
import { DebounceInput } from 'react-debounce-input'
import UserService from '../../services/user.service'
import { errorHandler, updateLoader } from '../../utils/common.utils'
import { dateFormat } from '../../utils/dateTime.utils'
import { CLAN_VIEWS } from '../../utils/strings.utils'
import { TableComponent } from '../common/DataTable'

export default class ClanMembers extends Component {
    
    constructor(props) {
        super(props)
        this.state = {
            viewClanDetailsId: props.viewClanDetailsId,
            viewClanDetails: props.viewClanDetails,
            params: {
                pageNo: 0,
                limit: 10
            },
            //tournamentDetails: []
        }
        this.authApi = new UserService();
        this.columns = [
            {
                name: "USER ID",
                selector: (row) => [row.id],
                sortable: false,
            },
            {
                name: "NAME",
                selector: (row) => [row.name],
                sortable: false,
            },
            {
                name: "ROLE",
                selector: (row) => [row.role],
                sortable: false,
            },
            {
                name: "A.K.A",
                selector: (row) => [row.username],
                sortable: false,
            },
            {
                name: "EMAIL",
                selector: (row) => [row.email],
                sortable: false,
            },
        ]
    }

    componentWillReceiveProps(newProps){
        this.setState({
            viewClanDetailsId: newProps.viewClanDetailsId,
            viewClanDetails: newProps.viewClanDetails
        });
    }

    componentDidMount() {}

    onPagination = (i, key) => {
        let paramsTemp = this.state.params
        paramsTemp[key] = i
        this.setState({ params: paramsTemp })
        console.log(`${key}`, paramsTemp)
        //this.getTournamenStats(this.state.viewUserDetailsId,paramsTemp)
    }

    render() {
        const clanMembers = [];
        this.state.viewClanDetails?.members?.owner?.map(member => clanMembers.push({...member, role: 'Owner'}));
        if(this.state.viewClanDetails?.members?.admin?.length){
            this.state.viewClanDetails?.members?.admin?.map(member => clanMembers.push({...member, role: 'Admin'}));
        }
        //console.log('clan members:: ', clanMembers);
        return (
            <div className="main-card mb-3 card transperent-card text-white">
                <div className="card-header no-background px-3 py-0 my-0">
                    <p className="my-0 sub-heading ">Clan Members Details</p>
                    <div className="btn-actions-pane-right">
                        <button className='btn edt-btn light-border-around' onClick={()=>this.props.updateState({viewClanDetails: null, viewUserDetailsId: null, currentView: CLAN_VIEWS.CLANS})}>Back</button>
                    </div>
                </div>
                <hr className='seperator my-0' />
                <div className=' p-4'>
                    <p className='fw-600 my-0' style={{fontSize:'20px'}}>Clan Name: {this.state.viewClanDetails?.name}</p>
                    <p className='fw-500 my-0'>Clan ID: {this.state.viewClanDetailsId}</p>
                    <div className="card-header no-background p-0 mb-2">
                        <div className="btn-actions-pane-right">
                            <div className="input-group icons search-box">
                                <DebounceInput minLength={3}
                                    debounceTimeout={500}
                                    type="search"
                                    className="ml-2 form-control rounded text-white transperent-box light-border-around"
                                    placeholder="Search in table ...."
                                    maxLength={100}
                                    //onChange={(e) => { this.onSearch(e.target.value) }} 
                                />
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <TableComponent column={this.columns} data={clanMembers} paginationTotalRows={clanMembers.length} onPagination={this.onPagination} />
                    </div>
                </div>
            </div>
        )
    }
}
