import React, { Component } from 'react'
import { DebounceInput } from 'react-debounce-input'
import UserService from '../../services/user.service'
import { errorHandler, updateLoader } from '../../utils/common.utils'
import { dateFormat } from '../../utils/dateTime.utils'
import { CLAN_VIEWS } from '../../utils/strings.utils'
import { TableComponent } from '../common/DataTable'

export default class ClanTournamentStats extends Component {
    
    constructor(props) {
        super(props)
        this.state = {
            viewClanDetailsId: props.viewClanDetailsId,
            viewClanDetails: props.viewClanDetails,
            params: {
                pageNo: 0,
                limit: 10
            },
            //tournamentDetails: []
        }
        this.authApi = new UserService();
        this.columns = [
            {
                name: "TOURNAMENT",
                selector: (row) => [row.name],
                sortable: false,
            },
            {
                name: "START DATE",
                selector: (row) => [dateFormat(row.start,true)],
                sortable: false,
            },
            {
                name: "END DATE",
                selector: (row) => [dateFormat(row.start,true)],
                sortable: false,
            },
            {
                name: "GAME",
                selector: (row) => [row.gameName],
                sortable: false,
            },
            {
                name: "ENTRY FEE",
                selector: (row) => [row.entryFees.entryFeeAmount],
                sortable: false,
            },
            // {
            //     name: "TOURNAMENT PRICE POOL",
            //     selector: (row) => [row.prizeWon],
            //     sortable: false,
            // },
            {
                name: "GAME WON/PLAYED",
                selector: (row) => [<span><b style={{color: '#13f745'}}>{row.totalWins}</b> / {row.totalGames}</span>],
                sortable: false,
            },
            {
                name: "TOURNAMENT PRICE POOL",
                selector: (row) => [row.entryFees?.prizePoolAmount ? row.entryFees?.prizePoolAmount : 'NA'],
                sortable: false,
            },
            {
                name: "RATING",
                selector: (row) => [row.rating],
                sortable: false,
            },
            
        ]
    }

    componentWillReceiveProps(newProps){
        this.setState({
            viewClanDetailsId: newProps.viewClanDetailsId,
            viewClanDetails: newProps.viewClanDetails
        });

        // if(newProps.viewUserDetailsId !== this.state.viewUserDetailsId){
        //     this.getTournamenStats(newProps.viewUserDetailsId,this.state.params);
        // }

    }

    componentDidMount() {
        // if(this.props.viewUserDetails){
        //     this.getTournamenStats(this.props.viewUserDetailsId,this.state.params);
        // }
    }

    getTournamenStats = (id, params) => {
        updateLoader(true)
        this.authApi.getTournamenStats(id,params).then(
            (res) => {
               const tournamentDetails = res.data.result?.filter((obj)=> obj !== null);
               console.log("@@@ getTournamenStats res : ",tournamentDetails);
               this.setState({tournamentDetails: tournamentDetails})
               updateLoader(false)
            }
        ).catch(
            (err) => {
                console.error(err)
                updateLoader(false)
                errorHandler(err)
            }
        )
    };

    onPagination = (i, key) => {
        let paramsTemp = this.state.params
        paramsTemp[key] = i
        this.setState({ params: paramsTemp })
        console.log(`${key}`, paramsTemp)
        //this.getTournamenStats(this.state.viewUserDetailsId,paramsTemp)
    }

    render() {
        return (
            <div className="main-card mb-3 card transperent-card text-white">
                <div className="card-header no-background px-3 py-0 my-0">
                    <p className="my-0 sub-heading ">Clan Tournament Details</p>
                    <div className="btn-actions-pane-right">
                        <button className='btn edt-btn light-border-around' onClick={()=>this.props.updateState({viewClanDetails: null, viewUserDetailsId: null, currentView: CLAN_VIEWS.CLANS})}>Back</button>
                    </div>
                </div>
                <hr className='seperator my-0' />
                <div className=' p-4'>
                    <p className='fw-600 my-0' style={{fontSize:'20px'}}>Clan Name: {this.state.viewClanDetails?.name}</p>
                    <p className='fw-500 my-0'>Clan ID: {this.state.viewClanDetailsId}</p>
                    <div className="card-header no-background p-0 mb-2">
                        <div className="btn-actions-pane-right">
                            <div className="input-group icons search-box">
                                <DebounceInput minLength={3}
                                    debounceTimeout={500}
                                    type="search"
                                    className="ml-2 form-control rounded text-white transperent-box light-border-around"
                                    placeholder="Search in table ...."
                                    maxLength={100}
                                    //onChange={(e) => { this.onSearch(e.target.value) }} 
                                />
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <TableComponent column={this.columns} data={this.state.viewClanDetails?.stats} paginationTotalRows={this.state.viewClanDetails?.stats?.length} onPagination={this.onPagination} />
                    </div>
                </div>
            </div>
        )
    }
}
