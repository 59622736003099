import * as axios from "axios";
import { token } from "../utils/common.utils";

export default class UserService {
    constructor() {
        this.client = null;
        this.api_token = null;
        this.api_url = process.env.REACT_APP_API_URL;
    }
    queryParams(params) {
        return Object.keys(params)
            .map(key => `${key}=${params[key]}`)
            .join('&');
    }
    init = () => {
        this.api_token = token();
        let headers = {
            Accept: "application/json",
        };

        if (this.api_token) {
            headers.Authorization = this.api_token;
        }

        this.client = axios.create({
            baseURL: this.api_url,
            timeout: 31000,
            headers: headers,
        });

        return this.client;
    };
    getAllAdmins = (params) => {
        const searchString = this.queryParams(params)
        return this.init().get(`/admin/api/v1/adminUser/getAdmins?${searchString}`);
    };
    getAllUsers = (params) => {
        //console.log('getAllUsers ::',params);
        const searchString = this.queryParams(params)
        //console.log('searchString ::',searchString);
        return this.init().get(`/admin/api/v1/user/getUsers?${searchString}`);
    };
    getAllUsersStats = (params) => {
        //console.log('getAllUsers ::',params);
        const searchString = this.queryParams(params)
        //console.log('searchString ::',searchString);
        return this.init().get(`/admin/api/v1/user/getUsersStats?${searchString}`);
    };
    getTournamenStats = (id,params) => {
        //console.log('getAllUsers ::',params);
        const searchString = this.queryParams(params)
        //console.log('searchString ::',searchString);
        return this.init().get(`/admin/api/v1/user/getTournamenStats/${id}?${searchString}`);
    };
    getAllClans = (params) => {
        const searchString = this.queryParams(params)
        return this.init().get(`/admin/api/v1/clan/getClans?${searchString}`);
    };

    getClansStats = (params) => {
        const searchString = this.queryParams(params)
        return this.init().get(`/admin/api/v1/clan/getClansStats?${searchString}`);
    };
    
    getAllTeams = (params) => {
        const searchString = this.queryParams(params)
        return this.init().get(`/admin/api/v1/team/getTeams?${searchString}`);
    };
    getAllOrgs = (params) => {
        const searchString = this.queryParams(params)
        return this.init().get(`/admin/api/v1/organization/getOrganizations?${searchString}`);
    };
}