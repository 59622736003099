import { Component } from 'react'
import { TableComponent } from '../common/DataTable';
import { BREADCRUMBS } from '../../utils/breadcrumbs.utils';
import { errorHandler, routeTo, updateLoader, withRouter } from '../../utils/common.utils';
import Button from 'react-bootstrap/Button';
import { Dropdown } from 'react-bootstrap';
import { DebounceInput } from 'react-debounce-input';
import BettingService from '../../services/betting.service';

const DROPDOWN_OPTIONS = [
  { name: 'User ID', term: 'userId' },
  { name: 'Name', term: 'name' },
  { name: 'AKA', term: 'username' },
  { name: 'Email', term: 'email' },
  { name: 'Discord', term: 'discordUsername' },
]

class BettingDataWinners extends Component {

  constructor(props) {
    super(props)
    this.state = {
      tournamentBetWinners: [],
      totalResult: 0,
      params: {
        pageNo: 1,
        limit: 10,
        _id: props.router.params.id
      },
      selectedIndex: 0,
      searchResult: []
    }
    this.bettingApi = new BettingService();
    this.columns = [
      {
        name: "USER ID",
        selector: (row) => [row.userId],
        sortable: true,
      },
      {
        name: "NAME",
        selector: (row) => [row.name],
        sortable: true,
      },
      {
        name: "AKA",
        selector: (row) => [row.aka],
        sortable: true,
      },
      {
        name: "EMAIL",
        selector: (row) => [row.email],
        sortable: true,
      },
      {
        name: "PHONE",
        selector: (row) => [`${row.countryCode.code}-${row.countryCode.number}`],
        sortable: true,
      },
      {
        name: "DISCORD ID",
        selector: (row) => [row.discord],
        sortable: true,
      },
      {
        name: "BETTING STATEMENT",
        selector: (row) => [row.bettingStatement],
        sortable: true,
      },
      {
        name: "BETS",
        selector: (row) => [row.bets],
        sortable: true,
      },
      {
        name: "BETTING AMOUNT",
        selector: (row) => [row.bettingAmout],
        sortable: true,
      },
      {
        name: "WINNING(EC)",
        selector: (row) => [row.Winning],
        sortable: true,
      }
    ]
  }

  componentDidMount() {
    this.getTournamentBetWinners(this.state.params)
  }

  onPagination = (i, key) => {
    let paramsTemp = this.state.params
    paramsTemp[key] = key === 'pageNo' ? (i + 1) : i;
    console.log(`${key}`, paramsTemp)
    this.getTournamentBetWinners(paramsTemp);
  }
  onSearch = (val) => {
    console.log(val)
    let searchTerm = DROPDOWN_OPTIONS[this.state.selectedIndex].term;
    const {params} = this.state;
    params[searchTerm] = val;
    this.getTournamentBetWinners(params);
  }

  getTournamentBetWinners = (val) => {
    let params = val;
    updateLoader(true)
    this.bettingApi.getBetWinners(params).then(
      (res) => {
        console.log("res - ", res.data.result)
        this.setState({tournamentBetWinners: res.data.result, totalResult: res.data.totalResults})
        updateLoader(false)
      }
    ).catch(
      (err) => {
        console.error(err);
        updateLoader(false);
        errorHandler(err);
      }
    )
  };

  render() {
    return (
      <div className="app-main__inner">
        <div className="d-flex flex-column flex-lg-row">
          <div className="flex-grow-1">
            <h3 className='text-white'>{BREADCRUMBS.BETTING_DATA_WINNERS.HEADING}</h3>


          </div>
          <div className="">
            <ol className="breadcrumb color-white" style={{ background: 'none', padding: '0px' }}>
              {BREADCRUMBS.BETTING_DATA_WINNERS.PATH.map((route, i) => {
                return <li key={i} className="breadcrumb-item cursor-pointer" onClick={() => { routeTo('/', 'dashboard') }}>{route}</li>
              })}
            </ol>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="main-card mb-3 card transperent-card text-white p-4">
              <div className="card-header no-background p-0 mb-2">{this.state.tournamentName}
                <div className="btn-actions-pane-right">
                  <div className="input-group icons search-box">
                    {/* <input type="search" className="form-control rounded text-white transperent-box light-border-around" placeholder="Search in table ...." /> */}
                    <Dropdown>
                        <Dropdown.Toggle variant="success" className='h-45 transperent-box light-border-around text-white' id="dropdown-basic">
                            <b className='px-5'>{DROPDOWN_OPTIONS[this.state.selectedIndex].name}</b>

                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            {
                                DROPDOWN_OPTIONS.map((game, i) => {
                                    return <Dropdown.Item key={i} onClick={() => this.setState({ selectedIndex: i })}>{game.name} </Dropdown.Item>
                                })
                            }
                        </Dropdown.Menu>
                    </Dropdown>
                    <DebounceInput minLength={3}
                        debounceTimeout={500}
                        type="search"
                        className="ml-2 form-control rounded text-white transperent-box light-border-around"
                        placeholder="Search in table ...."
                        maxLength={100}
                        onChange={(e) => { this.onSearch(e.target.value) }} />
                    <Button className='es-btn-padding ml-2' onClick={() => { this.props.router.navigate(`/betting/betting-data`) }} variant="primary">Back</Button>
                  </div>
                  {/* <div className='mb-4 float-right'> */}
                  {/* </div> */}
                </div>
              </div>
              <div className='row'>
                <TableComponent column={this.columns} data={this.state.tournamentBetWinners} paginationTotalRows={this.state.totalResult} onPagination={this.onPagination} />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(BettingDataWinners);
