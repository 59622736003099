import * as axios from "axios";
import { token } from "../utils/common.utils";

export default class CommissionService {
    constructor() {
        this.client = null;
        this.api_token = null;
        this.api_url = process.env.REACT_APP_API_URL;
    }
    queryParams(params) {
        return Object.keys(params)
            .map(key => `${key}=${params[key]}`)
            .join('&');
    }
    init = () => {
        this.api_token = token();
        let headers = {
            Accept: "application/json",
        };

        if (this.api_token) {
            headers.Authorization = this.api_token;
        }

        this.client = axios.create({
            baseURL: this.api_url,
            timeout: 31000,
            headers: headers,
        });

        return this.client;
    };
    updateCommission = (id, data) => {
        return this.init().put(`/admin/api/v1/commission/updateCommission/${id}`, data);
    };
    getCommission = () => {
        return this.init().get(`/admin/api/v1/commission/getCommissions`);
    };
    getCurrency = () => {
        return this.init().get(`/admin/api/v1/ec/getEc`);
    };
    updateECValue = (id, data) => {
        return this.init().put(`/admin/api/v1/ec/updateEc/${id}`, data);
    };
    getReasons = () => {
        return this.init().get(`/admin/api/v1/tournamentCancellation/getTournamentCancellation`);
    };
    createReason = (data) => {
        return this.init().post(`/admin/api/v1/tournamentCancellation/createTournamentCancellation`, data);
    };
    updateReason = (id, data) => {
        return this.init().put(`/admin/api/v1/tournamentCancellation/updateTournamentCancellation/${id}`, data);
    };
    deleteReason = (id) => {
        return this.init().delete(`/admin/api/v1/tournamentCancellation/deleteTournamentCancellation/${id}`);
    };
}