import { Component } from 'react'
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import AuthService from '../../services/auth.service';
import { errorHandler, updateLoader, withRouter } from '../../utils/common.utils';
import { toast } from 'material-react-toastify';
import logo from '../../assets/images/Loader.png';
import { FaLock, FaUserCircle } from "react-icons/fa";

class Login extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
        this.authApi = new AuthService();
    }
    onSubmit = (values) => {
        console.log('values - ', values)
        updateLoader(true)
        this.authApi.signIn(values)
            .then((userCredential) => {
                const user = userCredential.data.result;
                localStorage.setItem('user', JSON.stringify(user))
                setTimeout(() => {
                    this.props.router.navigate('/')
                }, 500)
                updateLoader(false)
                toast.success('Login successfully')
            })
            .catch((error) => {
                console.log(error)
                updateLoader(false)
                errorHandler(error)
            });
    }
    render() {
        return (
            <div>
                <div className="login-form-bg">
                    <div className="container">
                        <div className="row justify-content-center" style={{ height: '100vh' }}>
                            <div className="col-xl-5">
                                <div className="form-input-content">
                                    <div className="card login-form mb-0 transperent-card">
                                        <div className='d-flex justify-content-center'>
                                            <div className='d-flex justify-content-center align-items-center logo-container'  style={{marginTop: '-65px'}}>
                                                <img src={logo} alt='logo' className='' style={{ height: '100px', width: '100px' }}/>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            {/* <a className="text-center" href="index.html">
                                                <h4 className='text-heading-white'>ESPOTZ</h4>
                                            </a> */}
                                            <Formik
                                                enableReinitialize
                                                initialValues={{
                                                    email: '',
                                                    password: '',
                                                }}
                                                validationSchema={Yup.object({
                                                    email: Yup.string().required("Email is required").email('Invalid email'),
                                                    password: Yup.string().required("Password is required"),
                                                })}
                                                onSubmit={(values, { setSubmitting }) => {
                                                    setTimeout(() => {
                                                        this.onSubmit(values)
                                                    }, 400);
                                                }}
                                            >
                                                <Form className="mt-5 mb-5 login-input">
                                                    <div className="form-group d-flex align-items-center">
                                                        <FaUserCircle style={{height: '30px', width:'30px', color: 'white'}} />
                                                        <Field name="email" type="email" className="ms-2 form-control" placeholder="Email" />
                                                    </div>
                                                    <ErrorMessage component="span" className="error-msg" name="email" />
                                                    
                                                    <div className="form-group d-flex align-items-center">
                                                        <FaLock style={{height: '30px', width:'30px', color: 'white'}} />
                                                        <Field name="password" type="password" className="ms-2 form-control" placeholder="Password" />
                                                    </div>
                                                    <ErrorMessage component="span" className="error-msg" name="password" />
                                                    <button className="btn login-form__btn submit w-100" type='submit'>Sign In</button>
                                                </Form>
                                            </Formik>
                                            {/* <p className="mt-5 login-form__footer">Dont have account? <a href="page-register.html" className="text-primary">Sign Up</a> now</p> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default withRouter(Login)