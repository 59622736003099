import { Component } from 'react'
import CommissionService from '../../services/commission.service';
import { BREADCRUMBS } from '../../utils/breadcrumbs.utils';
import { errorHandler, routeTo, updateLoader } from '../../utils/common.utils';
import { COMMISSION_ENUM } from '../../utils/strings.utils';
import Button from 'react-bootstrap/Button';
import { toast } from 'material-react-toastify';

const btnTabs = [
  { title: 'Entry fees & price pool', key: 'ENTRY_FEE_PRICE_POOL' },
  { title: 'Wallet', key: 'WALLET' },
  { title: 'Den', key: 'DEN' },
  { title: 'Betting', key: 'BETTING' },
  { title: 'Tournament cancellation', key: 'TOURNAMENT_CANCELLATION' }
]
export default class CommissionIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedIndex: 0,
      editLabel: '',
      commissionObj: {},
      selectedBtn: 'ENTRY_FEE_PRICE_POOL'
    }
    this.commissionApis = new CommissionService();

  }

  componentDidMount() {
    this.getCriteria()
  }
  getCriteria = () => {
    updateLoader(true)
    this.commissionApis.getCommission().then(
      (res) => {
        console.log(res.data.result)
        let commissionObj = {};
        for (let data of res.data.result) {
          commissionObj[data.type] = data;
        }
        this.setState({ commissionObj: commissionObj })
        updateLoader(false)
      }
    ).catch(
      (err) => {
        console.error(err)
        updateLoader(false)
        errorHandler(err)
      }
    )
  }
  changeValue = (val, key, type) => {
    //console.log(`val-${val} key-${key} type-${type}`);
    if (val < 101) {
      let commissionObj = this.state.commissionObj;
      commissionObj[type].questions[key] = parseInt(val);
      this.setState({ commissionObj: commissionObj })
    }

  }

  onSubmit = () => {
    let data = this.state.commissionObj[this.state.selectedBtn].questions;
    let id = this.state.commissionObj[this.state.selectedBtn]._id;
    updateLoader(true)
    this.commissionApis.updateCommission(id, data).then(
      (res) => {
        console.log(res)
        this.setState({ editLabel: '' })
        updateLoader(false)
        toast.success('Commission updated successfully')
      }
    ).catch(
      (err) => {
        console.error(err)
        updateLoader(false)
        errorHandler(err)
      }
    )
  }

  render() {
    return (
      // <div className='es-page-height'><Outlet></Outlet></div>
      <div className="app-main__inner">
        <div className="d-flex flex-column flex-lg-row">
          <div className="flex-grow-1">
            <h3 className='text-white'>{BREADCRUMBS.COMMISSION.HEADING}</h3>


          </div>
          <div className="">
            <ol className="breadcrumb color-white" style={{ background: 'none', padding: '0px' }}>
              {BREADCRUMBS.COMMISSION.PATH.map((route, i) => {
                return <li key={i} className="breadcrumb-item cursor-pointer" onClick={() => { routeTo('/', 'dashboard') }}>{route}</li>
              })}
            </ol>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="main-card mb-3 card transperent-card text-white p-4">
              <div className="card-header no-background p-0 mb-2 ">Set up commission percentages
                <div className="btn-actions-pane-right">


                </div>
              </div>
              <div className="card-header no-background p-0 mb-2 ">
                {
                  btnTabs.map((el, i) => {
                    return (
                      <Button key={i} onClick={() => { this.setState({ selectedBtn: el.key }) }} className='mr-2' variant={(el.key === this.state.selectedBtn) ? 'primary' : 'outline-primary'}>{el.title}</Button>
                    )
                  })
                }
              </div>
              <div className='row'>
                {
                  COMMISSION_ENUM[this.state.selectedBtn].map((el, i) => {
                    return (
                      <div key={i} className='col-12 col-md-12 my-2'>
                        <label><span className='font-bold'>{el?.title}</span></label>
                        <div className="input-group">
                          <input type="number"
                            value={this.state.commissionObj[this.state.selectedBtn]?.questions[el.key]}
                            onChange={(e) => this.changeValue(e.target.value, el.key, this.state.selectedBtn)}
                            className="form-control transperent-box light-border-around text-gray"
                            aria-label="Recipient's username" aria-describedby="basic-addon2"
                            style={el.key === 'PLAYER_REFUND_ET' ? {borderRadius: '10px'} : {borderTopLeftRadius: '10px', borderBottomLeftRadius: '10px'} }
                            disabled={this.state.editLabel !== el.key || el.key === 'PLAYER_REFUND_ET'}
                          />
                          {el.key !== 'PLAYER_REFUND_ET' && 
                            <div className="input-group-append">
                              {
                                this.state.editLabel === el.key ?
                                  <button onClick={() => { this.onSubmit() }} className="btn edt-btn light-border-around" type="button" style={{ borderTopRightRadius: '10px', borderBottomRightRadius: '10px' }}>
                                    save
                                  </button> :
                                  <button className="btn edt-btn light-border-around" onClick={() => this.setState({ editLabel: el.key })} type="button" style={{ borderTopRightRadius: '10px', borderBottomRightRadius: '10px' }}>
                                    Edit
                                  </button>
                              }

                            </div>
                          }
                        </div>
                      </div>
                    )
                  })
                }

              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
