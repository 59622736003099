import { toast } from 'material-react-toastify';
import { Component } from 'react'
import CommissionService from '../../services/commission.service';
import { BREADCRUMBS } from '../../utils/breadcrumbs.utils';
import { errorHandler, routeTo, updateLoader } from '../../utils/common.utils';
import { EC_CURRENCY } from '../../utils/strings.utils';
export default class EcCurrency extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editLabel: '',
      currency: '',
      id: ''
    }
    this.commissionApis = new CommissionService();

  }

  componentDidMount() {
    this.getCurrency()
  }
  getCurrency = () => {
    updateLoader(true)
    this.commissionApis.getCurrency().then(
      (res) => {
        console.log(res.data.result)
        this.setState({ id: res.data.result[0]?._id, currency: res.data.result[0]?.value })
        updateLoader(false)
      }
    ).catch(
      (err) => {
        console.error(err)
        updateLoader(false)
        errorHandler(err)
      }
    )
  }
  onSubmit = () => {
    let data = {
      value: this.state.currency
    };
    let id = this.state.id;
    updateLoader(true)
    this.commissionApis.updateECValue(id, data).then(
      (res) => {
        console.log(res)
        this.setState({ editLabel: '' })
        toast.success('EC Value updated successfully')
        updateLoader(false)
      }
    ).catch(
      (err) => {
        console.error(err)
        updateLoader(false)
        errorHandler(err)
      }
    )
  }

  changeValue = (val) => {
    if (val.length < 5) {
      this.setState({ currency: val })
    }
  }
  render() {
    return (
      <div className="app-main__inner">
        <div className="d-flex flex-column flex-lg-row">
          <div className="flex-grow-1">
            <h3 className='text-white'>{BREADCRUMBS.EC_CURRENCY.HEADING}</h3>


          </div>
          <div className="">
            <ol className="breadcrumb color-white" style={{ background: 'none', padding: '0px' }}>
              {BREADCRUMBS.EC_CURRENCY.PATH.map((route, i) => {
                return <li key={i} className="breadcrumb-item cursor-pointer" onClick={() => { routeTo('/', 'dashboard') }}>{route}</li>
              })}
            </ol>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="main-card mb-3 card transperent-card text-white p-4">
              <div className="card-header no-background p-0 mb-2 ">Set up EC value
                <div className="btn-actions-pane-right">


                </div>
              </div>

              <div className='row'>
                {
                  EC_CURRENCY.map((el, i) => {
                    return (
                      <div key={i} className='col-12 col-md-12 my-2'>
                        <label><span className='font-bold'>{el?.title}</span></label>
                        <div className="input-group">
                          <input type="number"
                            value={this.state.currency}
                            onChange={(e) => { this.changeValue(e.target.value) }}
                            className="form-control transperent-box light-border-around text-gray"
                            aria-label="Recipient's username" aria-describedby="basic-addon2"
                            style={{ borderTopLeftRadius: '10px', borderBottomLeftRadius: '10px' }}
                            disabled={this.state.editLabel !== el.key}
                          />
                          <div className="input-group-append">
                            {
                              this.state.editLabel === el.key ?
                                <button onClick={() => { this.onSubmit() }} className="btn edt-btn light-border-around" type="button" style={{ borderTopRightRadius: '10px', borderBottomRightRadius: '10px' }}>
                                  save
                                </button> :
                                <button className="btn edt-btn light-border-around" onClick={() => this.setState({ editLabel: el.key })} type="button" style={{ borderTopRightRadius: '10px', borderBottomRightRadius: '10px' }}>
                                  Edit
                                </button>
                            }

                          </div>
                        </div>
                      </div>
                    )
                  })
                }

              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
