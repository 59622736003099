import { Component } from 'react'
import UserService from '../../services/user.service';
import { TableComponent } from '../common/DataTable'
import DataTable from 'react-data-table-component';
import Button from 'react-bootstrap/Button';
import AddAdmin from '../auth/AddAdmin';
import { Modal } from "react-bootstrap";
import AuthService from '../../services/auth.service';
import { BREADCRUMBS } from '../../utils/breadcrumbs.utils';
import { errorHandler, routeTo, updateLoader } from '../../utils/common.utils';
import { toast } from 'material-react-toastify';

export default class AdminList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            admins: [],
            totalResult: 0,
            params: {
                pageNo: 0,
                limit: 10
            },
            isAddAdmin: false,
            deleteUser: '',
        }
        this.userApi = new UserService();
        this.authApi = new AuthService();
        this.columns = [
            {
                name: "NAME",
                selector: (row) => [row.name],
                sortable: false,
            },
            {
                name: "EMAIL",
                selector: (row) => [row.email],
                sortable: false,
            },
            {
                cell: (row) => <span> <button className='es-squre-danger-btn' onClick={() => { this.setConfirmation(row.email) }} id={row.id}><i className="fa fa-trash" title=""></i></button> </span>,
                ignoreRowClick: true,
                allowOverflow: true,
                button: true,
                name: 'ACTION'
            },
        ]
    }
    componentDidMount() {
        this.getAdmins(this.state.params)
    }
    onPagination = (i, key) => {
        let paramsTemp = this.state.params
        paramsTemp[key] = i
        this.setState({ params: paramsTemp })
        console.log(`${key}`, paramsTemp)
        this.getAdmins(paramsTemp)
    }
    getAdmins = (params) => {
        updateLoader(true)
        this.userApi.getAllAdmins(params).then(
            (res) => {
                let admins = res.data.result.map((el, i) => { el.index = i; return el })
                this.setState({ admins: admins, totalResult: res.data.totalResults, deleteUser: '', isAddAdmin: false })
                console.log("res - ", res.data.result)
                updateLoader(false)
            }
        ).catch(
            (err) => {
                console.error(err)
                updateLoader(false)
                errorHandler(err)
            }
        )
    };
    setConfirmation = (email) => {
        console.log(email)
        this.setState({ deleteUser: email })
    }
    deleteAdmin = () => {
        let data = {
            email: this.state.deleteUser
        }
        updateLoader(true)
        this.authApi.removeAdmins(data).then(
            (res) => {
                updateLoader(false)
                this.getAdmins(this.state.params)
                toast.success('Admin deleted successfully')
                // this.setState({ deleteUser: '' })
            }
        ).catch(
            (err) => {
                console.error(err)
                updateLoader(false)
                errorHandler(err)
            }
        )
    }
    render() {
        return (
            <section>


                <div className="app-main__inner">
                    <div className="d-flex flex-column flex-lg-row">
                        <div className="flex-grow-1">
                            <h3 className='text-white'>{BREADCRUMBS.USERS_ADMINS.HEADING}</h3>
                        </div>
                        <div className="">
                            <ol className="breadcrumb color-white" style={{ background: 'none', padding: '0px' }}>
                                {BREADCRUMBS.USERS_ADMINS.PATH.map((route, i) => {
                                    return <li key={i} className="breadcrumb-item cursor-pointer" onClick={() => { routeTo('/', 'dashboard') }}>{route}</li>
                                })}
                            </ol>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="main-card mb-3 card transperent-card text-white p-4">
                                {/* <div className="card-header no-background p-0 mb-2">
                                    <div className="btn-actions-pane-right">
                                        <div className="input-group icons search-box">
                                            <input type="search" className="form-control rounded text-white transperent-box light-border-around" placeholder="Search in table ...." />
                                        </div>
                                    </div>
                                </div> */}
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <div className='mb-4 float-right'>
                                            <Button className='es-btn-padding' onClick={() => { this.setState({ isAddAdmin: true }) }} variant="primary">Add Admin</Button>
                                        </div>
                                        <div className='mb-4 float-right mr-2'>
                                            <input type="search" className="form-control rounded text-white transperent-box light-border-around" placeholder="Search in table ...." />
                                        </div>
                                    </div>

                                </div>
                                <div className='row'>
                                    <TableComponent column={this.columns} data={this.state.admins} paginationTotalRows={this.state.totalResult} onPagination={this.onPagination} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal show={this.state.isAddAdmin} centered dialogClassName="">
                    <Modal.Body>
                        <AddAdmin getAdmins={() => { this.getAdmins(this.state.params) }} close={() => { this.setState({ isAddAdmin: false }) }} />
                    </Modal.Body>
                </Modal>
                <Modal show={this.state.deleteUser} centered dialogClassName="">
                    <Modal.Body>
                        <div>
                            <div className='my-4'>
                                Are you sure you want to remove <b>{this.state.deleteUser}</b> as an Admin
                            </div>
                            <div>
                                <Button onClick={this.deleteAdmin} variant="secondary" className="me-1">
                                    Remove
                                </Button>
                                <Button onClick={() => { this.setState({ deleteUser: '' }) }} className="me-1" variant="success">
                                    Close
                                </Button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

            </section>
        )
    }
}
