import React, { Component } from 'react'
import { DebounceInput } from 'react-debounce-input'
import UserService from '../../services/user.service'
import { errorHandler, getTeamMembers, updateLoader } from '../../utils/common.utils'
import { dateFormat } from '../../utils/dateTime.utils'
import { CLAN_VIEWS } from '../../utils/strings.utils'
import { TableComponent } from '../common/DataTable'

export default class ClanTeamsStats extends Component {
    
    constructor(props) {
        super(props)
        this.state = {
            viewClanDetailsId: props.viewClanDetailsId,
            viewClanDetails: props.viewClanDetails,
            params: {
                pageNo: 0,
                limit: 10,
            },
            teams: [],
            selectedTeamMembers: [],
            showTeamMembers: false,
            totalTeams: null,
        }
        this.authApi = new UserService();
        this.columns = [
            // {
            //     name: "TEAM ID",
            //     selector: (row) => [row._id],
            //     sortable: false,
            // },
            {
                name: "TEAM NAME",
                selector: (row) => [row.name],
                sortable: false,
            },
            {
                name: "MEMBERS",
                selector: (row) => [<span style={{color: '#13f745'}}>{[getTeamMembers(row).length]} <i onClick={()=>this.updateSelectedTeamStats({selectedTeamMembers: getTeamMembers(row), showTeamMembers: true})} className='mx-1 cursor-pointer' style={{color:'#8e7ffb'}}>(View all)</i></span>],//[getTeamMembers(row).length],
                sortable: false,
            },
            {
                name: "TOURNAMENTS WON/PLAYED",
                selector: (row) => ['NA'], //No data in API response
                sortable: false,
            },
            {
                name: "TOTAL WINNING AMOUNT",
                selector: (row) => ['NA'], //No data in API response
                sortable: false,
            },
            {
                name: "CREATED DATE",
                selector: (row) => [dateFormat(row.createdAt, true)],
                sortable: false,
            },
            {
                name: "CREATED TIME",
                selector: (row) => [dateFormat(row.createdAt, false)],
                sortable: false,
            },
        ]
    }

    componentWillReceiveProps(newProps){
        this.setState({
            viewClanDetailsId: newProps.viewClanDetailsId,
            viewClanDetails: newProps.viewClanDetails
        });
        if(newProps.viewClanDetailsId !== this.state.viewClanDetailsId){
            let {params} = this.state;
            params = {...params, clanId: newProps.viewClanDetailsId};
            this.getClanTeams(params);
        }
    }

    componentDidMount() {
        if(this.props.viewClanDetailsId){
            let {params} = this.state;
            params = {...params, clanId: this.props.viewClanDetailsId};
            this.getClanTeams(params);
        }
    }

    getClanTeams = (params) => {
        updateLoader(true)
        this.authApi.getAllTeams(params).then(
            (res) => {
               //const tournamentDetails = res.data.result?.filter((obj)=> obj !== null);
               //console.log("@@@ getClanTeams res : ",res);
               this.setState({teams: res.data.result, totalTeams: res.data?.totalResults})
               updateLoader(false)
            }
        ).catch(
            (err) => {
                console.error(err)
                updateLoader(false)
                errorHandler(err)
            }
        )
    };

    onPagination = (i, key) => {
        let paramsTemp = this.state.params
        paramsTemp[key] = i;
        paramsTemp['clanId'] = this.state.viewClanDetailsId;
        this.setState({ params: paramsTemp })
        console.log(`${key}`, paramsTemp)
        this.getClanTeams(paramsTemp)
    }

    updateSelectedTeamStats = (obj) => { 
        this.setState(obj)
    }

    getTeamMembersView = () => { 
        this.columns2 = [
            // {
            //     name: "USER ID",
            //     selector: (row) => [row._id],
            //     sortable: false,
            // },
            {
                name: "NAME",
                selector: (row) => [row.name],
                sortable: false,
            },
            {
                name: "ROLE",
                selector: (row) => [row.role],
                sortable: false,
            },
            {
                name: "A.K.A",
                selector: (row) => [row.inGameName ? row.inGameName : row.username], //No data in API response
                sortable: false,
            },
            {
                name: "DEN",
                selector: (row) => [row.den ? 'Yes' : 'No'], //No data in API response
                sortable: false,
            },
            {
                name: "KYC",
                selector: (row) => [row.kycVerified ? 'Yes' : 'No'],
                sortable: false,
            },
            {
                name: "VERIFIED",
                selector: (row) => [(row.verificationStatus === 'VERIFIED') ? 'Yes' : 'No'],
                sortable: false,
            },
            {
                name: "EMAIL",
                selector: (row) => [row.email],
                sortable: false,
            },
            {
                name: "DISCORD ID",
                selector: (row) => [row.inDiscordId], //No data in API response
                sortable: false,
            },
        ]
        return(
            <div className=' p-4'>
                <div className='d-flex flex-column flex-md-row'>
                    <div>
                        <p className='fw-600 my-0' style={{fontSize:'20px'}}>Clan Name: {this.state.viewClanDetails?.name}</p>
                        <p className='fw-500 my-0'>Clan ID: {this.state.viewClanDetailsId}</p>
                    </div>
                    <button className='ms-auto btn edt-btn light-border-around' onClick={()=>this.updateSelectedTeamStats({selectedTeamMembers: [], showTeamMembers: false})}>Back to Teams</button>
                </div>
                
                <div className="card-header no-background p-0 mb-2">
                    <div className="btn-actions-pane-right">
                        <div className="input-group icons search-box">
                            <DebounceInput minLength={3}
                                debounceTimeout={500}
                                type="search"
                                className="ml-2 form-control rounded text-white transperent-box light-border-around"
                                placeholder="Search in table ...."
                                maxLength={100}
                                //onChange={(e) => { this.onSearch(e.target.value) }} 
                            />
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <TableComponent column={this.columns2} data={this.state.selectedTeamMembers} paginationTotalRows={this.state.selectedTeamMembers.length} onPagination={this.onPagination} />
                </div>
            </div>
        )
    }

    render() {
        
        return (
            <div className="main-card mb-3 card transperent-card text-white">
                <div className="card-header no-background px-3 py-0 my-0">
                    <p className="my-0 sub-heading ">Clan Teams Details</p>
                    <div className="btn-actions-pane-right">
                        <button className='btn edt-btn light-border-around' onClick={()=>this.props.updateState({viewClanDetails: null, viewUserDetailsId: null, currentView: CLAN_VIEWS.CLANS})}>Back</button>
                    </div>
                </div>
                <hr className='seperator my-0' />
                {this.state.showTeamMembers ? 
                    this.getTeamMembersView()
                    :
                    <div className=' p-4'>
                        <p className='fw-600 my-0' style={{fontSize:'20px'}}>Clan Name: {this.state.viewClanDetails?.name}</p>
                        <p className='fw-500 my-0'>Clan ID: {this.state.viewClanDetailsId}</p>
                        <div className="card-header no-background p-0 mb-2">
                            <div className="btn-actions-pane-right">
                                <div className="input-group icons search-box">
                                    <DebounceInput minLength={3}
                                        debounceTimeout={500}
                                        type="search"
                                        className="ml-2 form-control rounded text-white transperent-box light-border-around"
                                        placeholder="Search in table ...."
                                        maxLength={100}
                                        //onChange={(e) => { this.onSearch(e.target.value) }} 
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <TableComponent column={this.columns} data={this.state.teams} paginationTotalRows={this.state.totalTeams} onPagination={this.onPagination} />
                        </div>
                    </div>
                }
                
            </div>
        )
    }
}
