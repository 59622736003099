
import React, { Component } from 'react';
import { WEBSITE } from '../../utils/strings.utils';
import { getMaxLenght, getWebsiteDetails, routeTo, updateWebsiteDetails } from '../../utils/common.utils';
import { BREADCRUMBS } from '../../utils/breadcrumbs.utils';
import { toast } from 'material-react-toastify';
import Dropdown from 'react-bootstrap/Dropdown';
import Confirmation from '../common/Confirmation';
import "../../assets/css/switch.css";
import "../../assets/css/game.css";
import { Modal } from 'react-bootstrap';
import AddRule from '../popup/AddRule';

export default class StaticPages extends Component {

    constructor(props) {
        super(props);
        this.state = { 
          websiteDetails : null,
          selectedChoice : 0,
          editIndex : -1,
          updateValue : null,
          openConfirmationModal : false,
          deleteIndex : -1,
          editQuestionIndex : '0.0',
          addUpdateRule : false,
          editIndex : -1
        };
    }
  
    componentDidMount(){
      getWebsiteDetails(`type=STATIC_PAGE`,this.setwebsiteDetails);
    }

    // Updated site details on recieve new props
    setwebsiteDetails = (websiteDetails) =>{
      if(websiteDetails){
        websiteDetails = websiteDetails.sort((a, b) => a.subType.toLowerCase().localeCompare(b.subType.toLowerCase()))
        this.setState({
          websiteDetails: websiteDetails,
          editIndex : -1,
          updateValue : null,
          openConfirmationModal : false,
          deleteIndex : -1,
          editQuestionIndex : '0.0',
          addUpdateRule : false,
          editIndex : -1
        });
      }
    }

    // Filter between tabs
    onSelectOption = (index) =>{
      this.setState({
        selectedChoice : index
      });
    }

    // Save chaged value in state (temporary)
    changeValue = (val) =>{
      this.setState({
        updateValue: (val ? val : "")
      });
    }

    // Commom function to Perform CRUD
    updateWebsiteDetails = (key) => {
      let data = {};
      data[key] = this.state.updateValue;
      updateWebsiteDetails(this.state.websiteDetails[this.state.selectedChoice]._id, data, this.setwebsiteDetails);
    }

    getDefaultValue = (obj) => {
      if(obj){
        return Object.values(obj)[0]
      }else{
        return "";
      }
    }

    // To add new question entry in FAQ's
    addNewQuetion = () => {
      var updatedFAQ = this.state.websiteDetails[this.state.selectedChoice].FAQ;
      let newQuestion = {
        QUESTION : `string ${updatedFAQ.length+1}`,
        ANSWER : 'string',
      }
      updatedFAQ.push(newQuestion);
      updateWebsiteDetails(this.state.websiteDetails[this.state.selectedChoice]._id, {FAQ : updatedFAQ}, this.setwebsiteDetails);
    }

    // On Delete confirmation
    confirmDelete = () => {
      if(this.state.websiteDetails[this.state.selectedChoice]?.subType === "FAQS"){
        var updatedFAQ = this.state.websiteDetails[this.state.selectedChoice].FAQ;
        updatedFAQ.splice((this.state.deleteIndex), 1);
        updateWebsiteDetails(this.state.websiteDetails[this.state.selectedChoice]._id, {FAQ : updatedFAQ}, this.setwebsiteDetails);
      }else{
        const rules = this.getDefaultValue(this.state.websiteDetails[this.state.selectedChoice].questions);
        var rulesArray = rules.split("&&");
        rulesArray.splice(this.state.deleteIndex, 1);
        var updatedRules = rulesArray.join('&&');
        this.addUpdateRule(updatedRules);
      }
    }

    // To edit QUESTION/ANSWER field 
    enableEditOption = (index, i, key) =>{
      this.setState({
        editQuestionIndex: index
      });
    }
    // To save the edited chages of QUESTION/ANSWER Field 
    saveAndCloseEditOption = (index, i, key) =>{

      if(this.state.updateValue){
        var updatedFAQ = this.state.websiteDetails[this.state.selectedChoice].FAQ;
        updatedFAQ[i][key] = this.state.updateValue;
        updateWebsiteDetails(this.state.websiteDetails[this.state.selectedChoice]._id, {FAQ : updatedFAQ}, this.setwebsiteDetails);
      }else{
        this.setState({
          editQuestionIndex: '0.0'
        });
      }
    }

    addUpdateRule = (updatedRules) =>{
      let data = {};
      data[`SET_UP_`+this.state.websiteDetails[this.state.selectedChoice]?.subType] = updatedRules;
      updateWebsiteDetails(this.state.websiteDetails[this.state.selectedChoice]._id, data, this.setwebsiteDetails);
    }

    saveAndCloseEditRules = (index, rulesArray) =>{
      let val = this.state.updateValue
      if((val === '') || (!val.trim().length)){
        toast.warning('Required fields are missing.');
        return false;
      }
      rulesArray[index] = this.state.updateValue;
      var updatedRules = rulesArray.join('&&')
      this.addUpdateRule(updatedRules);
    }

    enableEditRules = (index) =>{
      this.setState({
        editIndex : index
      });
    }

    render() {
       
        if(this.state.websiteDetails){
          const {editQuestionIndex, editIndex} = this.state; 
          const isFAQsSection = (this.state.websiteDetails[this.state.selectedChoice]?.subType === "FAQS");
        
          const rules = this.getDefaultValue(this.state.websiteDetails[this.state.selectedChoice].questions);
          var rulesArray = rules.split("&&")
         
          return (
            <div className="app-main__inner">
                {/* HEAD */}
                <div className="d-flex flex-column flex-lg-row">
                    {/* MAIN-HEAD */}
                    <div className="flex-grow-1">
                        <h3 className='text-light-white'>{BREADCRUMBS.STATIC_PAGES.HEADING}</h3>
                    </div>
                    {/* BRAD-CRUMB */}
                    <div className="">
                        <ol className="breadcrumb color-white" style={{ background: 'none', padding: '0px' }}>
                            {BREADCRUMBS.STATIC_PAGES.PATH.map((route,i)=>{
                                return <li key={i} className="breadcrumb-item cursor-pointer" onClick={() => { routeTo('/','dashboard')  }}>{route}</li>
                            })}
                        </ol>
                    </div>
                </div>
                {/* BODY */}
                <div className="row">

                   
                    <div className="col-md-12">
                        <div className="main-card mb-3 card transperent-card text-white p-4">
                            {/* SUB-HEAD */}
                            <div className="card-header no-background p-0 mb-2 ">  Information on <i className="fa fa-info-circle px-2 fs-20 mb-1" aria-hidden="true"/> button (For that field)
                                {/* FILTER */}
                                <div className="btn-actions-pane-right">
                                
                                  <Dropdown>
                                    <Dropdown.Toggle variant="success" className='transperent-box light-border-around text-white' id="dropdown-basic">
                                      <b className='px-5'>{WEBSITE.STATIC_PAGES[this.state.websiteDetails[this.state.selectedChoice]?.subType]?.NAME}</b>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                      {this.state.websiteDetails?.map((module,i)=>{
                                        if(this.state.selectedChoice !== i){
                                          return (
                                            <Dropdown.Item key={i} onClick={()=>this.onSelectOption(i)}>
                                              <b className='px-4'>{WEBSITE.STATIC_PAGES[module.subType]?.NAME}</b> 
                                            </Dropdown.Item>
                                          )
                                        }
                                      })}
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                            </div>
                            {/* MAIN_BODY */}
                            {isFAQsSection ?
                              <div className='row px-3'>
                                {this.state.websiteDetails[this.state.selectedChoice]?.FAQ.map((statment,i)=>{
                                  var editQueLabel = editQuestionIndex === `${i+1}.1` ? 'Save' : 'Edit';
                                  var editAnsLabel = editQuestionIndex === `${i+1}.2` ? 'Save' : 'Edit';
                                  var editQueAction = editQuestionIndex === `${i+1}.1` ? this.saveAndCloseEditOption : this.enableEditOption;
                                  var editAnsAction = editQuestionIndex === `${i+1}.2` ? this.saveAndCloseEditOption : this.enableEditOption;
                                  return (
                                    <div className='col-12 my-2 question-answer' key={i}>
                                    
                                      {/* Question */}
                                      <div className='my-3'>
                                        <label><span className=' font-bold'>Question #{i+1}</span></label>
                                        <div className="input-group">
                                          <input type="text" 
                                            id="question"
                                            readOnly={editQuestionIndex !== `${i+1}.1`}
                                            defaultValue={statment.QUESTION}
                                            className="form-control transperent-box light-border-around rounded-border-left text-white" 
                                            placeholder={`Enter question no. ${i+1}`} 
                                            onChange={(e)=>this.changeValue(e.target.value)} 
                                            autoComplete="off"
                                            maxLength={150}
                                            />
                                          <div className="input-group-append ">
                                            <button className="btn btn-primary light-border-around fw-bold" onClick={()=>editQueAction(`${i+1}.1`, i, 'QUESTION')} type="button">{editQueLabel}</button>
                                            <button onClick={()=>this.setState({ deleteIndex: i, openConfirmationModal: true })} className="btn btn-primary light-border-around fw-bold rounded-border-right" type="button">Delete</button>
                                          </div>
                                        </div>
                                      </div>

                                      {/* Answer */}
                                      <div className='my-3'>
                                        <label><span className=' font-bold'>Answer #{i+1}</span></label>
                                        <div className="input-group" >
                                          <input type="text" 
                                            id="answer"
                                            readOnly={editQuestionIndex !== `${i+1}.2`}
                                            defaultValue={statment.ANSWER}
                                            className="form-control transperent-box light-border-around text-gray"
                                            style={{borderTopLeftRadius:'10px', borderBottomLeftRadius: '10px'}}
                                            placeholder={`Enter answer of question no. ${i+1}`} 
                                            onChange={(e)=>this.changeValue(e.target.value)} 
                                            autoComplete="off"
                                            maxLength={400}
                                          />
                                          <div className="input-group-append">
                                            <button className="btn edt-btn light-border-around rounded-border-right" onClick={()=>editAnsAction(`${i+1}.2`, i, 'ANSWER')} type="button" > {editAnsLabel} </button>
                                          </div>
                                        </div>
                                      </div>

                                    </div>
                                  );
                                })}
                              </div>
                            :
                              <div className='row  '>
                                {rulesArray.map((rule,j)=>{
                                  const isEditEnabled = (editIndex === j);
                                  const actionLabel = isEditEnabled ? 'Save' : 'Edit';
                                  const action = isEditEnabled ? this.saveAndCloseEditRules : this.enableEditRules;
                                  return (
                                    <div className='col-12  my-2' key={j}>
                                      <label><span className=' font-bold'>{WEBSITE.STATIC_PAGES[this.state.websiteDetails[this.state.selectedChoice]?.subType]?.NAME} #{j+1}</span></label>
                                        <div className="input-group">
                                        <input type="text"
                                            key={this.state.websiteDetails[this.state.selectedChoice]?.subType+j} 
                                            id={this.state.websiteDetails[this.state.selectedChoice]?.subType+j}
                                            readOnly={!isEditEnabled}
                                            defaultValue={rule ? rule : ""}
                                            className="form-control transperent-box light-border-around text-gray"
                                            style={{borderTopLeftRadius:'10px', borderBottomLeftRadius: '10px'}}
                                            onChange={(e)=>this.changeValue(e.target.value)} 
                                            autoComplete="off"
                                            maxLength={getMaxLenght(this.state.websiteDetails[this.state.selectedChoice]?.subType)}
                                          />
                                          <div className="input-group-append ">
                                              <button onClick={()=>action(j, rulesArray)} className="btn btn-primary light-border-around fw-bold"  type="button">{actionLabel}</button> 
                                              <button onClick={()=>this.setState({ deleteIndex: j, openConfirmationModal: true })} className="btn btn-primary light-border-around fw-bold rounded-border-right" type="button">Delete</button>
                                            </div>
                                        </div> 
                                    </div>
                                  )
                                })}


                                <div className='col-12  my-2' key={this.state.websiteDetails[this.state.selectedChoice]?.subType}>
                                
                                  <div className="my-2 " >
                                    <button type="button" onClick={()=>this.setState({addUpdateRule : true})} className="btn btn-primary mx-2">+ Add Another {WEBSITE.STATIC_PAGES[this.state.websiteDetails[this.state.selectedChoice]?.subType]?.NAME}</button>
                                  </div>
                                </div>

                              </div>
                            }

                            {isFAQsSection &&
                              <div className='d-flex '>
                                <div className="my-2 ms-2" >
                                  <button type="button" onClick={this.addNewQuetion} className="btn btn-primary">{WEBSITE.CONTACT.ADD_MORE}</button>
                                </div>
                              </div>
                            }
                        </div>
                    </div>
                </div>
                <Modal show={this.state.addUpdateRule} centered dialogClassName=''>
                  <Modal.Body>
                    <AddRule
                      message={`Are you sure you want to delete this reason no.  from reason's ?`}
                      type={WEBSITE.STATIC_PAGES[this.state.websiteDetails[this.state.selectedChoice]?.subType]?.NAME}
                      addUpdateRule={this.addUpdateRule}
                      rules={rules}
                      label={WEBSITE.STATIC_PAGES[this.state.websiteDetails[this.state.selectedChoice]?.subType]?.NAME}
                      number={rulesArray?.length+1}
                      onConfirm={this.deleteReason} onClose={() => this.setState({ addUpdateRule : false })}
                    />
                  </Modal.Body>
                </Modal>

                <Modal show={this.state.openConfirmationModal} centered dialogClassName=''>
                  <Modal.Body>
                    <Confirmation  
                      message={`Are you sure you want to delete this ${isFAQsSection ? 'question no.'+ (this.state.deleteIndex+1) +" from FAQ's " : WEBSITE.STATIC_PAGES[this.state.websiteDetails[this.state.selectedChoice]?.subType]?.NAME+" "+(this.state.deleteIndex+1)} ?`} 
                      onConfirm={this.confirmDelete} onClose={()=>this.setState({openConfirmationModal: false, deleteIndex: -1})} 
                    />
                  </Modal.Body>
                </Modal>
                
            </div>
          );
        }else{
          return null;
        }
       
    }
}