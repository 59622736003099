
import React,{ Component } from 'react';

class PageNotFound extends Component {
    constructor(props) {
        super(props);
        this.state = { };
    }

    render() {
        return (
            <div >
               <h1>page not found</h1>
            </div>
        );
    }

}

export default PageNotFound;