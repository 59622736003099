import React, { Component } from 'react';
import { HEADING, POINTS_RANK, DEN } from '../../utils/strings.utils';
import { getDenDetails, updateDenDetails, routeTo } from '../../utils/common.utils';
import { BREADCRUMBS } from '../../utils/breadcrumbs.utils';
import { DEN_ENUM } from '../../utils/strings.utils';
import "../../assets/css/switch.css";
import "../../assets/css/game.css";
import { toast } from 'material-react-toastify';

export default class DenSpotlight extends Component {
  
  constructor(props) {
    super(props);
    this.state = { 
      denDetails : null,
      selectedChoice : 0,
      editIndex : -1
    };
  }

  componentDidMount(){
    getDenDetails(`type=SPOTLIGHT`,this.setDenDetails);
  }

  setDenDetails = (denDetails) =>{
    if(denDetails){
      denDetails = denDetails.sort((a, b) => a.subType.toLowerCase().localeCompare(b.subType.toLowerCase()))
      this.setState({
        denDetails: denDetails,
        editIndex : -1,
      });
    }
  }

  changeValue = (val) =>{
    const {editIndex, denDetails} = this.state;
    //console.log('old denDetails', denDetails)
    denDetails[0].questions['SET_SPOTLIGHT_VALUE'][editIndex] = parseInt(val);
    //console.log('updated denDetails', denDetails);
    this.setState({
      denDetails: denDetails
    })
  }

  updateData = () => {
    const {editIndex, denDetails} = this.state;
    const id = this.state.denDetails[0]?._id;

    if(isNaN(parseFloat(denDetails[0]?.questions['SET_SPOTLIGHT_VALUE'][editIndex]))){
      toast.error('Spotlight value should be a positive number');
      return false;
    }

    if(editIndex === 4 && (denDetails[0]?.questions['SET_SPOTLIGHT_VALUE'][editIndex] <= denDetails[0]?.questions['SET_SPOTLIGHT_VALUE'][editIndex-1])){
      toast.warning(`Spotlight value should be greater than ${denDetails[0]?.questions['SET_SPOTLIGHT_VALUE'][editIndex-1]}`);
      return false;
    }

    if(editIndex === 0 && (denDetails[0]?.questions['SET_SPOTLIGHT_VALUE'][editIndex] >= denDetails[0]?.questions['SET_SPOTLIGHT_VALUE'][editIndex+1])){
      toast.warning(`Spotlight value should be less than ${denDetails[0]?.questions['SET_SPOTLIGHT_VALUE'][editIndex+1]}`);
      return false;
    }

    if((editIndex > 0 && editIndex < 4) && ((denDetails[0]?.questions['SET_SPOTLIGHT_VALUE'][editIndex-1] >= denDetails[0]?.questions['SET_SPOTLIGHT_VALUE'][editIndex]) || (denDetails[0]?.questions['SET_SPOTLIGHT_VALUE'][editIndex] >= denDetails[0]?.questions['SET_SPOTLIGHT_VALUE'][editIndex+1]))){
      toast.warning(`Spotlight value should be in between ${denDetails[0]?.questions['SET_SPOTLIGHT_VALUE'][editIndex-1]} and ${denDetails[0]?.questions['SET_SPOTLIGHT_VALUE'][editIndex+1]}`);
      return false;
    }
    //console.log('------ id', this.state.denDetails[0]?._id);
    const data = {"SET_SPOTLIGHT_VALUE" :this.state.denDetails[0]?.questions['SET_SPOTLIGHT_VALUE']};
    updateDenDetails(id, data, this.setDenDetails);
  }

  updateEditIndex = (label) => {
    console.log('Label ::', label);
    this.setState({editIndex : label})
  }

  render() {
    const changeValue = this.changeValue;
    const {editIndex, denDetails, selectedChoice} = this.state;
    
    if(this.state.denDetails){
      //console.log('Questions ::', this.state.denDetails[0]?.questions)
      return (
        <div className="app-main__inner">
            {/* HEAD */}
            <div className="d-flex flex-column flex-lg-row">
                {/* MAIN-HEAD */}
                <div className="flex-grow-1">
                    <h3 className='text-light-white'>{BREADCRUMBS.SPOTLIGHT.HEADING}</h3>
                </div>
                {/* BRAD-CRUMB */}
                <div className="">
                    <ol className="breadcrumb color-white" style={{ background: 'none', padding: '0px' }}>
                        {BREADCRUMBS.SPOTLIGHT.PATH.map((route,i)=>{
                            return <li key={i} className="breadcrumb-item cursor-pointer" onClick={() => { routeTo('/','dashboard')  }}>{route}</li>
                        })}
                    </ol>
                </div>
            </div>
            {/* BODY */}
            <div className="row">
            <div className="col-md-12">
              <div className="main-card mb-3 card transperent-card text-white p-4">
                  
              {DEN_ENUM?.SPOTLIGHT.map((question,i) => {
                //console.log('value ::', this.state.denDetails[selectedChoice].questions[question.key]);
                return (
                  <div className='col-12  my-2' key={i}>
                    <label><span className=' font-bold'>{question.title}</span></label>
                  
                    <div className="input-group">
                      <input type="number"
                        onChange={(e)=>changeValue(e.target.value)} onWheel={(e) => e.target.blur()} required
                        value={this.state.denDetails[selectedChoice]?.questions['SET_SPOTLIGHT_VALUE'][question.key]} //denDetails[selectedChoice].questions[key]
                        placeholder={'Enter a number'} //`Enter ${WEBSITE.INFORMATION_TEXT[selectedOption][key]} `
                        className="form-control transperent-box light-border-around text-gray" 
                        aria-label="Recipient's username" aria-describedby="basic-addon2"
                        style={{borderTopLeftRadius:'10px', borderBottomLeftRadius: '10px'}}
                        disabled={this.state.editIndex === question.key ? false : true}
                      />
                      <div className="input-group-append">
                        <button className="btn edt-btn light-border-around" type="button" style={{borderTopRightRadius:'10px', borderBottomRightRadius: '10px'}}
                          onClick={(e) => {this.state.editIndex === question.key ? this.updateData(e.target.value) : this.updateEditIndex(question.key)}}
                        >
                          {this.state.editIndex === question.key ? 'Save' : 'Edit'} 
                        </button>
                      </div>
                    </div>
                  </div>
                )
              })}
              </div>
              </div>
            </div>
        </div>
      );
    }else{
      return null;
    }
   
}
}

