import { Component } from 'react'
import { BREADCRUMBS } from '../../utils/breadcrumbs.utils';
import { errorHandler, routeTo, updateLoader } from '../../utils/common.utils';
import { EC_CURRENCY } from '../../utils/strings.utils';
import Confirmation from '../common/Confirmation';
import { Button, Modal } from 'react-bootstrap'
import RulesService from '../../services/rules.service';
import { toast } from 'material-react-toastify';

export default class LandingPageRules extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rules: [],
      addModal: false,
      description: '',
      heading: '',
      rulesWithId: [],
      upateId: ''
    }
    this.rulesApis = new RulesService();
  }

  componentDidMount() {
    this.getData()
  }
  getData = () => {
    updateLoader(true)
    this.rulesApis.getRules().then(
      (res) => {
        console.log(res.data.result)
        let rules = []
        let upateId;
        for (let data of res.data.result) {
          if (!data.game) {
            rules = data.rule;
            upateId = data._id
          }
        }
        this.setState({ rules: rules, addModal: false, upateId: upateId })
        updateLoader(false)
      }
    ).catch(
      (err) => {
        console.error(err)
        updateLoader(false)
        errorHandler(err)
      }
    )
  }
  updateRule = () => {
    let data = {
      rule: this.state.rules
    }
    updateLoader(true)
    this.rulesApis.updateRules(this.state.upateId, data).then(
      (res) => {
        console.log(res.data.result)
        this.setState({
          addModal: false,
          description: '',
          heading: '',
          upateId: ''
        })
        updateLoader(false)
        toast.success("Rules updated successfully")
      }
    ).catch(
      (err) => {
        console.error(err)
        updateLoader(false)
        errorHandler(err)
      }
    )
  }
  addRule = () => {
    let data = {
      heading: this.state.heading,
      description: this.state.description
    }
    let rules = this.state.rules;
    if (this.state.editId) {
      rules[(this.state.editId - 1)] = data;
    } else {
      rules.push(data)
    }
    this.setState({
      addModal: false,
      rules: rules,
      description: '',
      heading: '',
      editId: ''
    })
  }
  deleteRule = (i) => {
    let data = this.state.rules
    data.splice(i, 1)
    this.setState({ data: data, editId: '' })
  }
  editRule = (i, el) => {
    console.log(`${i} - `, el)
    this.setState({ description: el.description, heading: el.heading, editId: i })
  }
  render() {
    return (
      <div className="app-main__inner">
        <div className="d-flex flex-column flex-lg-row">
          <div className="flex-grow-1">
            <h3 className='text-white'>{BREADCRUMBS.LANDING_PAGE.HEADING}</h3>


          </div>
          <div className="">
            <ol className="breadcrumb color-white" style={{ background: 'none', padding: '0px' }}>
              {BREADCRUMBS.LANDING_PAGE.PATH.map((route, i) => {
                return <li key={i} className="breadcrumb-item cursor-pointer" onClick={() => { routeTo('/', 'dashboard') }}>{route}</li>
              })}
            </ol>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="main-card mb-3 card transperent-card text-white p-4">
              <div className="card-header no-background p-0 mb-2 ">Set up rules
                <div className="btn-actions-pane-right">


                </div>
              </div>

              <div className='row rules-border'>
                {
                  this.state.rules.map((el, i) => {
                    return (
                      <div key={i} className='col-12 col-md-12 my-2'>
                        <h4 style={{ color: '#fff' }}>{el?.heading} <span style={{ float: 'right' }}><span className='mr-2' onClick={() => { this.deleteRule(i) }}><i className="fa fa-trash" title=""></i></span><span onClick={() => { this.editRule((i + 1), el) }}><i className="fa fa-edit" title=""></i></span></span></h4>
                        <p>{el?.description}</p>
                      </div>
                    )
                  })
                }

              </div>
              <div>
                <button onClick={() => { this.setState({ addModal: true }) }} className="btn edt-btn light-border-around mr-2" type="button">Add New Rule</button>
                <button onClick={this.updateRule} className="btn edt-btn light-border-around" type="button">Save</button>
              </div>
            </div>
          </div>
        </div>
        <Modal show={this.state.addModal || this.state.editId} centered dialogClassName=''>
          <Modal.Body>
            <div className='modal-content' style={{ border: 'none', borderRadius: '0' }}>
              <h3 className='text-center text-white fw-bold'>{this.state.editId ? 'Update' : 'Add'} Rule</h3>
              <div className='my-2 text-center text-white fw-500'>
                <div className="input-group mb-4">
                  <input type="text"
                    value={this.state.heading}
                    onChange={(e) => { this.setState({ heading: e.target.value }) }}
                    placeholder='Enter rule heading'
                    maxLength={200}
                    className="form-control transperent-box light-border-around text-gray"
                    aria-label="Recipient's username" aria-describedby="basic-addon2"
                  // style={{ borderTopLeftRadius: '10px', borderBottomLeftRadius: '10px' }}
                  />
                </div>
                <div className="input-group">
                  <textarea type="text"
                    value={this.state.description}
                    onChange={(e) => { this.setState({ description: e.target.value }) }}
                    placeholder='Enter rule'
                    rows={3}
                    maxLength={500}
                    className="form-control transperent-box light-border-around text-gray"
                    aria-label="Recipient's username" aria-describedby="basic-addon2"
                  // style={{ borderTopLeftRadius: '10px', borderBottomLeftRadius: '10px' }}
                  />
                </div>
              </div>
              <div className='d-flex justify-content-center my-3'>
                <Button disabled={!this.state.description} onClick={this.addRule} className="btn btn-primary fw-bold me-1">
                  {this.state.editId ? 'Update' : 'Add'}
                </Button>
                <Button onClick={() => { this.setState({ addModal: false, editId: '', description: '', heading: '' }) }} className="btn-primary fw-bold me-1">
                  Close
                </Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    )
  }
}
