import { Component } from 'react';
//import Logo from '../assets/images/logo.png';
//import { Navigate } from "react-router-dom";
import { Role, withRouter } from '../utils/common.utils';
import { SIDEBAR } from '../utils/images.utils'
import packageJson from '../../package.json';

class Sidebar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activePage: 'dashboard'
        };
    }
    componentDidMount() {
        var pathArray = window.location.pathname.split('/');
        var page = pathArray[pathArray.length - 1];
        console.log();
        if (page) {
            console.log("-------URL-------", page);
            this.setState({
                activePage: page
            });
        }
    }
    routeTo = (path, page) => {
        this.setState({
            activePage: page
        });
        this.props.router.navigate(path)
    }
    render() {
        return (
            <div className="app-sidebar sidebar-shadow">
                <div className="app-header__logo">
                    {/* <div className="logo-src"></div> */}
                    <div className="header__pane ml-auto">
                        <div>
                            <button type="button" className="hamburger close-sidebar-btn hamburger--elastic closed-sidebar">
                                <span className="hamburger-box">
                                    <span className="hamburger-inner"></span>
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="app-header__mobile-menu">
                    <div>
                        <button type="button" className="hamburger hamburger--elastic mobile-toggle-nav">
                            <span className="hamburger-box">
                                <span className="hamburger-inner"></span>
                            </span>
                        </button>
                    </div>
                </div>
                <div className="app-header__menu">
                    <span>
                        <button type="button" className="btn-icon btn-icon-only btn btn-primary btn-sm mobile-toggle-header-nav">
                            <span className="btn-icon-wrapper">
                                <i className="fa fa-ellipsis-v fa-w-6"></i>
                            </span>
                        </button>
                    </span>
                </div>
                <div className="scrollbar-sidebar d-flex flex-column">
                    <div className="app-sidebar__inner">
                        <ul className="vertical-nav-menu">
                            <li className="app-sidebar__heading">MENU</li>
                            <li>
                                <a onClick={() => { this.routeTo('/', 'dashboard') }} className={`${this.state.activePage === 'dashboard' ? 'mm-active' : ''} sub-text cursor-pointer`}>
                                    <i className=" metismenu-icon drop-down-icn"><img alt='' src={SIDEBAR.DASHBOARD} className="metismenu-icon drop-down-icn sidebar-icon" /></i>
                                    Dashboard
                                </a>
                            </li>

                            <li>
                                <a href="/">
                                    <i className=" metismenu-icon drop-down-icn">
                                        <img alt='' src={SIDEBAR.USERS} className="fa fa-home metismenu-icon drop-down-icn sidebar-icon" />
                                    </i>
                                    USERS
                                    <i className="drop-down-icn metismenu-state-icon icon-arrow-down caret-left"></i>
                                </a>
                                <ul>
                                    <li>
                                        <a className={`${this.state.activePage === 'users' ? 'mm-active' : ''} sub-text cursor-pointer`} onClick={() => { this.routeTo('/user/users', 'users') }}>
                                            <i className="metismenu-icon">
                                            </i>Users
                                        </a>
                                    </li>
                                    {
                                        Role() === 'OWNER' ?
                                            <li>
                                                <a className={`${this.state.activePage === 'admins' ? 'mm-active' : ''} sub-text cursor-pointer`} onClick={() => { this.routeTo('/user/admins', 'admins') }}>
                                                    <i className="metismenu-icon"></i>
                                                    Admins
                                                </a>
                                            </li>
                                            : null
                                    }

                                    <li>
                                        <a className={`${this.state.activePage === 'clans' ? 'mm-active' : ''} sub-text cursor-pointer`} onClick={() => { this.routeTo('/user/clans', 'clans') }}>
                                            <i className="metismenu-icon">
                                            </i>Clans
                                        </a>
                                    </li>
                                    {/* <li>
                                        <a className={`${this.state.activePage === 'teams' ? 'mm-active' : ''} sub-text cursor-pointer`} onClick={() => { this.routeTo('/user/teams', 'teams') }}>
                                            <i className="metismenu-icon">
                                            </i>Teams
                                        </a>
                                    </li> */}
                                    <li>
                                        <a className={`${this.state.activePage === 'organisations' ? 'mm-active' : ''} sub-text cursor-pointer`} onClick={() => { this.routeTo('/user/organisations', 'organisations') }}>
                                            <i className="metismenu-icon">
                                            </i>Organisations
                                        </a>
                                    </li>
                                    <li>
                                        <a className={`${this.state.activePage === 'block-ban-users' ? 'mm-active' : ''} sub-text cursor-pointer`} onClick={() => { this.routeTo('/user/block-ban-users', 'block-ban-users') }}>
                                            <i className="metismenu-icon">
                                            </i>Block/Ban Users
                                        </a>
                                    </li>
                                    <li>
                                        <a className={`${this.state.activePage === 'block-ban-To' ? 'mm-active' : ''} sub-text cursor-pointer`} onClick={() => { this.routeTo('/user/block-ban-To', 'block-ban-To') }}>
                                            <i className="metismenu-icon">
                                            </i>Block/Ban TO
                                        </a>
                                    </li>
                                  
                                    <li>
                                        <a className={`${this.state.activePage === 'block-ban-teams' ? 'mm-active' : ''} sub-text cursor-pointer`} onClick={() => { this.routeTo('/user/block-ban-teams', 'block-ban-teams') }}>
                                            <i className="metismenu-icon">
                                            </i>Block/Ban Teams
                                        </a>
                                    </li>
                                    <li>
                                        <a className={`${this.state.activePage === 'block-ban-brands' ? 'mm-active' : ''} sub-text cursor-pointer`} onClick={() => { this.routeTo('/user/block-ban-brands', 'block-ban-brands') }}>
                                            <i className="metismenu-icon">
                                            </i>Block/Ban Brands
                                        </a>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <a href="/">
                                    <i className=" metismenu-icon drop-down-icn">
                                        <img alt='' src={SIDEBAR.VERIFICATION} className="fa fa-home metismenu-icon drop-down-icn sidebar-icon" />
                                    </i>
                                    VERIFICATION
                                    <i className="drop-down-icn metismenu-state-icon icon-arrow-down caret-left"></i>
                                </a>
                                <ul>
                                    <li>
                                        <a className={`${this.state.activePage === 'user-verification' ? 'mm-active' : ''} sub-text cursor-pointer`} onClick={() => { this.routeTo('/verification/user-verification', 'user-verification') }} >
                                            <i className="metismenu-icon">
                                            </i>All Users
                                        </a>
                                    </li>
                                    <li>
                                        <a className={`${this.state.activePage === 'clan-verification' ? 'mm-active' : ''} sub-text cursor-pointer`} onClick={() => { this.routeTo('/verification/clan-verification', 'clan-verification') }}>
                                            <i className="metismenu-icon"></i>
                                            Clans
                                        </a>
                                    </li>
                                    <li>
                                        <a className={`${this.state.activePage === 'organization-verification' ? 'mm-active' : ''} sub-text cursor-pointer`} onClick={() => { this.routeTo('/verification/organization-verification', 'organization-verification') }}>
                                            <i className="metismenu-icon">
                                            </i>Organization
                                        </a>
                                    </li>
                                    <li>
                                        <a className={`${this.state.activePage === 'verification-criteria' ? 'mm-active' : ''} sub-text cursor-pointer`} onClick={() => { this.routeTo('/verification/verification-criteria', 'verification-criteria') }}>
                                            <i className="metismenu-icon">
                                            </i>Manage Criteria
                                        </a>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <a href="/" className={`${this.state.activePage === 'games' ? 'mm-active' : ''} `}>
                                    <i className=" metismenu-icon drop-down-icn">
                                        <img alt='' src={SIDEBAR.GAMES} className="fa fa-home metismenu-icon drop-down-icn sidebar-icon" />
                                    </i>
                                    GAMES
                                    <i className="drop-down-icn metismenu-state-icon icon-arrow-down caret-left"></i>
                                </a>
                                <ul>
                                    <li>
                                        <a className={`${this.state.activePage === 'all-games' ? 'mm-active' : ''} sub-text cursor-pointer`} onClick={() => { this.routeTo('/games/all-games', 'all-games') }} >
                                            <i className="metismenu-icon">
                                            </i>All Games
                                        </a>
                                    </li>
                                    <li>
                                        <a className={`${this.state.activePage === 'point-matrix' ? 'mm-active' : ''} sub-text cursor-pointer`} onClick={() => { this.routeTo('/games/point-matrix', 'point-matrix') }}>
                                            <i className="metismenu-icon"></i>
                                            Point Matrix
                                        </a>
                                    </li>
                                    <li>
                                        <a className={`${this.state.activePage === 'betting' ? 'mm-active' : ''} sub-text cursor-pointer`} onClick={() => { this.routeTo('/games/betting', 'betting') }}>
                                            <i className="metismenu-icon">
                                            </i>Betting
                                        </a>
                                    </li>
                                    <li>
                                        <a className={`${this.state.activePage === 'entry-fees' ? 'mm-active' : ''} sub-text cursor-pointer`} onClick={() => { this.routeTo('/games/entry-fees', 'entry-fees') }}>
                                            <i className="metismenu-icon">
                                            </i>Entry Fees
                                        </a>
                                    </li>
                                    <li>
                                        <a className={`${this.state.activePage === 'price-pool' ? 'mm-active' : ''} sub-text cursor-pointer`} onClick={() => { this.routeTo('/games/price-pool', 'price-pool') }}>
                                            <i className="metismenu-icon">
                                            </i>Price Pool
                                        </a>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <a href="/">
                                    <i className=" metismenu-icon drop-down-icn">
                                        <img alt='' src={SIDEBAR.WEBSITE} className="fa fa-home metismenu-icon drop-down-icn sidebar-icon" />
                                    </i>
                                    WEBSITE
                                    <i className="drop-down-icn metismenu-state-icon icon-arrow-down caret-left"></i>
                                </a>
                                <ul>
                                    <li>
                                        <a className={`${this.state.activePage === 'landing-page' ? 'mm-active' : ''} sub-text cursor-pointer`} onClick={() => { this.routeTo('/website/landing-page', 'landing-page') }} >
                                            <i className="metismenu-icon">
                                            </i>Landing Page
                                        </a>
                                    </li>
                                    <li>
                                        <a className={`${this.state.activePage === 'information-text' ? 'mm-active' : ''} sub-text cursor-pointer`} onClick={() => { this.routeTo('/website/information-text', 'information-text') }}>
                                            <i className="metismenu-icon"></i>
                                            Information Text
                                        </a>
                                    </li>
                                    <li>
                                        <a className={`${this.state.activePage === 'static-pages' ? 'mm-active' : ''} sub-text cursor-pointer`} onClick={() => { this.routeTo('/website/static-pages', 'static-pages') }}>
                                            <i className="metismenu-icon">
                                            </i>Static Pages
                                        </a>
                                    </li>
                                    <li>
                                        <a className={`${this.state.activePage === 'news' ? 'mm-active' : ''} sub-text cursor-pointer`} onClick={() => { this.routeTo('/website/news', 'news') }}>
                                            <i className="metismenu-icon">
                                            </i>News
                                        </a>
                                    </li>
                                    <li>
                                        <a className={`${this.state.activePage === 'contact-media' ? 'mm-active' : ''} sub-text cursor-pointer`} onClick={() => { this.routeTo('/website/contact-media', 'contact-media') }}>
                                            <i className="metismenu-icon">
                                            </i>Contact Us
                                        </a>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <a href="/">
                                    <i className=" metismenu-icon drop-down-icn">
                                        <img alt='' src={SIDEBAR.WALLET} className="fa fa-home metismenu-icon drop-down-icn sidebar-icon" />
                                    </i>
                                    WALLET
                                    <i className="drop-down-icn metismenu-state-icon icon-arrow-down caret-left"></i>
                                </a>
                                <ul>
                                    <li>
                                        <a className={`${this.state.activePage === 'tournament-transaction' ? 'mm-active' : ''} sub-text cursor-pointer`} onClick={() => { this.routeTo('/wallet/tournament-transaction', 'tournament-transaction') }} >
                                            <i className="metismenu-icon">
                                            </i>Tournament Transaction
                                        </a>
                                    </li>
                                    {/* <li>
                                        <a className={`${this.state.activePage === 'information-text' ? 'mm-active' : ''} sub-text cursor-pointer`} onClick={() => { this.routeTo('/wallet/information-text', 'information-text') }}>
                                            <i className="metismenu-icon"></i>
                                            Tournament Winners
                                        </a>
                                    </li>
                                    <li>
                                        <a className={`${this.state.activePage === 'static-pages' ? 'mm-active' : ''} sub-text cursor-pointer`} onClick={() => { this.routeTo('/wallet/static-pages', 'static-pages') }}>
                                            <i className="metismenu-icon">
                                            </i>Betting Winners
                                        </a>
                                    </li> */}
                                    <li>
                                        <a className={`${this.state.activePage === 'ec-deposite-withdrawls' ? 'mm-active' : ''} sub-text cursor-pointer`} onClick={() => { this.routeTo('/wallet/ec-deposite-withdrawls', 'ec-deposite-withdrawls') }}>
                                            <i className="metismenu-icon">
                                            </i>EC Deposite Withdrawls
                                        </a>
                                    </li>
                                    <li>
                                        <a className={`${this.state.activePage === 'account-payments' ? 'mm-active' : ''} sub-text cursor-pointer`} onClick={() => { this.routeTo('/wallet/account-payments', 'account-payments') }}>
                                            <i className="metismenu-icon">
                                            </i>Account Payments
                                        </a>
                                    </li>
                                </ul>
                            </li>

                            <li>
                                <a onClick={() => { this.routeTo('/commission', 'commission') }} className={`${this.state.activePage === 'commission' ? 'mm-active' : ''} sub-text cursor-pointer`}>
                                    <i className=" metismenu-icon drop-down-icn">
                                        <img alt='' src={SIDEBAR.COMMISSION} className="fa fa-home metismenu-icon drop-down-icn sidebar-icon" />
                                    </i>
                                    COMMISSION
                                </a>
                            </li>
                            <li>
                                <a href="/">
                                    <i className=" metismenu-icon drop-down-icn">
                                        <img alt='' src={SIDEBAR.NOTIFICATIONS} className="fa fa-home metismenu-icon drop-down-icn sidebar-icon" />
                                    </i>
                                    NOTIFIATIONS
                                    <i className="drop-down-icn metismenu-state-icon icon-arrow-down caret-left"></i>
                                </a>
                                <ul>
                                    <li>
                                        <a className={`${this.state.activePage === 'users-notifications' ? 'mm-active' : ''} sub-text cursor-pointer`} onClick={() => { this.routeTo('/notifications/users-notifications', 'users-notifications') }} >
                                            <i className="metismenu-icon">
                                            </i>Users
                                        </a>
                                    </li>
                                    <li>
                                        <a className={`${this.state.activePage === 'clan-notifications' ? 'mm-active' : ''} sub-text cursor-pointer`} onClick={() => { this.routeTo('/notifications/clan-notifications', 'clan-notifications') }}>
                                            <i className="metismenu-icon"></i>
                                            Clans
                                        </a>
                                    </li>
                                    <li>
                                        <a className={`${this.state.activePage === 'team-notifications' ? 'mm-active' : ''} sub-text cursor-pointer`} onClick={() => { this.routeTo('/notifications/team-notifications', 'team-notifications') }}>
                                            <i className="metismenu-icon">
                                            </i>Teams
                                        </a>
                                    </li>
                                    <li>
                                        <a className={`${this.state.activePage === 'organisation-notifications' ? 'mm-active' : ''} sub-text cursor-pointer`} onClick={() => { this.routeTo('/notifications/organisation-notifications', 'organisation-notifications') }}>
                                            <i className="metismenu-icon">
                                            </i>Organisations
                                        </a>
                                    </li>
                                    <li>
                                        <a className={`${this.state.activePage === 'tournament-notifications' ? 'mm-active' : ''} sub-text cursor-pointer`} onClick={() => { this.routeTo('/notifications/tournament-notifications', 'tournament-notifications') }}>
                                            <i className="metismenu-icon">
                                            </i>Tournament
                                        </a>
                                    </li>
                                    <li>
                                        <a className={`${this.state.activePage === 'betting-notifications' ? 'mm-active' : ''} sub-text cursor-pointer`} onClick={() => { this.routeTo('/notifications/betting-notifications', 'betting-notifications') }}>
                                            <i className="metismenu-icon">
                                            </i>Betting
                                        </a>
                                    </li>
                                    <li>
                                        <a className={`${this.state.activePage === 'den-notifications' ? 'mm-active' : ''} sub-text cursor-pointer`} onClick={() => { this.routeTo('/notifications/den-notifications', 'den-notifications') }}>
                                            <i className="metismenu-icon">
                                            </i>Den
                                        </a>
                                    </li>
                                    <li>
                                        <a className={`${this.state.activePage === 'wallet-notifications' ? 'mm-active' : ''} sub-text cursor-pointer`} onClick={() => { this.routeTo('/notifications/wallet-notifications', 'wallet-notifications') }}>
                                            <i className="metismenu-icon">
                                            </i>Wallet
                                        </a>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <a onClick={() => { this.routeTo('/feature-request', 'feature-request') }} className={`${this.state.activePage === 'feature-request' ? 'mm-active' : ''} sub-text cursor-pointer`}>
                                    <i className=" metismenu-icon drop-down-icn">
                                        <img alt='' src={SIDEBAR.REQUESTS} className="fa fa-home metismenu-icon drop-down-icn sidebar-icon" />
                                    </i>
                                    FEATURE REQUEST
                                </a>
                            </li>

                            <li>
                                <a onClick={() => { this.routeTo('/bug-report', 'bug-report') }} className={`${this.state.activePage === 'bug-report' ? 'mm-active' : ''} sub-text cursor-pointer`}>
                                    <i className=" metismenu-icon drop-down-icn">
                                        <img alt='' src={SIDEBAR.BUG} className="fa fa-home metismenu-icon drop-down-icn sidebar-icon" />
                                    </i>
                                    BUG REPORT
                                </a>
                            </li>
                            <li>
                                <a onClick={() => { this.routeTo('/tournament-cancellation', 'tournament-cancellation') }} className={`${this.state.activePage === '/tournament-cancellation' ? 'mm-active' : ''} sub-text cursor-pointer`}>
                                    <i className=" metismenu-icon drop-down-icn">
                                        <img alt='' src={SIDEBAR.CANCELLATION} className="fa fa-home metismenu-icon drop-down-icn sidebar-icon" />
                                    </i>
                                    TOURNAMENT CANCEL
                                </a>
                            </li>
                            <li>
                                <a onClick={() => { this.routeTo('/forms', 'forms') }} className={`${this.state.activePage === 'forms' ? 'mm-active' : ''} sub-text cursor-pointer`}>
                                    <i className=" metismenu-icon drop-down-icn">
                                        <img alt='' src={SIDEBAR.FORMS} className="fa fa-home metismenu-icon drop-down-icn sidebar-icon" />
                                    </i>
                                    FORM
                                </a>
                            </li>
                            <li>
                                <a href="/">
                                    <i className=" metismenu-icon drop-down-icn">
                                        <img alt='' src={SIDEBAR.BETTING} className="fa fa-home metismenu-icon drop-down-icn sidebar-icon" />
                                    </i>
                                    BETTING
                                    <i className="drop-down-icn metismenu-state-icon icon-arrow-down caret-left"></i>
                                </a>
                                <ul>
                                    <li>
                                        <a className={`${this.state.activePage === 'games-betting' ? 'mm-active' : ''} sub-text cursor-pointer`} onClick={() => { this.routeTo('/betting/games-betting', 'games-betting') }} >
                                            <i className="metismenu-icon">
                                            </i>All Games
                                        </a>
                                    </li>
                                    <li>
                                        <a className={`${this.state.activePage === 'betting-statements' ? 'mm-active' : ''} sub-text cursor-pointer`} onClick={() => { this.routeTo('/betting/betting-statements', 'betting-statements') }}>
                                            <i className="metismenu-icon"></i>
                                            Betting Statements
                                        </a>
                                    </li>
                                    <li>
                                        <a className={`${this.state.activePage === 'betting-criteria' ? 'mm-active' : ''} sub-text cursor-pointer`} onClick={() => { this.routeTo('/betting/betting-criteria', 'betting-criteria') }}>
                                            <i className="metismenu-icon">
                                            </i>Betting Criteria
                                        </a>
                                    </li>
                                    <li>
                                        <a className={`${this.state.activePage === 'betting-organisation' ? 'mm-active' : ''} sub-text cursor-pointer`} onClick={() => { this.routeTo('/betting/betting-organisation', 'betting-organisation') }}>
                                            <i className="metismenu-icon">
                                            </i>Organisation
                                        </a>
                                    </li>
                                    <li>
                                        <a className={`${this.state.activePage === 'betting-data' ? 'mm-active' : ''} sub-text cursor-pointer`} onClick={() => { this.routeTo('/betting/betting-data', 'betting-data') }}>
                                            <i className="metismenu-icon">
                                            </i>Betting Data
                                        </a>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <a onClick={() => { this.routeTo('/Ec-currency', 'Ec-currency') }} className={`${this.state.activePage === 'Ec-currency' ? 'mm-active' : ''} sub-text cursor-pointer`}>
                                    <i className=" metismenu-icon drop-down-icn">
                                        <img alt='' src={SIDEBAR.CURRENCY} className="fa fa-home metismenu-icon drop-down-icn sidebar-icon" />
                                    </i>
                                    EC CURRENCY
                                </a>
                            </li>
                            <li>
                                <a href="/">
                                    <i className=" metismenu-icon drop-down-icn">
                                        <img alt='' src={SIDEBAR.RULES} className="fa fa-home metismenu-icon drop-down-icn sidebar-icon" />
                                    </i>
                                    RULES
                                    <i className="drop-down-icn metismenu-state-icon icon-arrow-down caret-left"></i>
                                </a>
                                <ul>
                                    <li>
                                        <a className={`${this.state.activePage === 'landing-rules' ? 'mm-active' : ''} sub-text cursor-pointer`} onClick={() => { this.routeTo('/rules/landing-rules', 'landing-rules') }} >
                                            <i className="metismenu-icon">
                                            </i>Landing Page
                                        </a>
                                    </li>
                                    <li>
                                        <a className={`${this.state.activePage === 'games-rules' ? 'mm-active' : ''} sub-text cursor-pointer`} onClick={() => { this.routeTo('/rules/games-rules', 'games-rules') }}>
                                            <i className="metismenu-icon"></i>
                                            Games
                                        </a>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <a onClick={() => { this.routeTo('/terms-conditions', 'terms-conditions') }} className={`${this.state.activePage === 'terms-conditions' ? 'mm-active' : ''} sub-text cursor-pointer`}>
                                    <i className=" metismenu-icon drop-down-icn">
                                        <img alt='' src={SIDEBAR.CONDITIONS} className="fa fa-home metismenu-icon drop-down-icn sidebar-icon" />
                                    </i>
                                    TERMS AND CONDITIONS
                                </a>
                            </li>
                            <li>
                                <a href="/">
                                    <i className=" metismenu-icon drop-down-icn">
                                        <img alt='' src={SIDEBAR.DEN} className="fa fa-home metismenu-icon drop-down-icn sidebar-icon" />
                                    </i>
                                    DEN
                                    <i className="drop-down-icn metismenu-state-icon icon-arrow-down caret-left"></i>
                                </a>
                                <ul>
                                    <li>
                                        <a className={`${this.state.activePage === 'den-star' ? 'mm-active' : ''} sub-text cursor-pointer`} onClick={() => { this.routeTo('/den/den-star', 'den-star') }} >
                                            <i className="metismenu-icon">
                                            </i>Den Star
                                        </a>
                                    </li>
                                    <li>
                                        <a className={`${this.state.activePage === 'spotlight' ? 'mm-active' : ''} sub-text cursor-pointer`} onClick={() => { this.routeTo('/den/spotlight', 'spotlight') }}>
                                            <i className="metismenu-icon"></i>
                                            Spotlight
                                        </a>
                                    </li>
                                    <li>
                                        <a className={`${this.state.activePage === 'spin-wheel' ? 'mm-active' : ''} sub-text cursor-pointer`} onClick={() => { this.routeTo('/den/spin-wheel', 'spin-wheel') }}>
                                            <i className="metismenu-icon"></i>
                                            Spin Wheel
                                        </a>
                                    </li>
                                    <li>
                                        <a className={`${this.state.activePage === 'joining-interaction-bonus' ? 'mm-active' : ''} sub-text cursor-pointer`} onClick={() => { this.routeTo('/den/joining-interaction-bonus', 'joining-interaction-bonus') }}>
                                            <i className="metismenu-icon"></i>
                                            Joining Interaction Bonus
                                        </a>
                                    </li>
                                    <li>
                                        <a className={`${this.state.activePage === 'Achievement-rank-title' ? 'mm-active' : ''} sub-text cursor-pointer`} onClick={() => { this.routeTo('/den/Achievement-rank-title', 'Achievement-rank-title') }}>
                                            <i className="metismenu-icon"></i>
                                            Achievement Rank Title
                                        </a>
                                    </li>
                                    <li>
                                        <a className={`${this.state.activePage === 'Den-profile-rank-title' ? 'mm-active' : ''} sub-text cursor-pointer`} onClick={() => { this.routeTo('/den/Den-profile-rank-title', 'Den-profile-rank-title') }}>
                                            <i className="metismenu-icon"></i>
                                            Den Profile Rank Title
                                        </a>
                                    </li>
                                    <li>
                                        <a className={`${this.state.activePage === 'Achievement-rank-criteria' ? 'mm-active' : ''} sub-text cursor-pointer`} onClick={() => { this.routeTo('/den/Achievement-rank-criteria', 'Achievement-rank-criteria') }}>
                                            <i className="metismenu-icon"></i>
                                            Achievement Rank Criteria
                                        </a>
                                    </li>
                                    <li>
                                        <a className={`${this.state.activePage === 'den-profile-criteria' ? 'mm-active' : ''} sub-text cursor-pointer`} onClick={() => { this.routeTo('/den/den-profile-criteria', 'den-profile-criteria') }}>
                                            <i className="metismenu-icon"></i>
                                            Den Profile Criteria
                                        </a>
                                    </li>
                                    <li>
                                        <a className={`${this.state.activePage === 'hashtags' ? 'mm-active' : ''} sub-text cursor-pointer`} onClick={() => { this.routeTo('/den/hashtags', 'hashtags') }}>
                                            <i className="metismenu-icon"></i>
                                            Hashtags
                                        </a>
                                    </li>
                                    <li>
                                        <a className={`${this.state.activePage === 'reported-posts' ? 'mm-active' : ''} sub-text cursor-pointer`} onClick={() => { this.routeTo('/den/reported-posts', 'reported-posts') }}>
                                            <i className="metismenu-icon"></i>
                                            Reported Posts
                                        </a>
                                    </li>
                                </ul>
                            </li>
                        </ul>

                    </div>
                    
                    <h5 className='text-center sub-text mt-auto opacity-25'>V.{packageJson.version}</h5>
                </div>
            </div>
        );
    }
}
export default withRouter(Sidebar)