import React, { Component } from 'react';
import {  LineChart, PieChart } from 'react-chartkick'
import 'chartkick/chart.js'
import { MONTS } from '../../utils/strings.utils';

export default class LineCharts extends Component {

  getDateFromMonth = (month)=> {
    var date = new Date();
    var currentMonth = date.getMonth()+1;
    var selectedMonth = MONTS.indexOf(month)+1;
    var year = currentMonth >= selectedMonth ? date.getFullYear() : date.getFullYear()-1;
    return `${year}-${selectedMonth}-1`;
  }

  render() {
    const value = this.props.dashboardDetails?.betScoreCount[0];
   
    var data = [{"name":"Bet Won", "data": {}},{"name":"Bet Loss", "data": {}}];
    
    Object.keys(value).map((key,i)=>{
    
      data[0].data[this.getDateFromMonth((key).toUpperCase())] =value[key].won ;
      data[1].data[this.getDateFromMonth((key).toUpperCase())] =  value[key].lose ;
   
    });
  
    return (
        <LineChart data={data}  download={{background: "#331c54"}}/>    
    )
  }
}
