import { toast } from 'material-react-toastify';
import React, { Component } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { approveOrRejectPayment } from '../../utils/common.utils';

export default class ApproveOrRejectPayment extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isApproval: props.isApproval,
            paymentDtails: props.paymentDtails,
            amount: 0,
            message: '',
            walletInfo: props.walletInfo
        }
    }

    componentWillReceiveProps(newProps){
        this.setState({
            isApproval: newProps.isApproval,
            paymentDtails: newProps.paymentDtails,
            walletInfo: newProps.walletInfo
        });
    }

    onChange = (key, val) => {
        if(key === 'amount'){
            if(val>=0 && val<100000){
                this.setState({ amount: val })
            }else{
               toast.warn("Amount should be less greater than 0 and less than 100000.");
            }
        }else{
            this.setState({ message: val })
        }
    }

    approveOrRejectPayment = (isApproved) =>{
        approveOrRejectPayment(this.state.paymentDtails._id, isApproved, { amount: this.state.amount}, (result)=>{
            console.log("result :: ",result);
            this.props.onClose();
            this.props.refreshList(); 
        });
    }

    

    render() {
        console.log("###### paymentDtails :: ",this.state.amount)
        if(this.state.isApproval){
            return (
                <div className='modal-content' style={{border:'none', borderRadius:'0'}}>
                    <h3 className='text-center text-white fw-bold'>Approve Payment</h3>
                    <div className='my-2 text-center text-white fw-500'>
                        {this.props.message}
                    </div>
                    <div className='my-2'>
                        <label><span className='text-white font-bold'>Accepted Credit Amount</span></label>
                        <div className="input-group">
                            <input type="number" 
                                className="form-control transperent-box light-border-around text-gray"
                                placeholder='Enter field title' 
                                aria-label="Recipient's username" aria-describedby="basic-addon2"
                                style={{borderRadius:'10px'}}
                                value={this.state.amount}
                                onChange={(e)=>this.onChange('amount', e.target.value)}
                                min={0} max={100000} step={1} onWheel={(e) => e.target.blur()}
                            />
                        </div>
                    </div>
                    {this.state.amount > 0 &&
                       <div className='d-flex my-2'>
                            <p className='fs-12 text-white mx-auto my-0' style={{width:'85%'}}>{(this.state.amount/100)*(100-this.props.walletInfo.ESPOTZ_COMMMISSION_ON_DIEW)} will be credited to the respective wallet after deduction {this.props.walletInfo?.ESPOTZ_COMMMISSION_ON_DIEW}% commission.</p>
                        </div>
                    }
                    <div className='d-flex justify-content-center my-3'>
                        <Button  className="btn btn-primary fw-bold me-1" onClick={()=>this.approveOrRejectPayment(true)}>
                            Approve
                        </Button>
                        <Button onClick={() => { this.props.onClose() }} className="btn-primary fw-bold me-1">
                            Close
                        </Button>
                    </div>
                </div>
            )
        }else{
            return (
                <div className='modal-content' style={{border:'none', borderRadius:'0'}}>
                    <h3 className='text-center text-white fw-bold'>Reject Payment</h3>
                    <div className='my-2 text-center text-white fw-500'>
                        {this.props.message}
                    </div>
                    <div className='my-2'>
                        <label><span className='text-white font-bold'>Rejected Credit Amount</span></label>
                        <div className="input-group">
                            <input type="number" 
                                className="form-control transperent-box light-border-around text-gray"
                                placeholder='Enter field title' 
                                aria-label="Recipient's username" aria-describedby="basic-addon2"
                                style={{borderRadius:'10px'}}
                                value={this.state.amount}
                                onChange={(e)=>this.onChange('amount', e.target.value)}
                            />
                        </div>
                    </div>
                    

                    <div className='d-flex justify-content-center my-3'>
                        <Button  className="btn btn-primary fw-bold me-1" onClick={()=>this.approveOrRejectPayment(false)}>
                            Reject
                        </Button>
                        <Button onClick={() => { this.props.onClose() }} className="btn-primary fw-bold me-1">
                            Close
                        </Button>
                    </div>
                </div>
            )
        }
      
    }
}

