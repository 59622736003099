import { Component } from 'react'
import { Card, Modal } from 'react-bootstrap';
import { AiOutlineClose } from "react-icons/ai";
import { Link } from 'react-router-dom';
import { BREADCRUMBS } from '../../utils/breadcrumbs.utils';
import { getBugsReport, deleteBugReport, routeTo } from '../../utils/common.utils';
import Confirmation from '../common/Confirmation';
import defaultPreviewImage from '../../assets/images/imgs/1.jpg';
import ImagePreview from '../common/ImagePreview';

export default class BugReport extends Component {

  constructor(props) {
    super(props);
    this.state = {
      bugsReport: [],
      deleteId: null,
      openConfirmationModal : false,
      previewImageDetails: {
        display: false,
        src: defaultPreviewImage
      },
    }
    getBugsReport(this.setFeatureRequest)
  }
  componentDidMount(){}

  setFeatureRequest = (data) => { 
    //console.log('setFeatureRequest ::', data);
    this.setState({bugsReport: data});
  }

  setDeleteIndex = (id) => { 
    this.setState({ 
      openConfirmationModal: true,
      deleteId: id 
    });
  }

  resetDeleteIndex = () => { 
    this.setState({
      openConfirmationModal: false,
      deleteId: null
    })
  }

  deleteReason = () => { 
    deleteBugReport(this.state.deleteId, this.setFeatureRequest);
    this.resetDeleteIndex()
  }

  previewImage = (src, param) => {
    this.setState({
      previewImageDetails: {
        display: param,
        src: (param ? src : defaultPreviewImage)
      }
    });
  }

  render() {
    return (
      <div className="app-main__inner">
        {/* HEAD */}
        <div className="d-flex flex-column flex-lg-row">
            {/* MAIN-HEAD */}
            <div className="flex-grow-1">
                <h3 className='text-light-white'>{BREADCRUMBS.BUG_REPORT.HEADING}</h3>
            </div>
            {/* BRAD-CRUMB */}
            <div className="">
                <ol className="breadcrumb color-white" style={{ background: 'none', padding: '0px' }}>
                    {BREADCRUMBS.BUG_REPORT.PATH.map((route,i)=>{
                        return <li key={i} className="breadcrumb-item cursor-pointer" onClick={() => { routeTo('/','dashboard')  }}>{route}</li>
                    })}
                </ol>
            </div>
        </div>
        <div className="row">
          {
            this.state.bugsReport?.length ? 
            this.state.bugsReport.map((bugDetails,i) => { 
              return (
                <div className="col-12 col-md-6 col-lg-4" key={i}>
                  <Card style={{ background: '#6259CA'}}>
                    <Card.Body className='p-3'>
                      <div className='row'>
                        <div className='col-10'><p className='feature-card-title my-0'>{bugDetails.bug}</p></div>
                        <div className='col-2 text-end'> <AiOutlineClose className='cursor-pointer' style={{height:'20px', width:'20px', color:'white'}} onClick={() => this.setDeleteIndex(bugDetails._id)} /></div>
                      </div>
                      <Card.Text className='feature-card-text my-2'>
                        {bugDetails.description}
                      </Card.Text>
  
                      {bugDetails?.images?.length ?
                        <div>
                          <p className='feature-card-title my-0' style={{fontSize:'16px'}}>Screenshots</p>
                          {bugDetails.images?.map((image, i) => {
                            return <img src={image.url} alt='' className='cursor-pointer me-2' style={{height:'30px'}} key={i} onClick={() => this.previewImage((image.url), true)} />
                          })}
                        </div>
                        :
                        null
                      }
                      <Card.Text>
                        -<span className='fs-12 fw-bold text-warning'>{bugDetails.email}</span>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>
              )
            })
            :
            <h4 className='text-white-50'>No Bug Reports Found</h4>
          }
        </div>
        <Modal show={this.state.openConfirmationModal} centered dialogClassName=''>
          <Modal.Body>
            <Confirmation
              message={`Are you sure you want to remove this bug ?`}
              onConfirm={this.deleteReason} onClose={this.resetDeleteIndex}
            />
          </Modal.Body>
        </Modal>
        <Modal show={this.state.previewImageDetails.display} centered dialogClassName="image-preview-container bg-transparent">
          <ImagePreview previewImageDetails={this.state.previewImageDetails} previewImage={this.previewImage} />
        </Modal>  
      </div>
    )
  }
}
