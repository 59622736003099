import * as axios from "axios";
import { token } from "../utils/common.utils";

export default class WalletService {
    constructor() {
        this.client = null;
        this.api_token = null;
        this.api_url = process.env.REACT_APP_API_URL;
    }
    queryParams(params) {
        return Object.keys(params)
            .map(key => `${key}=${params[key]}`)
            .join('&');
    }
    init = () => {
        this.api_token = token();
        let headers = {
            Accept: "application/json",
        };

        if (this.api_token) {
            headers.Authorization = this.api_token;
        }

        this.client = axios.create({
            baseURL: this.api_url,
            timeout: 31000,
            headers: headers,
        });

        return this.client;
    };
    getAllTournament = (params) => {
        const searchString = this.queryParams(params)
        return this.init().get(`/admin/api/v1/wallet/getTournaments?${searchString}`);
    };
    getMatchWinners = (params) => {
        const searchString = this.queryParams(params)
        return this.init().get(`/admin/api/v1/wallet/getMatchWinners?${searchString}`);
    };
    getBetWinners = (params) => {
        const searchString = this.queryParams(params)
        return this.init().get(`/admin/api/v1/wallet/getBetWinners?${searchString}`);
    };
    getAllTransaction = (params) => {
        const searchString = this.queryParams(params)
        return this.init().get(`/admin/api/v1/wallet/getTransaction?${searchString}`);
    };

    getWalletPayments = (type, status, params) => {
        console.log("type ",type)
        const searchString = this.queryParams(params)
        return this.init().get(`/admin/api/v1/wallet/${type}/${status}/getWalletPayments?${searchString}`);
    }

    approveOrRejectPayment = (paymentId, isApprove, data) => {
        return this.init().put(`/admin/api/v1/wallet/approveOrRejectPayment/${paymentId}/${isApprove}`,data);
    };
}