import React, { Component } from 'react'
import { Outlet } from 'react-router-dom'
//import Footer from './Footer'
import Header from './Header'
import Sidebar from './Sidebar'

export default class Parent extends Component {
    render() {
        return (
            <div className="app-container app-theme-white body-tabs-shadow fixed-sidebar fixed-header">
                <Header />
                <div className="app-main">
                    <Sidebar />
                    <div className="app-main__outer">
                        <Outlet />
                        {/* <Footer /> */}
                    </div>
                </div>
            </div>
        )
    }
}
