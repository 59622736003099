import React, { Component } from 'react';
import { HEADING, POINTS_RANK, DEN } from '../../utils/strings.utils';
import { getBettingDetails, updateBettingDetails, routeTo } from '../../utils/common.utils';
import { BREADCRUMBS } from '../../utils/breadcrumbs.utils';
import {  BETTING } from '../../utils/strings.utils';
import "../../assets/css/switch.css";
import "../../assets/css/game.css";
import { toast } from 'material-react-toastify';

export default class BettingCriteria extends Component {
  
  constructor(props) {
    super(props);
    this.state = { 
      denDetails : null,
      selectedChoice : 0,
      editLabel : ''
    };
  }

  componentDidMount(){
    getBettingDetails(this.setBettingDetails);
  }

  setBettingDetails = (response, key) =>{
    let denDetails = this.state.denDetails;
    if(response){
      if(key){
        denDetails[0] = response
      }else{
        denDetails = response
      }
      this.setState({
        denDetails: denDetails,
        editLabel: ''
      })
    }
  }

  changeValue = (val) =>{
    const {editLabel, denDetails} = this.state;
    //console.log('old denDetails', denDetails)
    denDetails[0][editLabel] = val;
    //console.log('updated denDetails', denDetails);
    this.setState({ denDetails: denDetails })
  }

  changeCriteria = (val) =>{
    const id = this.state.denDetails[0]?._id;
    const data = { 'verficationRequired': val};
    updateBettingDetails(id, data, this.setBettingDetails);
  }

  updateData = (val) => {
    const id = this.state.denDetails[0]?._id;
    console.log('------ id', this.state.denDetails[0]?._id);
    console.log(`editLabel-${this.state.editLabel} && val-${val}`);
    const data = { [this.state.editLabel]: this.state.denDetails[0][this.state.editLabel]};
    console.log('data', data);
    //this.setState({editLabel : ''})
    updateBettingDetails(id, data, this.setBettingDetails);
  }

  updateEditLabel = (label) => {
    console.log('Label ::', label);
    this.setState({editLabel : label})
  }

  render() {
    const changeValue = this.changeValue;
    const { denDetails, selectedChoice} = this.state;
    
    if(this.state.denDetails){
      //console.log('Questions ::', this.state.denDetails[0]?.questions)
      return (
        <div className="app-main__inner">
            {/* HEAD */}
            <div className="d-flex flex-column flex-lg-row">
                {/* MAIN-HEAD */}
                <div className="flex-grow-1">
                    <h3 className='text-light-white'>{BREADCRUMBS.BETTING_CRITERIA.HEADING}</h3>
                </div>
                {/* BRAD-CRUMB */}
                <div className="">
                    <ol className="breadcrumb color-white" style={{ background: 'none', padding: '0px' }}>
                        {BREADCRUMBS.BETTING_CRITERIA.PATH.map((route,i)=>{
                            return <li key={i} className="breadcrumb-item cursor-pointer" onClick={() => { routeTo('/','dashboard')  }}>{route}</li>
                        })}
                    </ol>
                </div>
            </div>
            {/* BODY */}
            <div className="row">
            <div className="col-md-12">
              <div className="main-card mb-3 card transperent-card text-white p-4">
                  
              {BETTING.BETTING_CRITERIA.map((question,i) => {
                //console.log('question ::', question);verficationRequired
                if(question.key !== 'verficationRequired'){
                  return (
                    <div className='col-12  my-2' key={i}>
                      <label><span className=' font-bold'>{question.title}</span></label>
                    
                      <div className="input-group">
                        <input type="number"
                          onChange={(e)=>changeValue(question.key === 'averageRating' ? e.target.value : parseInt(e.target.value))}
                          value={this.state.denDetails[selectedChoice][question.key]}
                          placeholder={'Enter a number'}
                          className="form-control transperent-box light-border-around text-gray" 
                          aria-label="Recipient's username" aria-describedby="basic-addon2"
                          style={{borderTopLeftRadius:'10px', borderBottomLeftRadius: '10px'}}
                          disabled={this.state.editLabel === question.key ? false : true}
                          min={0} max={question.key === 'averageRating' ? 5 : 10000} step={question.key === 'averageRating' ? 0.1 : 1} onWheel={(e) => e.target.blur()}
                        />
                        <div className="input-group-append">
                          <button className="btn edt-btn light-border-around" type="button" style={{borderTopRightRadius:'10px', borderBottomRightRadius: '10px'}}
                            onClick={(e) => {this.state.editLabel === question.key ? this.updateData(e.target.value) : this.updateEditLabel(question.key)}}
                          >
                            {this.state.editLabel === question.key ? 'Save' : 'Edit'} 
                          </button>
                        </div>
                      </div>
                    </div>
                  )
                  }
              })}
              <div className='col-12  my-2'>
                <label><span className=' font-bold'>Verification required</span></label>
              
                <div className="input-group">
                  
                  <select
                    onChange={(e)=>this.changeCriteria(e.target.value)}
                    value={this.state.denDetails[selectedChoice]['verficationRequired']}
                    placeholder={'Enter a number'}
                    className="form-control transperent-box light-border-around text-gray" 
                    aria-label="Recipient's username" aria-describedby="basic-addon2"
                    style={{borderRadius:'10px'}}
                  >
                    <option value={true} className="form-control transperent-box light-border-around text-gray" >Yes</option>
                    <option value={false} className="form-control transperent-box light-border-around text-gray" >No</option> 
                  </select>
                  
                </div>
              </div>
              </div>
              </div>
            </div>
        </div>
      );
    }else{
      return null;
    }
   
}
}

