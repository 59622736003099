import DataTable, { createTheme } from 'react-data-table-component';

createTheme('solarized', {
    text: {
        primary: '#fff',
        secondary: '#fff',
    },
    background: {
        default: 'transperent',
    },
    context: {
        background: 'transperent',
        text: '#FFFFFF',
    },
    divider: {
        default: '#073642',
    },
    action: {
        button: '#fff',
     
        disabled: '#fff',
    },
}, 'light');

const conditionalRowStyles = [
    {
        when: row => (row.index % 2) == 0,
        style: {
            backgroundColor: 'rgb(218 213 213 / 5%)',
            color: 'white',
            '&:hover': {
                cursor: 'pointer',
                backgroundColor:'transperent'
            },
        },
    },
    {
        when: row => (row.index % 2) != 0,
        style: {
            backgroundColor: 'rgba(0, 0, 0, 0.05)',
            color: 'white',
            '&:hover': {
                cursor: 'pointer',
                backgroundColor:'transperent'
            },
        },
    }
]

// const ExpandedComponent = ({ data }) => {
//     console.log('data ::::', data);
//     return <pre className='text-white'>{JSON.stringify(data, null, 2)}</pre>
// };

export function TableComponent(props) {
    return (
        <DataTable
            theme="solarized"
            columns={props.column}
            data={props.data}
            responsive={true}
            noHeader
            defaultSortField="id"
            defaultSortAsc={false}
            striped={true}
            center={true}
            persistTableHead
            pagination
            paginationServer={true}
            conditionalRowStyles={conditionalRowStyles}
            paginationTotalRows={props.paginationTotalRows}
            onChangePage={(e) => { props.onPagination((e - 1), 'pageNo') }}
            onChangeRowsPerPage={(e) => { props.onPagination(e, 'limit') }}
            highlightOnHover
            //expandableRows
            //expandableRowsComponent={ExpandedComponent}
        />
    );
};