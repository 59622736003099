import { Component } from 'react'
import AuthService from '../../services/auth.service';
import Button from 'react-bootstrap/Button';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { errorHandler, updateLoader } from '../../utils/common.utils';
import { toast } from 'material-react-toastify';

export default class AddAdmin extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }

        this.authApi = new AuthService();
    }

    onAdminAdd = (values) => {
        updateLoader(true)
        this.authApi.createAdmin(values).then(
            (res) => {
                this.props.getAdmins()
                updateLoader(false)
                toast.success('Admin added successfully')
            }
        ).catch(
            (err) => {
                console.error(err)
                updateLoader(false)
                errorHandler(err)
            }
        )
    }
    render() {
        return (
            <div>
                <div className='text-center'>
                    <h3>Add Admin</h3>
                </div>
                <Formik
                    enableReinitialize
                    initialValues={{
                        email: '',
                        name: '',
                    }}
                    validationSchema={Yup.object({
                        email: Yup.string().required("Email is required"),
                        name: Yup.string().required("Name is required"),
                    })}
                    onSubmit={(values, { setSubmitting }) => {
                        setTimeout(() => {
                            this.onAdminAdd(values)
                        }, 400);
                    }}
                >
                    <Form className="validate-form">
                        <div className="wrap-input100 validate-input">
                            <label>Name</label>
                            <Field
                                className="w-100 p-2"
                                type="text"
                                name="name"
                                placeholder="Name"
                            />

                            <ErrorMessage component="span" className="error-msg" name="name" />
                        </div>
                        <div className="wrap-input100 validate-input">
                            <label>Email</label>
                            <Field
                                className="w-100 p-2"
                                type="text"
                                name="email"
                                placeholder="Email"
                            />

                            <ErrorMessage component="span" className="error-msg" name="email" />
                        </div>
                        <div className='mt-4'>
                            <Button type='submit' variant="secondary" className="me-1">
                                Add
                            </Button>
                            <Button onClick={this.props.close} className="me-1" variant="success">
                                Close
                            </Button>
                        </div>
                    </Form>
                </Formik>
            </div>
        )
    }
}
