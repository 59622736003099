import React, { Component } from 'react';
import { WEBSITE, PLACEHOLDER } from '../../utils/strings.utils';
import { getDenHashTagDetails, routeTo, addDenHashTagDetails, updateDenHashTagDetails, deleteDenHashTagDetails } from '../../utils/common.utils';
import { BREADCRUMBS } from '../../utils/breadcrumbs.utils';
import "../../assets/css/switch.css";
import "../../assets/css/game.css";
import { toast } from 'material-react-toastify';
import { Button, Dropdown, Modal } from 'react-bootstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { AiOutlineClose } from "react-icons/ai";
import { hasWhiteSpace } from '../../utils/validation.utils';

export default class Hashtags extends Component {
  
  constructor(props) {
    super(props);
    this.state = { 
      denDetails : null,
      selectedChoice : 0,
      editIndex : -1,
      HASHTAG: [
        {QUESTION: 'Hashtag 1', ANSWER: ''}
      ],
      openModal : false,
      gameDetails: null,
      hashtags:[],
      selectedGameIndex:0,
      editedValue: null
    };
  }

  componentDidMount(){
    getDenHashTagDetails("",this.setDenHashTagDetails);
  }

  setDenHashTagDetails = (hashtags) => {
    this.setState({hashtags: hashtags })
  }

  toggleModalStatus = () => {
    this.setState({ openModal : !this.state.openModal})
  }

  onSubmit = (values) => {
    
    if(hasWhiteSpace(values.hashtag)){
      toast.warning("Please remove space from given hashtag.")
    }else{
    
      var hashTagDetails =  this.state.hashtags;
      const data = {
        gameId: hashTagDetails[parseInt(values.game)].gameDetails._id,
        tag: values.hashtag
      }

      addDenHashTagDetails(data, (result)=>{
        hashTagDetails[parseInt(values.game)].HASHTAG =  result.HASHTAG;
        this.updateHashtagsDetails(hashTagDetails); 
      })
    }
  }

  onUpdate = (gameId, editIndex, tag) =>{

    if(tag?.length>0){
      var hashTagDetails =  this.state.hashtags;
      updateDenHashTagDetails(gameId,editIndex,{tag: tag}, (result)=>{
        hashTagDetails[this.state.selectedGameIndex].HASHTAG =  result.HASHTAG;
        this.updateHashtagsDetails(hashTagDetails);   
      });
    }else{
      toast.warning("Please enter a valid tag...")
    }
  }

  onDelete = (gameId, deleteIndex) => {
    var hashTagDetails =  this.state.hashtags;
    deleteDenHashTagDetails(gameId,deleteIndex, (result)=>{
      hashTagDetails[this.state.selectedGameIndex].HASHTAG =  result.HASHTAG;
      this.updateHashtagsDetails(hashTagDetails);   
    });
  }

  updateHashtagsDetails = (hashTagDetails) =>{
    this.setState({
      hashtags: hashTagDetails,
      editIndex: -1,
      editedValue: null,
      openModal: false
    })
  }

  render() {
//    const changeValue = this.changeValue;
    const { editIndex , editedValue, selectedGameIndex, hashtags} = this.state;
    
    console.log(" editedValue : ",editedValue)

    if(hashtags.length > 0){
      //console.log('Questions ::', this.state.denDetails[0]?.questions)
      return (
        <div className="app-main__inner">
            {/* HEAD */}
            <div className="d-flex flex-column flex-lg-row">
                {/* MAIN-HEAD */}
                <div className="flex-grow-1">
                    <h3 className='text-light-white'>{BREADCRUMBS.HASHTAGS.HEADING}</h3>
                </div>
                {/* BRAD-CRUMB */}
                <div className="">
                    <ol className="breadcrumb color-white" style={{ background: 'none', padding: '0px' }}>
                        {BREADCRUMBS.HASHTAGS.PATH.map((route,i)=>{
                            return <li key={i} className="breadcrumb-item cursor-pointer" onClick={() => { routeTo('/','dashboard')  }}>{route}</li>
                        })}
                    </ol>
                </div>
            </div>
            {/* BODY */}
            <div className="row">
            <div className="col-md-12">
              <div className="main-card mb-3 card transperent-card text-white p-4">
                
                {/* SUB-HEAD */}
                <div className="card-header no-background p-0 mb-2 ">  {BREADCRUMBS.HASHTAGS.SUB_HEADING} {selectedGameIndex !== null ? "For "+this.state.hashtags[selectedGameIndex].gameDetails.name : ""}
                  {/* FILTER */}
                  <div className="btn-actions-pane-right">
                    <Dropdown>
                      <Dropdown.Toggle variant="success" className='transperent-box light-border-around text-white' id="dropdown-basic">
                        <b className='px-5'>{selectedGameIndex !== null ? this.state.hashtags[selectedGameIndex].gameDetails.name : "ALL"}</b>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {/* <Dropdown.Item  onClick={()=>this.setState({selectedGameIndex : null})}>
                          <b className='px-4'>ALL</b> 
                        </Dropdown.Item> */}
                        {this.state.hashtags?.map((hashtag,i)=>{
                          if(selectedGameIndex !== i){
                            return (
                              <Dropdown.Item key={i} onClick={()=>this.setState({selectedGameIndex : i,editIndex: -1})}>
                                <b className='px-4'>{hashtag.gameDetails.name}</b> 
                              </Dropdown.Item>
                            )
                          }
                        })}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
              </div>

              <div className="container">
                <div className="row">

                  {hashtags[selectedGameIndex].HASHTAG.map((tag,i) => {

                    console.log("tag :: ",tag.ANSWER)
                    return (
                      <div className='my-2 col-md-6 col-12' key={i}>
                        <label><span className=' font-bold'>Hashtag #{i+1}</span></label>
                      
                        <div className="input-group">
                          
                          
                          {editIndex === i ?
                            <div className="input-group">
                              <input type="text"
                                id={hashtags[selectedGameIndex]._id} 
                                className="form-control transperent-box light-border-around text-gray"
                                style={{borderTopLeftRadius:'10px', borderBottomLeftRadius: '10px'}}
                                defaultValue={tag.ANSWER}
                                placeholder={PLACEHOLDER.HASHTAG}
                                autoComplete="off"
                                onChange={(e)=>this.setState({editedValue : e.target.value})} 
                              />

                              <div className="input-group-append ">
                                <button onClick={()=>this.onUpdate(this.state.hashtags[selectedGameIndex]._id,editIndex,editedValue )} className="btn btn-primary light-border-around fw-bold" type="button">Save</button>
                                <button onClick={()=>this.setState({editIndex: -1})} className="btn btn-primary light-border-around fw-bold rounded-border-right" type="button">Cancel</button>
                              </div>
                            </div>
                          :
                            <div className="input-group">
                              <input type="text"
                                  id={hashtags[selectedGameIndex]._id} 
                                  className="form-control transperent-box light-border-around text-gray"
                                  style={{borderTopLeftRadius:'10px', borderBottomLeftRadius: '10px'}}
                                  value={tag.ANSWER}
                                  placeholder={PLACEHOLDER.HASHTAG}
                                  autoComplete="off"
                                  readOnly={true}
                              />

                              <div className="input-group-append ">
                                <button onClick={()=>this.setState({editIndex: i})} className="btn btn-primary light-border-around fw-bold" type="button">Edit</button>
                                <button onClick={()=>this.onDelete(this.state.hashtags[selectedGameIndex]._id,i)} className="btn btn-primary light-border-around fw-bold rounded-border-right" type="button">Delete</button>
                              </div>

                             </div>
                          }
                          
                            
                          
                          
                        </div>
                      </div>
                    )
                  })}
                  
                </div>
                                  

                {/* ADD MORE FIELDS */}
                <div className='d-flex '>
                  <div className="my-2" >
                    <button type="button" onClick={()=>this.setState({openModal: true})} className="btn btn-primary">+ {WEBSITE.DEN.HASHTAG.ADD_MORE}</button>
                  </div>
                </div>

                
              </div>
             
              </div>
              </div>
            </div>
            <Modal show={this.state.openModal} centered dialogClassName=''>
              <Modal.Body>
                <div className="">
                  <div className="row">
                    <div className="col-2"></div>
                    <div className="col-8"><h5 className='text-center text-white fw-bold mb-0'>Add new hashtag</h5></div>
                    <div className="col-2 text-end"><AiOutlineClose className="cursor-pointer text-white" onClick={this.toggleModalStatus} /></div>
                  </div>
                  <Formik
                      enableReinitialize
                      initialValues={{
                          game: this.state.selectedGameIndex,// !== null ? hashtags[this.state.selectedGameIndex].gameDetails._id : hashtags[0].gameDetails._id ,
                          hashtag: ''
                      }}
                      validationSchema={Yup.object({
                          game: Yup.string().required('Game is Required'),
                          hashtag: Yup.string().required('Hashtag is Required').max(50, 'Max 50 characters').matches(/^[a-zA-Z0-9]*$/, 'Special characters and spaces are not allowed.'),
                      })}
                      onSubmit={(values, { setSubmitting }) => {
                          setTimeout(() => {
                              this.onSubmit(values)
                          }, 400);
                      }}
                  >
                      <Form className=" d-flex flex-column" autoComplete="off">
                          <div className="my-1">
                              <label htmlFor="game" className="fs-16 fw-bold text-white mb-1">Select Game</label>
                              <div className=""> 
                
                                  <Field name="game" as="select" disabled className="form-control transperent-box light-border-around text-gray" style={{borderRadius:'10px'}}>
                                      {
                                          this.state.hashtags?.map((hashtag,i)=>{
                                              return (
                                                  <option key={i} value={i} className="text-white bg-dark">{hashtag.gameDetails.name}</option>
                                              )
                                          })
                                      }
                                  </Field>
                              </div>
                              <ErrorMessage component="span" className="error-msg" name="game" />
                          </div>

                          <div className="my-1">
                              <label htmlFor="hashtag" className="fs-16 fw-600 text-white mb-1">Enter Hashtag</label>
                              <Field
                                name="hashtag" type="text"
                                className="form-control transperent-box light-border-around text-gray"
                                placeholder="Enter Hashtag"  style={{borderRadius:'10px'}} />
                              <ErrorMessage component="span" className="error-msg" name="hashtag" />
                          </div>

                          <Button className="mx-auto my-3" type="submit">Create</Button>
                      </Form>
                  </Formik>
                </div>
              </Modal.Body>
            </Modal>
        </div>
      );
    }else{
      return null;
    }
   
}
}



