import React, { Component } from 'react';
import { HEADING, POINTS_RANK, DEN } from '../../utils/strings.utils';
import { getDenDetails, updateDenDetails, routeTo } from '../../utils/common.utils';
import { BREADCRUMBS } from '../../utils/breadcrumbs.utils';
import { DEN_ENUM } from '../../utils/strings.utils';
import "../../assets/css/switch.css";
import "../../assets/css/game.css";
import { toast } from 'material-react-toastify';
import Dropdown from 'react-bootstrap/Dropdown';

export default class DenAchievementRankTitle extends Component {
  
  constructor(props) {
    super(props);
    this.state = { 
      denDetails : null,
      selectedChoice : 0,
      editIndex : -1,
      updateValue : null
    };
  }

  componentDidMount(){
    getDenDetails(`type=ACHIEVE_RANK_TITLE`,this.setDenDetails);
  }

  setDenDetails = (denDetails) =>{
    if(denDetails){
      //denDetails = denDetails.sort((a, b) => a.subType.toLowerCase().localeCompare(b.subType.toLowerCase()))
      this.setState({
        denDetails: denDetails,
        editIndex : -1,
        updateValue : null
      });
    }
  }

  onSelectOption = (index) =>{
    this.setState({
      selectedChoice : index,
      editIndex : -1,
    });
  }


  enableEditOption = (index, val) =>{
    this.setState({
      editIndex: index,
      updateValue : val
    });
  }

  changeValue = (val) =>{
    this.setState({
      updateValue: (val ? val : 0)
    });
  }

  updateWebsiteDetails = (key) => {

    console.log("--------- ID :: ",this.state.denDetails[this.state.selectedChoice]._id);
    let data = {};

    if(this.state.updateValue?.length){
      if(!this.state.updateValue.trim()){
        toast.warning('Achievement title is required');
        return false;
      }
    }else{
      toast.warning('Achievement title is required');
      return false;
    }
    data[key] = this.state.updateValue.trim()
    updateDenDetails(this.state.denDetails[this.state.selectedChoice]._id, data, this.setUpdatedDenDetails);
          
  }

  setUpdatedDenDetails = (response) => {
    const {denDetails, selectedChoice} = this.state;
    denDetails[selectedChoice] = response[0];
    this.setState({
      editIndex: -1,
      denDetails: denDetails,
      updateValue : null
      //selectedChoice: 0
    })
  }

  render() {
    const enableEditOption = this.enableEditOption;
    const changeValue = this.changeValue;
    const updateWebsiteDetails = this.updateWebsiteDetails;
    const {editIndex, denDetails, selectedChoice} = this.state;
    if(this.state.denDetails){
      //console.log('Questions ::', this.state.denDetails[0]?.questions)
      return (
        <div className="app-main__inner">
            {/* HEAD */}
            <div className="d-flex flex-column flex-lg-row">
                {/* MAIN-HEAD */}
                <div className="flex-grow-1">
                    <h3 className='text-light-white'>{BREADCRUMBS.ACHIEVE_RANK_TITLE.HEADING}</h3>
                </div>
                {/* BRAD-CRUMB */}
                <div className="">
                    <ol className="breadcrumb color-white" style={{ background: 'none', padding: '0px' }}>
                        {BREADCRUMBS.ACHIEVE_RANK_TITLE.PATH.map((route,i)=>{
                            return <li key={i} className="breadcrumb-item cursor-pointer" onClick={() => { routeTo('/','dashboard')  }}>{route}</li>
                        })}
                    </ol>
                </div>
            </div>
            {/* BODY */}
            <div className="row">
            <div className="col-md-12">
              <div className="main-card mb-3 card transperent-card text-white p-4">
                {/* FILTER */}
                <div className="btn-actions-pane-right">
                  <Dropdown>
                    <Dropdown.Toggle variant="success" className='transperent-box light-border-around text-white' id="dropdown-basic">
                      <b className='px-5'>{DEN_ENUM.ACHIEVE_RANK_TITLE[this.state.denDetails[this.state.selectedChoice].subType].NAME}</b>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {this.state.denDetails?.map((module,i)=>{
                        if(this.state.selectedChoice !== i){
                          return (
                            <Dropdown.Item key={i} onClick={()=>this.onSelectOption(i)}>
                              <b className='px-4'>{DEN_ENUM.ACHIEVE_RANK_TITLE[module.subType].NAME}</b> 
                            </Dropdown.Item>
                          )
                        }
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              {/* MAIN_BODY */}
              <div className='row'>
                {this.state.denDetails[this.state.selectedChoice]?.questions && Object.keys(this.state.denDetails[this.state.selectedChoice].questions).map(function(key, index) {

                    const selectedOption = denDetails[selectedChoice].subType;

                    if(DEN_ENUM.ACHIEVE_RANK_TITLE[selectedOption][key] === undefined) {
                      return null;
                    }else{
                      if(editIndex === index){
                        return (
                          <div className='col-12  my-2' key={key}>
                            <label><span className=' font-bold'>{DEN_ENUM.ACHIEVE_RANK_TITLE[selectedOption][key]}</span></label>
                          
                            <div className="input-group" >
                              <input type="text" 
                                id={key}
                                onChange={(e)=>changeValue(e.target.value)}
                                defaultValue={denDetails[selectedChoice].questions[key]}
                                placeholder={`Enter ${DEN_ENUM.ACHIEVE_RANK_TITLE[selectedOption][key]} `}
                                className="form-control transperent-box light-border-around text-gray" 
                                aria-label="Recipient's username" aria-describedby="basic-addon2"
                                style={{borderTopLeftRadius:'10px', borderBottomLeftRadius: '10px'}}
                              />
                              <div className="input-group-append">
                                <button className="btn edt-btn light-border-around" onClick={()=>updateWebsiteDetails(key)} type="button" style={{borderTopRightRadius:'10px', borderBottomRightRadius: '10px'}}>
                                  Save
                                </button>
                              </div>
                            </div>
                          </div>
                        )
                      }else{
                        return (
                          <div className='col-12  my-2' key={key}>
                            <label><span className=' font-bold'>{DEN_ENUM.ACHIEVE_RANK_TITLE[selectedOption][key]}</span></label>
                          
                            <div className="input-group" >
                              <input type="text" 
                                id={key}
                                placeholder={`Enter ${DEN_ENUM.ACHIEVE_RANK_TITLE[selectedOption][key]} `}
                                value={denDetails[selectedChoice].questions[key]}
                                readOnly
                                className="form-control transperent-box light-border-around text-gray" 
                                aria-label="Recipient's username" aria-describedby="basic-addon2"
                                style={{borderTopLeftRadius:'10px', borderBottomLeftRadius: '10px'}}
                              />
                              <div className="input-group-append">
                                <button className="btn edt-btn light-border-around" onClick={()=>enableEditOption(index, denDetails[selectedChoice].questions[key])}  type="button" style={{borderTopRightRadius:'10px', borderBottomRightRadius: '10px'}}>
                                  Edit
                                </button>
                              </div>
                            </div>
                          
                          </div>
                        )
                      }
                    }
                  })
                }
              
              </div>
              </div>
              </div>
            </div>
        </div>
      );
    }else{
      return null;
    }
   
  }
}



