import * as axios from "axios";
import { token } from "../utils/common.utils";

export default class VerificationService {
    constructor() {
        this.client = null;
        this.api_token = null;
        this.api_url = process.env.REACT_APP_API_URL;
    }
    queryParams(params) {
        return Object.keys(params)
            .map(key => `${key}=${params[key]}`)
            .join('&');
    }
    init = () => {
        this.api_token = token();
        let headers = {
            Accept: "application/json",
        };

        if (this.api_token) {
            headers.Authorization = this.api_token;
        }

        this.client = axios.create({
            baseURL: this.api_url,
            timeout: 31000,
            headers: headers,
        });

        return this.client;
    };
    updateClan = (id, data) => {
        return this.init().put(`/admin/api/v1/clan/updateClan/${id}`, data);
    };
    updateOrg = (id, data) => {
        return this.init().put(`/admin/api/v1/organization/updateOrganizations/${id}`, data);
    };
    updateUser = (id, data) => {
        return this.init().put(`/admin/api/v1/user/updateUser/${id}`, data);
    };
    updateTeam = (id, data) => {
        return this.init().put(`/admin/api/v1/team/updateTeams/${id}`, data);
    };
    getCriteria = () => {
        return this.init().get(`/admin/api/v1/verfication/getManageCretria`);
    };
    updateCriteria = (id, data) => {
        return this.init().put(`/admin/api/v1/verfication/updateManageCretria/${id}`, data);
    };
    getSponsor = (params) => {
        const searchString = this.queryParams(params)
        return this.init().get(`/admin/api/v1/sponsor/getSponsors?${searchString}`);
    };
    updateSponsor = (id, data) => {
        return this.init().put(`/admin/api/v1/sponsor/updateSponsor/${id}`, data);
    };
}
