import { Component } from 'react';

//import './App.css';
import './Add.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/style.css';
import MainRoutes from './routes/MainRoutes';
import { saveLoader } from './utils/common.utils';
import { ToastContainer } from 'material-react-toastify';
import 'material-react-toastify/dist/ReactToastify.css';
import Loader from './components/common/Loader';

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaderActive : 0
        }
    }

    componentDidMount() {
        console.log("loading js file..")
        const script = document.createElement("script");
        script.async = true;
        script.src = "https://demo.dashboardpack.com/architectui-html-free/assets/scripts/main.js";

        // For body
        document.body.appendChild(script);

        saveLoader(this.updateLoader);

    }

    //Set loader for whole project
    updateLoader = (param) => {
        var count = (param === true ? (this.state.isLoaderActive + 1) : (this.state.isLoaderActive - 1));
        count = ((count < 0) ? 0 : count);
        
        this.setState({
            isLoaderActive: count
        })
    }


    render() {
      
        return (
            <div className="App">
                {/* To display loader while fetching data from backend */}
                {((this.state.isLoaderActive > 0)) ? <div><Loader /> </div> : null}
                <MainRoutes />
                {/* To display Success, Warning or Error messages to user */}
                <div className="toast-message" >
                    <ToastContainer />
                </div>
            </div>
        );
    }
}


export default App;
