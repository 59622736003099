import { Component } from 'react'
import UserService from '../../services/user.service';
import { TableComponent } from '../common/DataTable';
import VerificationService from '../../services/verification.service';
import { BREADCRUMBS } from '../../utils/breadcrumbs.utils';
import { errorHandler, routeTo, updateLoader } from '../../utils/common.utils';
import Dropdown from 'react-bootstrap/Dropdown';
import { DebounceInput } from 'react-debounce-input';
import { dateFormat } from '../../utils/dateTime.utils';

const DROPDOWN_OPTIONS = [
  // { name: 'User id', term: 'userId' },
  { name: 'Name', term: 'name' },
  { name: 'AKA', term: 'username' },
  { name: 'Email', term: 'email' },
  { name: 'Discord', term: 'discordUsername' },
]
export default class UserVerification extends Component {
  constructor(props) {
    super(props)
    this.state = {
      users: [],
      totalResult: 0,
      params: {
        pageNo: 0,
        limit: 10,
        verificationStatus: "VERIFICATION_REQUESTED"
      },
      selectedIndex: 0,
    }
    this.authApi = new UserService();
    this.verificationApi = new VerificationService();
    this.columns = [
      // {
      //   name: "USER ID",
      //   selector: (row) => [row._id],
      //   sortable: false,
      // },
      {
        name: "NAME",
        selector: (row) => [row.name],
        sortable: false,
      },
      {
        name: "EMAIL",
        selector: (row) => [row.email],
        sortable: false,
      },
      {
        name: "AKA",
        selector: (row) => [row.username],
        sortable: false,
      },
      {
        name: "DEN",
        selector: (row) => [row.den ? 'Yes' : 'No'],
        sortable: false,
      },
      {
        name: "KYC",
        selector: (row) => [row.kycVerified ? 'Yes' : 'No'],
        sortable: false,
      },
      {
        name: "VERIFIED",
        selector: (row) => [(row.verificationStatus === 'VERIFIED') ? 'Yes' : 'No'],
        sortable: false,
      },
      {
        name: "DISCORD",
        selector: (row) => [row?.discordProfile?.discordUsername],
        sortable: false,
      },
      {
        name: "DATE",
        selector: (row) => [dateFormat(row.createdAt, true)],
        sortable: false,
      },
      {
        name: "TIME",
        selector: (row) => [dateFormat(row.createdAt, false)],
        sortable: false,
      },
      {
        cell: (row) => <span> <button className='mr-2 es-squre-success-btn' onClick={() => { this.action(row._id, 'VERIFIED') }} id={row.id}><i class="fa fa-check"></i></button>
          <button className='es-squre-danger-btn' onClick={() => { this.action(row._id, 'NOT_VERIFIED') }} id={row.id}><i class="fa fa-close"></i></button> </span>,
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
        name: 'ACTION'
      },
    ]
  }
  componentDidMount() {
    this.getUsers(this.state.params)
  }
  action = (id, status) => {
    console.log(id)
    let data = {
      verificationStatus: status
    }
    updateLoader(true)
    this.verificationApi.updateUser(id, data).then(
      (res) => {
        updateLoader(false)
        this.getUsers(this.state.params)
        // this.setState({ deleteUser: '' })
      }
    ).catch(
      (err) => {
        console.error(err)
        updateLoader(false)
        errorHandler(err)
      }
    )
  }
  onPagination = (i, key) => {
    let paramsTemp = this.state.params
    paramsTemp[key] = i
    this.setState({ params: paramsTemp })
    console.log(`${key}`, paramsTemp)
    this.getUsers(paramsTemp)
  }
  onSearch = (val) => {
    let params = {
      [DROPDOWN_OPTIONS[this.state.selectedIndex].term]: val,
      pageNo: 0,
      limit: this.state?.params?.limit ?this.state.params.limit : 10,
      verificationStatus: "VERIFICATION_REQUESTED"
    }
    if (!val) delete params[DROPDOWN_OPTIONS[this.state.selectedIndex].term];
    this.setState({ params: params })
    this.searchUsers(params)
  }
  getUsers = (params) => {
    updateLoader(true)
    this.authApi.getAllUsers(params).then(
      (res) => {
        let users = res.data.result.map((el, i) => { el.index = i; return el })
        this.setState({ users: users, totalResult: res.data.totalResults })
        updateLoader(false)
      }
    ).catch(
      (err) => {
        console.error(err)
        updateLoader(false)
        errorHandler(err)
      }
    )
  };

  searchUsers = (params) => {
    updateLoader(true)
    this.authApi.getAllUsers(params).then(
      (res) => {
        let users = res.data.result.map((el, i) => { el.index = i; return el })
        this.setState({ users: users, totalResult: res.data.totalResults })
        updateLoader(false)
      }
    ).catch(
      (err) => {
        console.error(err)
        updateLoader(false)
        errorHandler(err)
      }
    )
  };
  render() {
    return (
      <div className="app-main__inner">
        <div className="d-flex flex-column flex-lg-row">
          <div className="flex-grow-1">
            <h3 className='text-white'>{BREADCRUMBS.VERIFICATION_USER.HEADING}</h3>


          </div>
          <div className="">
            <ol className="breadcrumb color-white" style={{ background: 'none', padding: '0px' }}>
              {BREADCRUMBS.VERIFICATION_USER.PATH.map((route, i) => {
                return <li key={i} className="breadcrumb-item cursor-pointer" onClick={() => { routeTo('/', 'dashboard') }}>{route}</li>
              })}
            </ol>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="main-card mb-3 card transperent-card text-white p-4">
              <div className="card-header no-background p-0 mb-2">
                <div className="btn-actions-pane-right">
                  <div className="input-group icons search-box">
                    <Dropdown>
                      <Dropdown.Toggle variant="success" className='h-45 transperent-box light-border-around text-white' id="dropdown-basic">
                        <b className='px-5'>{DROPDOWN_OPTIONS[this.state.selectedIndex].name}</b>

                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {
                          DROPDOWN_OPTIONS.map((game, i) => {
                            return <Dropdown.Item key={i} onClick={() => this.setState({ selectedIndex: i })}>{game.name} </Dropdown.Item>
                          })
                        }
                      </Dropdown.Menu>
                    </Dropdown>
                    <DebounceInput minLength={3}
                      debounceTimeout={500}
                      type="search"
                      maxLength={100}
                      className="ml-2 form-control rounded text-white transperent-box light-border-around"
                      placeholder="Search in table ...."
                      onChange={(e) => { this.onSearch(e.target.value) }} />
                  </div>
                </div>
              </div>
              <div className='row'>
                <TableComponent column={this.columns} data={this.state.users} paginationTotalRows={this.state.totalResult} onPagination={this.onPagination} />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
