import { Component } from 'react'
import { BREADCRUMBS } from '../../utils/breadcrumbs.utils';
import { errorHandler, routeTo, updateLoader } from '../../utils/common.utils';
import { EC_CURRENCY } from '../../utils/strings.utils';
import Confirmation from '../common/Confirmation';
import { Button, Modal } from 'react-bootstrap'
import RulesService from '../../services/rules.service';
import Dropdown from 'react-bootstrap/Dropdown';
import { toast } from 'material-react-toastify';

const DROPDOWN_OPTIONS = [
  {
    name: 'Fortnite',
    enum: 'FORTNITE'
  },
  {
    name: 'League Of Legends',
    enum: 'LOL'
  },
  {
    name: 'Dota 2',
    enum: 'DOTA'
  },
  {
    name: 'CS:GO',
    enum: 'CSGO'
  },
  {
    name: 'PUBG',
    enum: 'PUBG'
  },
  {
    name: 'Valorant',
    enum: 'VALORANT'
  },
  {
    name: 'PUBG Mobile',
    enum: 'PUBG_MOBILE'
  },
  {
    name: 'Call Of Duty',
    enum: 'COD'
  },
  {
    name: 'FreeFire',
    enum: 'FREE_FIRE'
  },
  {
    name: 'Apex Legends',
    enum: 'APEX_LEGENDS'
  },
]
export default class GamesRules extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editId: '',
      rules: [],
      addModal: false,
      description: '',
      heading: '',
      rulesWithId: [],
      upateId: '',
      selectedIndex: 0,
      FORTNITE: [],
      LOL: [],
      DOTA: [],
      CSGO: [],
      PUBG: [],
      VALORANT: [],
      PUBG_MOBILE: [],
      COD: [],
      FREE_FIRE: [],
      APEX_LEGENDS: []
    }
    this.rulesApis = new RulesService();
  }

  componentDidMount() {
    this.getData()
  }
  getData = () => {
    updateLoader(true)
    this.rulesApis.getRules().then(
      (res) => {
        console.log(res.data.result)
        let FORTNITE, LOL, DOTA, CSGO, PUBG, VALORANT, PUBG_MOBILE, COD, FREE_FIRE, APEX_LEGENDS;
        for (let data of res.data.result) {
          console.log(data)
          switch (data?.game) {
            case 'Fortnite':
              FORTNITE = data.rule;
              continue;
            case 'League Of Legends':
              LOL = data.rule;
              continue;
            case 'Dota 2':
              DOTA = data.rule;
              continue;
            case 'CS:GO':
              CSGO = data.rule;
              continue;
            case 'PUBG':
              PUBG = data.rule;
              continue
            case 'Valorant':
              VALORANT = data.rule;
              continue
            case 'PUBG Mobile':
              PUBG_MOBILE = data.rule;
              continue
            case 'Call Of Duty':
              COD = data.rule;
              continue
            case 'FreeFire':
              FREE_FIRE = data.rule;
              continue
            case 'Apex Legends':
              APEX_LEGENDS = data.rule;
              continue
            default:
              continue;
          }
        }
        console.log(FORTNITE)
        this.setState({
          FORTNITE: FORTNITE,
          LOL: LOL,
          DOTA: DOTA,
          CSGO: CSGO,
          PUBG: PUBG,
          VALORANT: VALORANT,
          PUBG_MOBILE: PUBG_MOBILE,
          COD: COD,
          FREE_FIRE: FREE_FIRE,
          APEX_LEGENDS: APEX_LEGENDS,
          addModal: false,
          rulesWithId: res.data.result,
        })
        updateLoader(false)
      }
    ).catch(
      (err) => {
        console.error(err)
        updateLoader(false)
        errorHandler(err)
      }
    )
  }
  updateRule = () => {
    let id = this.state.rulesWithId[this.state.rulesWithId.map(el => { return el.game }).indexOf(DROPDOWN_OPTIONS[this.state.selectedIndex].name)]._id
    let data = {
      rule: this.state[DROPDOWN_OPTIONS[this.state.selectedIndex].enum]
    }
    updateLoader(true)
    this.rulesApis.updateRules(id, data).then(
      (res) => {
        console.log(res.data.result)
        this.setState({
          addModal: false,
          description: '',
          heading: '',
          editId: ''
        })
        updateLoader(false)
        toast.success('Rules updated successfully')
      }
    ).catch(
      (err) => {
        console.error(err)
        updateLoader(false)
        errorHandler(err)
      }
    )
  }
  addRule = () => {
    let data = {
      heading: this.state.heading,
      description: this.state.description
    }
    let rules = this.state[DROPDOWN_OPTIONS[this.state.selectedIndex].enum]
    if (this.state.editId) {
      rules[(this.state.editId - 1)] = data;
    } else {
      rules.push(data)
    }
    this.setState({
      addModal: false,
      [DROPDOWN_OPTIONS[this.state.selectedIndex].enum]: rules,
      description: '',
      heading: '',
      editId: ''
    })
  }
  deleteRule = (i) => {
    let data = this.state[DROPDOWN_OPTIONS[this.state.selectedIndex].enum]
    data.splice(i, 1)
    this.setState({ [DROPDOWN_OPTIONS[this.state.selectedIndex].enum]: data, editId: '' })
  }
  editRule = (i, el) => {
    console.log(`${i} - `, el)
    this.setState({ description: el.description, heading: el.heading, editId: i })
  }
  render() {
    return (
      <div className="app-main__inner">
        <div className="d-flex flex-column flex-lg-row">
          <div className="flex-grow-1">
            <h3 className='text-white'>{BREADCRUMBS.RULES_GAMES.HEADING}</h3>


          </div>
          <div className="">
            <ol className="breadcrumb color-white" style={{ background: 'none', padding: '0px' }}>
              {BREADCRUMBS.RULES_GAMES.PATH.map((route, i) => {
                return <li key={i} className="breadcrumb-item cursor-pointer" onClick={() => { routeTo('/', 'dashboard') }}>{route}</li>
              })}
            </ol>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="main-card mb-3 card transperent-card text-white p-4">
              <div className="card-header no-background p-0 mb-2 ">Set up rules
                <div className="btn-actions-pane-right">

                  <Dropdown>
                    <Dropdown.Toggle variant="success" className='transperent-box light-border-around text-white' id="dropdown-basic">
                      <b className='px-5'>{DROPDOWN_OPTIONS[this.state.selectedIndex].name}</b>

                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {
                        DROPDOWN_OPTIONS.map((game, i) => {
                          return <Dropdown.Item key={i} onClick={() => this.setState({ selectedIndex: i })}>{game.name} </Dropdown.Item>
                        })
                      }
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>

              <div className='row rules-border'>
                {
                  this.state[DROPDOWN_OPTIONS[this.state.selectedIndex].enum].map((el, i) => {
                    return (
                      <div key={i} className='col-12 col-md-12 my-2'>
                        <h4 style={{ color: '#fff' }}>{el?.heading} <span style={{ float: 'right' }}><span className='mr-2' onClick={() => { this.deleteRule(i) }}><i className="fa fa-trash" title=""></i></span><span onClick={() => { this.editRule((i + 1), el) }}><i className="fa fa-edit" title=""></i></span></span></h4>
                        <p>{el?.description}</p>
                      </div>
                    )
                  })
                }

              </div>
              <div>
                <button onClick={() => { this.setState({ addModal: true }) }} className="btn edt-btn light-border-around mr-2" type="button">Add New Rule</button>
                <button onClick={this.updateRule} className="btn edt-btn light-border-around" type="button">Save</button>
              </div>
            </div>
          </div>
        </div>
        <Modal show={this.state.addModal || this.state.editId} centered dialogClassName=''>
          <Modal.Body>
            <div className='modal-content' style={{ border: 'none', borderRadius: '0' }}>
              <h3 className='text-center text-white fw-bold'>{this.state.editId ? 'Update' : 'Add'} Rule</h3>
              <div className='my-2 text-center text-white fw-500'>
                <div className="input-group mb-4">
                  <input type="text"
                    value={this.state.heading}
                    onChange={(e) => { this.setState({ heading: e.target.value }) }}
                    placeholder='Enter rule heading'
                    maxLength={200}
                    className="form-control transperent-box light-border-around text-gray"
                    aria-label="Recipient's username" aria-describedby="basic-addon2"
                  // style={{ borderTopLeftRadius: '10px', borderBottomLeftRadius: '10px' }}
                  />
                </div>
                <div className="input-group">
                  <textarea type="text"
                    value={this.state.description}
                    onChange={(e) => { this.setState({ description: e.target.value }) }}
                    placeholder='Enter rule'
                    rows={3}
                    maxLength={500}
                    className="form-control transperent-box light-border-around text-gray"
                    aria-label="Recipient's username" aria-describedby="basic-addon2"
                  // style={{ borderTopLeftRadius: '10px', borderBottomLeftRadius: '10px' }}
                  />
                </div>
              </div>
              <div className='d-flex justify-content-center my-3'>
                <Button disabled={!this.state.description} onClick={this.addRule} className="btn btn-primary fw-bold me-1">
                  {this.state.editId ? 'Update' : 'Add'}
                </Button>
                <Button onClick={() => { this.setState({ addModal: false, editId: '', description: '', heading: '' }) }} className="btn-primary fw-bold me-1">
                  Close
                </Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    )
  }
}
