import { Component } from 'react'
import { BREADCRUMBS } from '../../utils/breadcrumbs.utils';
import Dropdown from 'react-bootstrap/Dropdown';
import { errorHandler, routeTo, updateLoader } from '../../utils/common.utils';
import { MANAGE_CRITERIA_ENUM } from '../../utils/strings.utils';
import VerificationService from '../../services/verification.service';
import { toast } from 'material-react-toastify';

const DROPDOWN_OPTIONS = [
  { name: 'Clan', value: 'clan', enum: 'CLAN' },
  { name: 'Organisation', value: 'organisation', enum: 'ORGANISATION' },
  { name: 'User In Den', value: 'user', enum: 'USER' }
]

export default class ManageCriteriaVerification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedIndex: 0,
      editLabel: '',
      clan: {},
      user: {},
      organisation: {}
    }
    this.verificationApi = new VerificationService();

  }

  componentDidMount() {
    this.getCriteria()
  }

  getCriteria = () => {
    updateLoader(true)
    this.verificationApi.getCriteria().then(
      (res) => {
        //console.log(res.data.result)
        let clan, user, organisation = {};
        for (let data of res.data.result) {
          if (data.type === 'clan') {
            clan = data
          } else if (data.type === 'user') {
            user = data
          } else if (data.type === 'organisation') {
            organisation = data
          }
        }
        this.setState({
          clan: clan,
          user: user,
          organisation: organisation
        })
        updateLoader(false)
        // this.setState({ deleteUser: '' })
      }
    ).catch(
      (err) => {
        console.error(err)
        updateLoader(false)
        errorHandler(err)
      }
    )
  }

  changeValue = (val, key, maxValue, type) => {
    //console.log(`input value ::`, val);
    //console.log('maxValue ::', maxValue);
    if( val > maxValue){
      toast.error(`Maximum allowed value is ${maxValue}`);
      return false;
    }
    if ( (val >=0 && val <= maxValue) || isNaN(val) ) {
      if (type === 'clan') {
        console.log('----inside clan----');
        let clan = this.state.clan;
        clan[key] = val;
        this.setState({ clan: clan })
      } else if (type === 'organisation') {
        console.log('----inside organisation----');
        let organisation = this.state.organisation;
        organisation[key] = val;
        this.setState({ organisation: organisation })
      } else if (type === 'user') {
        console.log('----inside user----');
        let user = this.state.user;
        user[key] = val;
        this.setState({ user: user })
      }
    }
  }

  changeDropDownValue = (val, key, type) => {
    if (type === 'clan') {
      let clan = this.state.clan;
      clan[key] = val;
      this.setState({ clan: clan })
    } else if (type === 'organisation') {
      let organisation = this.state.organisation;
      organisation[key] = val;
      this.setState({ organisation: organisation })
    } else if (type === 'user') {
      let user = this.state.user;
      user[key] = val;
      this.setState({ user: user })
    }
  }

  onSubmit = (type) => {
    console.log('type ::', type);
    let data;
    let updateStatus = true;
    if (type === 'clan') {
      MANAGE_CRITERIA_ENUM.CLAN.map((el, i) => { 
        const inputValue = this.state.clan[el.key];
        console.log(`inputValue -- ${inputValue}`);
        if(isNaN(inputValue)){
          toast.warning('Required fields are missing');
          updateStatus = false;
          return false;
        }else{
          data = this.state.clan;
        }
      })
    } else if (type === 'organisation') {
      MANAGE_CRITERIA_ENUM.ORGANISATION.map((el, i) => { 
        
        const inputValue = this.state.organisation[el.key];
        console.log(`inputValue -- ${inputValue}`, inputValue==='');
        if(inputValue === '' || isNaN(inputValue)){
          toast.warning('Required fields are missing');
          updateStatus = false;
          return false;
        }else{
          data = this.state.organisation;
        }
      })
    } else if (type === 'user') {
      MANAGE_CRITERIA_ENUM.USER.map((el, i) => { 
        console.log(`${this.state.user[el.key]}`);
        const inputValue = this.state.user[el.key];
        if(isNaN(inputValue)){
          toast.warning('Required fields are missing');
          updateStatus = false;
          return false;
        }else{
          data = this.state.user;
        }
      })
    }
    //console.log(data)
    if(updateStatus){
    updateLoader(true)
      this.verificationApi.updateCriteria(data._id, data).then(
        (res) => {
          this.setState({ editLabel: '' })
          updateLoader(false);
          toast.success('Manage criteria updated successfully')
        }
      ).catch(
        (err) => {
          console.error(err)
          updateLoader(false)
          errorHandler(err)
        }
      )
    }
  }

  render() {
    return (
      <div className="app-main__inner">
        <div className="d-flex flex-column flex-lg-row">
          <div className="flex-grow-1">
            <h3 className='text-white'>{BREADCRUMBS.MANAGE_CRITERIA.HEADING}</h3>


          </div>
          <div className="">
            <ol className="breadcrumb color-white" style={{ background: 'none', padding: '0px' }}>
              {BREADCRUMBS.MANAGE_CRITERIA.PATH.map((route, i) => {
                return <li key={i} className="breadcrumb-item cursor-pointer" onClick={() => { routeTo('/', 'dashboard') }}>{route}</li>
              })}
            </ol>
          </div>
        </div>
        <form>
        <div className="row">
          <div className="col-md-12">
            <div className="main-card mb-3 card transperent-card text-white p-4">
              <div className="card-header no-background p-0 mb-2 ">Manage Criteria
                <div className="btn-actions-pane-right">

                  <Dropdown>
                    <Dropdown.Toggle variant="success" className='transperent-box light-border-around text-white' id="dropdown-basic">
                      <b className='px-5'>{DROPDOWN_OPTIONS[this.state.selectedIndex].name}</b>

                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {
                        DROPDOWN_OPTIONS.map((game, i) => {
                          return <Dropdown.Item key={i} onClick={() => this.setState({ selectedIndex: i, editLabel: '' })}>{game.name} </Dropdown.Item>
                        })
                      }
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>

              <div className='row'>
                {
                  MANAGE_CRITERIA_ENUM[DROPDOWN_OPTIONS[this.state.selectedIndex].enum].map((el, i) => {
                    //console.log('EL ::::', el);
                    return (
                      <div className='col-12 col-md-6 my-2'>
                        <label htmlFor="basic-url ps-1"><span className='font-bold'>{el?.title}</span></label>
                        <div className="input-group">

                          {el?.isDropdown ?
                            <Dropdown>
                              <Dropdown.Toggle disabled={this.state.editLabel !== el.key} variant="success" style={{ borderTopLeftRadius: '10px', borderBottomLeftRadius: '10px' }} className='form-control transperent-box light-border-around text-white' id="dropdown-basic">
                                <b className='px-5'>{this.state.organisation?.verfiedTagRequiredForBetting ? 'Yes' : 'No'}</b>

                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                <Dropdown.Item onClick={() => this.changeDropDownValue(true, 'verfiedTagRequiredForBetting', 'organisation')}>Yes </Dropdown.Item>
                                <Dropdown.Item onClick={() => this.changeDropDownValue(false, 'verfiedTagRequiredForBetting', 'organisation')}>No </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                            :
                            <input type="number"
                              onChange={(e) => this.changeValue(el.isDecimal ? e.target.value : parseInt(e.target.value), el.key, el?.maxValue, DROPDOWN_OPTIONS[this.state.selectedIndex].value)}
                              value={this.state[DROPDOWN_OPTIONS[this.state.selectedIndex].value][el.key]}
                              className="form-control transperent-box light-border-around text-gray"
                              aria-label="Recipient's username" aria-describedby="basic-addon2"
                              style={{ borderTopLeftRadius: '10px', borderBottomLeftRadius: '10px' }}
                              disabled={this.state.editLabel !== el.key}
                              min={el?.minValue} max={el?.maxValue} step={el?.isDecimal ? 0.1 : 1} onWheel={(e) => e.target.blur()}
                            />
                          }
                          <div className="input-group-append">
                            {
                              this.state.editLabel === el.key ?
                                <button onClick={() => { this.onSubmit(DROPDOWN_OPTIONS[this.state.selectedIndex].value) }} className="btn edt-btn light-border-around" type="button" style={{ borderTopRightRadius: '10px', borderBottomRightRadius: '10px' }}>
                                  save
                                </button> :
                                <button className="btn edt-btn light-border-around" onClick={() => this.setState({ editLabel: el.key })} type="button" style={{ borderTopRightRadius: '10px', borderBottomRightRadius: '10px' }}>
                                  Edit
                                </button>
                            }

                          </div>
                        </div>
                      </div>
                    )
                  })
                }

              </div>
            </div>
          </div>
        </div>
        </form>
      </div>
    )
  }
}
