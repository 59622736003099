import { Component } from 'react'
import { TableComponent } from '../common/DataTable';
import UserService from '../../services/user.service';
import { BREADCRUMBS } from '../../utils/breadcrumbs.utils';
import { convertDateTime, errorHandler, routeTo, updateLoader } from '../../utils/common.utils';
import VerificationService from '../../services/verification.service';
import { Button, Modal } from 'react-bootstrap'
import Confirmation from '../common/Confirmation';
import Dropdown from 'react-bootstrap/Dropdown';
import { DebounceInput } from 'react-debounce-input';

const DROPDOWN_OPTIONS = [
  // { name: 'User ID', term: 'userId' },
  { name: 'Name', term: 'name' },
  { name: 'AKA', term: 'username' },
  { name: 'Email', term: 'email' },
  { name: 'Discord', term: 'discordID' },
]
export default class BlockBanUsers extends Component {
  constructor(props) {
    super(props)
    this.state = {
      users: [],
      totalResult: 0,
      params: {
        pageNo: 0,
        limit: 10
      },
      selectedIndex: 0,
      action: null,
    }
    this.verificationApi = new VerificationService();
    this.authApi = new UserService();
    this.columns = [
      // {
      //   name: "USER ID",
      //   selector: (row) => [row._id],
      //   sortable: false,
      // },
      {
        name: "NAME",
        selector: (row) => [row.name],
        sortable: false,
      },
      {
        name: "EMAIL",
        selector: (row) => [row.email],
        sortable: false,
      },
      {
        name: "AKA",
        selector: (row) => [row.username],
        sortable: false,
      },
      {
        name: "PHONE",
        selector: (row) => [row.countryCode?.number ? `${row.countryCode.code}-${row.countryCode.number}` : 'NA'],
        sortable: true,
      },
      {
        name: "DEN",
        selector: (row) => [row.den ? 'Yes' : 'No'],
        sortable: false,
      },
      {
        name: "KYC",
        selector: (row) => [row.kycVerified ? 'Yes' : 'No'],
        sortable: false,
      },
      {
        name: "VERIFIED",
        selector: (row) => [(row.verificationStatus === 'VERIFIED') ? 'Yes' : 'No'],
        sortable: false,
      },
      {
        name: "DISCORD",
        selector: (row) => [row?.discordProfile?.discordUsername],
        sortable: false,
      },
      // {
      //   name: "CREATED AT",
      //   selector: (row) => [convertDateTime(row.createdAt)],
      //   sortable: false,
      // },
      {
        cell: (row) => <span> <span> <button className={`${row?.block ? 'es-squre-success-btn' : 'es-squre-primary-btn'} cursor-pointer`} onClick={() => { this.action(row._id, 'block', row?.block ? false : true) }} id={row.id}><i className={`${row?.block ? 'fa fa-circle' : 'fa fa-lock'}`} title=""></i></button> </span>
          <span> <button disabled={row?.ban} className='es-squre-danger-btn cursor-pointer' onClick={() => { this.action(row._id, 'ban', true) }} id={row.id}><i className="fa fa-ban" title=""></i></button> </span></span>,
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
        name: 'ACTION'
      },
    ]
  }

  componentDidMount() {
    this.getUsers(this.state.params)
  }

  action = (id, key, value) => {
    let msg = (key === 'ban') ? 'Are you sure, want to Ban this user?' : value ? 'Are you sure, want to Block this user?' : 'Are you sure, want to Unblock this user?'
    let data = {
      id: id,
      msg: msg,
      key: key,
      value: value
    }
    this.setState({ action: data })
  }

  performAction = () => {
    let data = {
      [this.state.action.key]: this.state.action.value
    }
    updateLoader(true)
    this.verificationApi.updateUser(this.state.action.id, data).then(
      (res) => {
        updateLoader(false)
        this.getUsers(this.state.params)
      }
    ).catch(
      (err) => {
        updateLoader(false)
        errorHandler(err)
        console.error(err)
      }
    )
  }

  onPagination = (i, key) => {
    let paramsTemp = this.state.params
    paramsTemp[key] = i
    this.setState({ params: paramsTemp })
    console.log(`${key}`, paramsTemp)
    this.getUsers(paramsTemp)
  }

  onSearch = (val) => {
    let params = {
      [DROPDOWN_OPTIONS[this.state.selectedIndex].term]: val,
      pageNo: 0,
      limit: this.state?.params?.limit ?this.state.params.limit : 10
    }
    if (!val) delete params[DROPDOWN_OPTIONS[this.state.selectedIndex].term];
    this.setState({ params: params })
    this.searchUsers(params)
  }
  
  getUsers = (params) => {
    updateLoader(true)
    this.authApi.getAllUsers(params).then(
      (res) => {
        let users = res.data.result.map((el, i) => { el.index = i; return el })
        this.setState({ users: users, totalResult: res.data.totalResults, action: null })
        console.log("res - ", res.data.result)
        updateLoader(false)
      }
    ).catch(
      (err) => {
        console.error(err)
        updateLoader(false)
        errorHandler(err)
      }
    )
  };

  searchUsers = (params) => {
    updateLoader(true)
    this.authApi.getAllUsers(params).then(
      (res) => {
        let users = res.data.result.map((el, i) => { el.index = i; return el })
        this.setState({ users: users, totalResult: res.data.totalResults, action: null })
        updateLoader(false)
      }
    ).catch(
      (err) => {
        console.error(err)
        errorHandler(err)
        updateLoader(false)
      }
    )
  };

  render() {
    return (
      <div className="app-main__inner">
        <div className="d-flex flex-column flex-lg-row">
          <div className="flex-grow-1">
            <h3 className='text-white'>{BREADCRUMBS.USERS_BLOCK_USER.HEADING}</h3>


          </div>
          <div className="">
            <ol className="breadcrumb color-white" style={{ background: 'none', padding: '0px' }}>
              {BREADCRUMBS.USERS_BLOCK_USER.PATH.map((route, i) => {
                return <li key={i} className="breadcrumb-item cursor-pointer" onClick={() => { routeTo('/', 'dashboard') }}>{route}</li>
              })}
            </ol>
          </div>
        </div>
        
        <div className="row my-2">
          <div className="col-6 col-md-3 my-1">
            <button className='es-squre-primary-btn'><i className='fa fa-lock' title=""></i></button>
            <label className='fw-bold mx-2'>BLOCK</label>
          </div>
          <div className="col-6 col-md-3 my-1">
            <button className='es-squre-success-btn'><i className='fa fa-circle' title=""></i></button>
            <label className='fw-bold mx-2'>UNBLOCK</label>
          </div>
          <div className="col-6 col-md-3 my-1">
            <button className='es-squre-danger-btn cursor-pointer'><i className="fa fa-ban" title=""></i></button>
            <label className='fw-bold mx-2'>BAN</label>
          </div>
        </div>
        
        <div className="row">
          <div className="col-md-12">
            <div className="main-card mb-3 card transperent-card text-white p-4">
              <div className="card-header no-background p-0 mb-2">
                <div className="btn-actions-pane-right">
                  <div className="input-group icons search-box">
                    <Dropdown>
                      <Dropdown.Toggle variant="success" className='h-45 transperent-box light-border-around text-white' id="dropdown-basic">
                        <b className='px-5'>{DROPDOWN_OPTIONS[this.state.selectedIndex].name}</b>

                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {
                          DROPDOWN_OPTIONS.map((game, i) => {
                            return <Dropdown.Item key={i} onClick={() => this.setState({ selectedIndex: i })}>{game.name} </Dropdown.Item>
                          })
                        }
                      </Dropdown.Menu>
                    </Dropdown>
                    <DebounceInput minLength={3}
                      debounceTimeout={500}
                      type="search"
                      maxLength={100}
                      className="ml-2 form-control rounded text-white transperent-box light-border-around"
                      placeholder="Search in table ...."
                      onChange={(e) => { this.onSearch(e.target.value) }} />
                  </div>
                </div>
              </div>
              <div className='row'>
                <TableComponent column={this.columns} data={this.state.users} paginationTotalRows={this.state.totalResult} onPagination={this.onPagination} />
              </div>
            </div>
          </div>
        </div>
        <Modal show={this.state.action} centered dialogClassName=''>
          <Modal.Body>
            <Confirmation
              message={this.state.action?.msg}
              onConfirm={this.performAction} btnText={this.state.action?.key === 'ban' ? 'Ban' : this.state.action?.value ? 'Block' : 'Unblock'} onClose={() => this.setState({ action: null })}
            />
          </Modal.Body>
        </Modal>
      </div>

    )
  }
}
