
import React, { Component } from 'react';
import { HEADING, POINTS_RANK } from '../../utils/strings.utils';
import { getGamesDetails, routeTo, updateGameDetails } from '../../utils/common.utils';
import { BREADCRUMBS } from '../../utils/breadcrumbs.utils';
import Dropdown from 'react-bootstrap/Dropdown';

import "../../assets/css/switch.css"
import { toast } from 'material-react-toastify';

export default class PointMatrix extends Component {

    constructor(props) {
        super(props);
        this.state = { 
          gameDetails: null,
          selectedGameIndex: 0,
          editEnableIndex : -1,
          updateValue : null
        };
    }
  
    componentDidMount(){
      getGamesDetails(this.setGamesDetails);
    }
  
    setGamesDetails = (gameDetails) =>{
      this.setState({
        gameDetails: gameDetails,
        editEnableIndex : -1,
        updateValue : null
      });
    }


    getExistingPoint = () =>{

      var array = [];

      [...Array(8)].map((key,i)=>{
        if(this.state.gameDetails[this.state.selectedGameIndex]){
          try{
            array[i] = this.state.gameDetails[this.state.selectedGameIndex].pointSystem[i+1];
          }catch(err){
            array[i] = 0;
          }

        }else{
          array[i] = 0;
        }
      });

      array[8] = this.state.gameDetails[this.state.selectedGameIndex]?.pointSystem?  this.state.gameDetails[this.state.selectedGameIndex]?.pointSystem[8] : 0;
      array[9] = this.state.gameDetails[this.state.selectedGameIndex]?.pointsPerKill ? this.state.gameDetails[this.state.selectedGameIndex]?.pointsPerKill : 0;
    
      return array;
    
    }

    changeValue =(val) =>{
      this.setState({
        updateValue: (val ? val : 0)
      });
    }

    updatedPointMatrix = (index, defaultVaules) =>{
      console.log(`####### INDEX :: `,index);
      console.log(`####### defaultVaules :: `,defaultVaules);
      console.log(`####### updateValue :: `,this.state.updateValue);
      let inputValue = parseInt(this.state.updateValue);
      if(inputValue < 0){
        toast.warning('Cannot be a negative number');
        return false;
      }else if(inputValue >=1000){
        toast.warning('Maximum allowed value is 999');
        return false;
      }
      defaultVaules[index] = parseInt(this.state.updateValue);
 

      updateGameDetails(this.state.gameDetails[this.state.selectedGameIndex]._id, { pointMatrix : defaultVaules }, this.setGamesDetails);

    }
    


    render() {

        if(this.state.gameDetails){

          //var gameDetails = this.state.gameDetails;
          //console.log("BEFORE gameDetails= ",gameDetails)
          this.state.gameDetails.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
          //console.log("AFTER gameDetails= ",gameDetails)
          const defaultVaules = this.getExistingPoint();

          return (
            <div className="app-main__inner">
                {/* HEAD */}
                <div className="d-flex flex-column flex-lg-row">
                    {/* MAIN-HEAD */}
                    <div className="flex-grow-1">
                        <h3 className='text-white'>{BREADCRUMBS.POINT_MATRIX.HEADING}</h3>
                    </div>
                    {/* BREAD-CRUMB */}
                    <div className="">
                        <ol className="breadcrumb color-white" style={{ background: 'none', padding: '0px' }}>
                            {BREADCRUMBS.POINT_MATRIX.PATH.map((route,i)=>{
                                return <li key={i} className="breadcrumb-item cursor-pointer" onClick={() => { routeTo('/','dashboard')  }}>{route}</li>
                            })}
                        </ol>
                    </div>
                </div>
                {/* BODY */}
                <div className="row">
                    <div className="col-md-12">
                        <div className="main-card mb-3 card transperent-card text-white p-4">
                            {/* SUB-HEAD */}
                            <div className="card-header no-background p-0 mb-2 ">Game Center
                                {/* FILTER */}
                                <div className="btn-actions-pane-right">
                                 
                                  <Dropdown>
                                    <Dropdown.Toggle variant="success" className='transperent-box light-border-around text-white' id="dropdown-basic">
                                    <b className='px-5'>{this.state.gameDetails[this.state.selectedGameIndex].name}</b>
                                 
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                      {
                                        this.state.gameDetails.map((game,i)=>{
                                          return (
                                            <Dropdown.Item key={i} onClick={()=>this.setState({selectedGameIndex : i, editEnableIndex : -1})}>
                                              <b className='px-4'>{game.name}</b> 
                                            </Dropdown.Item>
                                          ) 
                                        })
                                      }
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                            </div>

                            {/* MAIN_BODY */}
                            <div className='row'>
                                { [...Array(10)].map((key,i)=>{
                                 return (
                                    <div className='col-12 col-md-6 my-2'>
                                      <label for="basic-url ps-1"><span className=' font-bold'>{POINTS_RANK[i]}</span></label>
                                      {this.state.editEnableIndex === i ?
                                      <div className="input-group" key={i}>
                                        <input type="number" 
                                          defaultValue={defaultVaules[i]}
                                          onChange={(e)=>this.changeValue(e.target.value)}
                                          className="form-control transperent-box light-border-around text-gray" placeholder={`Enter ${POINTS_RANK[i]} points`} 
                                          aria-label="Recipient's username" aria-describedby="basic-addon2"
                                          style={{borderTopLeftRadius:'10px', borderBottomLeftRadius: '10px'}}
                                        />
                                        <div className="input-group-append">
                                          <button onClick={()=>this.updatedPointMatrix(i, defaultVaules)} className="btn edt-btn light-border-around" type="button" style={{borderTopRightRadius:'10px', borderBottomRightRadius: '10px'}}>
                                            save
                                          </button>
                                        </div>
                                      </div>
                                      :
                                      <div className="input-group" key={i}>
                                        <input type="number" 
                                          readOnly
                                          value={defaultVaules[i]}
                                          className="form-control transperent-box light-border-around text-gray" placeholder={`Enter ${POINTS_RANK[i]} points`} 
                                          aria-label="Recipient's username" aria-describedby="basic-addon2"
                                          style={{borderTopLeftRadius:'10px', borderBottomLeftRadius: '10px'}}
                                        />
                                        <div className="input-group-append">
                                          <button className="btn edt-btn light-border-around" onClick={()=>this.setState({editEnableIndex : i})} type="button" style={{borderTopRightRadius:'10px', borderBottomRightRadius: '10px'}}>
                                            Edit
                                          </button>
                                        </div>
                                      </div>
                                      }
                                    </div>
                                  );
                                })}
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          );
        }else{
          return null;
        }
       
    }
}