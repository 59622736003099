import { Component } from 'react'
import { TableComponent } from '../common/DataTable';
import UserService from '../../services/user.service';
import { BREADCRUMBS } from '../../utils/breadcrumbs.utils';
import { convertDateTime, errorHandler, getClanOrgMembers, routeTo, updateLoader } from '../../utils/common.utils';
import Dropdown from 'react-bootstrap/Dropdown';
import { DebounceInput } from 'react-debounce-input';
import { createdAt } from '../../utils/dateTime.utils';
import ClanTournamentStats from './ClanTournamentStats';
import ClanMembers from './ClanMembers';
import ClanTeamsStats from './ClanTeamsStats';
import { CLAN_VIEWS } from '../../utils/strings.utils';

const DROPDOWN_OPTIONS = [
    // { name: 'Clan ID', term: '_id' },
    { name: 'Clan Name', term: 'name' },
]


export default class Clans extends Component {
    constructor(props) {
        super(props)
        this.state = {
            clans: [],
            totalResult: 0,
            params: {
                pageNo: 0,
                limit: 10
            },
            selectedIndex: 0,
            viewClanDetailsId: null,
            viewClanDetails: null,
            currentView: CLAN_VIEWS.CLANS
        }
        this.authApi = new UserService();
        this.columns = [
            // {
            //     name: "CLAN ID",
            //     selector: (row) => [row._id],
            //     sortable: false,
            // },
            {
                name: "CLAN'S NAME",
                selector: (row) => [row.name],
                sortable: false,
            },
            {
                name: "DEN",
                selector: (row) => [row.den ? 'Yes' : 'No'],
                sortable: false,
            },
            {
                name: "KYC",
                selector: (row) => [row.kycVerified ? 'Yes' : 'No'],
                sortable: false,
            },
            {
                name: "VERIFIED",
                selector: (row) => [row.verificationStatus === 'VERIFIED' ? 'Yes' : 'No'],
                sortable: false,
            },
            {
                name: "TOURNAMEN WON/PLAYED",
                selector: (row) => [<span><b style={{color: '#13f745'}}>{row.stats.length}</b> / {row.stats.length} <i onClick={()=>this.viewDetails(row, CLAN_VIEWS.TOURNAMENT_STATS)} className='mx-1 cursor-pointer' style={{color:'#8e7ffb'}}>(View all)</i></span>],
                sortable: false,
            },
            {
                name: "MEMBERS",
                selector: (row) => [<span style={{color: '#13f745'}}>{getClanOrgMembers(row.members)} <i onClick={()=>this.viewDetails(row, CLAN_VIEWS.MEMBERS)} className='mx-1 cursor-pointer' style={{color:'#8e7ffb'}}>(View all)</i></span>],//[getClanOrgMembers(row.members)],
                sortable: false,
            },
            {
                name: "TEAMS",
                selector: (row) => [<span style={{color: '#13f745'}}><i onClick={()=>this.viewDetails(row, CLAN_VIEWS.TEAMS)} className='mx-1 cursor-pointer' style={{color:'#8e7ffb'}}>View all</i></span>],//[getClanOrgMembers(row.members)],
                sortable: false,
            },
            {
                name: "ACTIVE STATUS",
                selector: (row) => [row.isActive ? 'Yes' : 'No'],
                sortable: false,
            },
            {
                name: "CLAN'S AGE",
                selector: (row) => [createdAt(row.createdAt)],
                sortable: false,
            },
        ]
    }

    componentDidMount() {
        this.getClans(this.state.params)
    }

    viewDetails = (row, view) => {
        this.setState({
            viewClanDetailsId: row._id,
            viewClanDetails: row,
            currentView: view
        });
    }


    onPagination = (i, key) => {
        let paramsTemp = this.state.params
        paramsTemp[key] = i
        this.setState({ params: paramsTemp })
        console.log(`${key}`, paramsTemp)
        this.getClans(paramsTemp)
    }
    onSearch = (val) => {
        let params = {
            [DROPDOWN_OPTIONS[this.state.selectedIndex].term]: val,
            pageNo: 0,
            limit: this.state?.params?.limit ?this.state.params.limit : 10
        }
        if (!val) delete params[DROPDOWN_OPTIONS[this.state.selectedIndex].term];
        this.setState({ params: params })
        this.searchClans(params)
    }
    getClans = (params) => {
        updateLoader(true)
        this.authApi.getClansStats(params).then(
            (res) => {
                let clans = res.data.result.map((el, i) => { el.index = i; return el })
                this.setState({ clans: clans, totalResult: res.data.totalResults })
                //console.log("res - ", res.data.result)
                updateLoader(false)
            }
        ).catch(
            (err) => {
                console.error(err)
                updateLoader(false)
                errorHandler(err)
            }
        )
    };
    searchClans = (params) => {
        updateLoader(true)
        this.authApi.getClansStats(params).then(
            (res) => {
                let clans = res.data.result.map((el, i) => { el.index = i; return el })
                this.setState({ clans: clans, totalResult: res.data.totalResults })
                updateLoader(false)
            }
        ).catch(
            (err) => {
                console.error(err)
                updateLoader(false)
                errorHandler(err)
            }
        )
    };

    getClansStatsView = () => { 
        return(
            <div className="main-card mb-3 card transperent-card text-white p-4">
                <div className="card-header no-background p-0 mb-2">
                    <div className="btn-actions-pane-right">
                        <div className="input-group icons search-box">
                            <Dropdown>
                                <Dropdown.Toggle variant="success" className='h-45 transperent-box light-border-around text-white' id="dropdown-basic">
                                    <b className='px-5'>{DROPDOWN_OPTIONS[this.state.selectedIndex].name}</b>

                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    {
                                        DROPDOWN_OPTIONS.map((game, i) => {
                                            return <Dropdown.Item key={i} onClick={() => this.setState({ selectedIndex: i })}>{game.name} </Dropdown.Item>
                                        })
                                    }
                                </Dropdown.Menu>
                            </Dropdown>
                            <DebounceInput minLength={3}
                                debounceTimeout={500}
                                type="search"
                                maxLength={100}
                                className="ml-2 form-control rounded text-white transperent-box light-border-around"
                                placeholder="Search in table ...."
                                onChange={(e) => { this.onSearch(e.target.value) }} />
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <TableComponent column={this.columns} data={this.state.clans} paginationTotalRows={this.state.totalResult} onPagination={this.onPagination} />
                </div>
            </div>
        )
    }

    renderView = () => {
        const {viewClanDetailsId, viewClanDetails} = this.state; 
        switch (this.state.currentView) {
            case CLAN_VIEWS.CLANS:
                return this.getClansStatsView();
            case CLAN_VIEWS.TOURNAMENT_STATS:
                return <ClanTournamentStats viewClanDetailsId={viewClanDetailsId} viewClanDetails={viewClanDetails} updateState={this.updateState} />
            case CLAN_VIEWS.MEMBERS:
                return <ClanMembers viewClanDetailsId={viewClanDetailsId} viewClanDetails={viewClanDetails} updateState={this.updateState} />
            case CLAN_VIEWS.TEAMS:
                return <ClanTeamsStats viewClanDetailsId={viewClanDetailsId} viewClanDetails={viewClanDetails} updateState={this.updateState} />
            default:
                return this.getClansStatsView();
        }
    }

    updateState = (obj) => {
        this.setState(obj)
    }

    render() {
        return (
            // <div className='container my-4'>
            <div className="app-main__inner">
                <div className="d-flex flex-column flex-lg-row">
                    <div className="flex-grow-1">
                        <h3 className='text-white'>{BREADCRUMBS.USERS_CLANS.HEADING}</h3>


                    </div>
                    <div className="">
                        <ol className="breadcrumb color-white" style={{ background: 'none', padding: '0px' }}>
                            {BREADCRUMBS.USERS_CLANS.PATH.map((route, i) => {
                                return <li key={i} className="breadcrumb-item cursor-pointer" onClick={() => { routeTo('/', 'dashboard') }}>{route}</li>
                            })}
                        </ol>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        {this.renderView()}
                    </div>
                </div>
            </div>
            // </div>
        )
    }
}
