import { Component } from 'react'
import { TableComponent } from '../common/DataTable';
import UserService from '../../services/user.service';
import { BREADCRUMBS } from '../../utils/breadcrumbs.utils';
import { convertDateTime, errorHandler, getCommissionDetails, getWalletPayments, routeTo, updateLoader } from '../../utils/common.utils';
import VerificationService from '../../services/verification.service';
import { Button, ButtonGroup, Modal } from 'react-bootstrap'
import Confirmation from '../common/Confirmation';
import Dropdown from 'react-bootstrap/Dropdown';
import { DebounceInput } from 'react-debounce-input';
import { AiFillEye } from 'react-icons/ai';
import defaultPreviewImage from '../../assets/images/imgs/1.jpg';
import ImagePreview from '../common/ImagePreview';


import DropdownButton from 'react-bootstrap/DropdownButton';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import ApproveOrRejectPayment from '../common/ApproveOrRejectPayment';


const DROPDOWN_OPTIONS = [
  { name: 'TRANSACTION ID', term: '_id' },
  { name: 'WALLET TYPE', term: 'type' }
]

const WALLET_TYPES = ["ALL", "USER", "CLAN", "ORG"];

export default class AccountPayments extends Component {
  constructor(props) {
    super(props)
    this.state = {
      teams: [],
      walletType: WALLET_TYPES[0],
      paymentStatus: "PENDING",
      totalResult: 0,
      params: {
        pageNo: 0,
        limit: 10
      },
      selectedIndex: 1,
      action: null,
      payments: [],
      previewImageDetails: {
        display: false,
        src: defaultPreviewImage
      },
      approvePaymentDetails :null,
      declinePaymentDetails :null,
      walletInfo: null
    }
    this.verificationApi = new VerificationService();
    this.authApi = new UserService();
  }

  componentDidMount() {
    getWalletPayments(this.state.walletType, this.state.paymentStatus, this.state.params, this.setWalletPayments);
    getCommissionDetails((result)=>{
      result.forEach((obj) => {
        if(obj.type === "WALLET"){

          this.setState({
            walletInfo: obj.questions
          })
          //return obj.questions;
        }
      });
     
    })
  }

  previewImage = (src, param) => {
    this.setState({
      previewImageDetails: {
        display: param,
        src: (param ? src : defaultPreviewImage)
      }
    });
  }

 

  setWalletPayments = (result,count) => {
    this.setState({
        payments: result,
        totalResult: count
    })
  }

  action = (id, key, value) => {
    let msg = (key === 'ban') ? 'Are you sure, want to Ban this Brand?' : value ? 'Are you sure, want to Block this Brand?' : 'Are you sure, want to Unblock this Brand?'
    let data = {
      id: id,
      msg: msg,
      key: key,
      value: value
    }
    this.setState({ action: data })
  }

  onPagination = (i, key) => {
    let paramsTemp = this.state.params
    paramsTemp[key] = i
    this.setState({ params: paramsTemp })
    console.log(`${key}`, paramsTemp);
    getWalletPayments(this.state.walletType, this.state.paymentStatus, paramsTemp, this.setWalletPayments);
  }

  onSearch = (val) => {
    let params = {
      [DROPDOWN_OPTIONS[this.state.selectedIndex].term]: val,
      pageNo: 0,
      limit: this.state?.params?.limit ?this.state.params.limit : 10
    }
    if (!val) delete params[DROPDOWN_OPTIONS[this.state.selectedIndex].term];
    this.setState({ params: params });
    getWalletPayments(this.state.walletType, this.state.paymentStatus, params, this.setWalletPayments);
  }

  performAction = () => {
    let data = {
      [this.state.action.key]: this.state.action.value
    }
    updateLoader(true)
    this.verificationApi.updateSponsor(this.state.action.id, data).then(
      (res) => {
        updateLoader(false)
      }
    ).catch(
      (err) => {
        console.error(err)
        updateLoader(false)
        errorHandler(err)
      }
    )
  }

  onSwitchtab = (status) => {
   

    this.setState({
        paymentStatus: status
    });
    getWalletPayments(this.state.walletType, status, this.state.params, this.setWalletPayments);
  }

  onSelectType = (type) => {
    this.setState({
        walletType: type
    });

    getWalletPayments(type, this.state.paymentStatus, this.state.params, this.setWalletPayments);
  }


  
  render() {
   // console.log("columns :: ",this.state.columns.length)
   console.log("######### getCommissionDetails : ",this.state.walletInfo)
    var columns = [
        {
            name: "TRANSACTION ID",
            selector: (row) => [row._id],
            sortable: false,
        },
        {
            name: "ACCOUNT HOLDER",
            selector: (row) => [row.parentDtails?.name],
            sortable: false,
        },
        {
            name: "WALLET TYPE",
            selector: (row) => [row.type],
            sortable: false,
        },
        {
            name: "NAME",
            selector: (row) =>  [row.userDtails?.name],
            sortable: false,
        },
        {
            name: "STATUS",
            selector: (row) =>  [row.status],
            sortable: false,
        },
        {
            name: "RECEIPT",
            selector: (row) => <span> <AiFillEye onClick={()=>this.previewImage(row.screenshot.url,true)}/> </span>,
            sortable: false,
        },
    ];

    if(this.state.paymentStatus === "PENDING"){
       columns.push(
        {
            cell: (row) => <span> <span> <button className={`es-squre-success-btn`} onClick={() => this.setState({approvePaymentDetails: row})} id={row.id}><i className={`fa fa-check`} title=""></i></button> </span>
            <span> <button disabled={row?.ban} className='es-squre-danger-btn' onClick={() => this.setState({declinePaymentDetails: row})} id={row.id}><i className="fa fa-times" title=""></i></button> </span></span>,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            name: 'ACTION'
        }
       ) 
    }else{
      columns.push(
        {
          name: "AMOUNT",
          selector: (row) => <> {row.amount} EC</>,
          sortable: false,
        }
      )
    }
    return (
      <div className="app-main__inner">
        <div className="d-flex flex-column flex-lg-row">
          <div className="flex-grow-1">
            <h3 className='text-white'>{BREADCRUMBS.USERS_BLOCK_BRANDS.HEADING}</h3>


          </div>
          <div className="">
            <ol className="breadcrumb color-white" style={{ background: 'none', padding: '0px' }}>
              {BREADCRUMBS.USERS_BLOCK_BRANDS.PATH.map((route, i) => {
                return <li key={i} className="breadcrumb-item cursor-pointer" onClick={() => { routeTo('/', 'dashboard') }}>{route}</li>
              })}
            </ol>
          </div>
        </div>
        
        
        <div className="row">
          <div className="col-md-12">
            <div className="main-card mb-3 card transperent-card text-white p-4">
              <div className="card-header no-background p-0 mb-2">
                <ButtonGroup aria-label="Basic example">
                    <Button className={this.state.paymentStatus === "ACCEPTED" && "selected-type-tab"} variant="primary" onClick={()=>this.onSwitchtab("ACCEPTED")}>APPROVED</Button>
                    <Button className={this.state.paymentStatus === "PENDING" && "selected-type-tab"} variant="primary" onClick={()=>this.onSwitchtab("PENDING")}>PENDING</Button>
                    <Button className={this.state.paymentStatus === "REJECTED" && "selected-type-tab"} variant="primary" onClick={()=>this.onSwitchtab("REJECTED")}>REJECTED</Button>
                </ButtonGroup>

              
                
                <DropdownButton className='me-2 mx-2' id="dropdown-basic-button" title={this.state.walletType}>
                    {WALLET_TYPES.map((type)=> {
                        return <Dropdown.Item onClick={()=>this.onSelectType(type)}>{type}</Dropdown.Item>
                    })}
                </DropdownButton>
                
             
                <div className="btn-actions-pane-right pt-3">
                    <InputGroup className="mb-3 " >
                        <Form.Control aria-label="Text input with dropdown button"
                         placeholder="Search in table ...."
                         className='text-white'
                         style={{height: "10px"}}
                         onChange={(e) => { this.onSearch(e.target.value) }}
                        />

                        <DropdownButton className='search-drop'
                            variant="outline-secondary"
                            title={DROPDOWN_OPTIONS[this.state.selectedIndex].name}
                            id="input-group-dropdown-2"
                            align="end"
                        >
                        {
                          DROPDOWN_OPTIONS.map((searchType, i) => {
                            return  <Dropdown.Item key={i} onChange={() => this.setState({ selectedIndex: i })}>{searchType.name} </Dropdown.Item>
                          })
                        }
              
                        </DropdownButton>
                    </InputGroup>
                 
                </div>
              </div>
              <div className='row'>
                <TableComponent column={columns} data={this.state.payments} paginationTotalRows={this.state.totalResult} onPagination={this.onPagination} />
              </div>
            </div>
          </div>
        </div>

        <Modal show={this.state.approvePaymentDetails || this.state.declinePaymentDetails} centered dialogClassName=''>
          <Modal.Body>
            
            <ApproveOrRejectPayment
              walletInfo={this.state.walletInfo}
              isApproval={this.state.approvePaymentDetails? true : false} 
              paymentDtails={this.state.approvePaymentDetails? this.state.approvePaymentDetails : this.state.declinePaymentDetails}  
              message={this.state.approvePaymentDetails? "( This amount will be added to the respective wallet )" : "( This amount will be rejected to be added in respective wallet )"}
              onClose={() => this.setState({ approvePaymentDetails: null, declinePaymentDetails: null  })}
              refreshList={()=>{getWalletPayments(this.state.walletType, this.state.paymentStatus, this.state.params, this.setWalletPayments)}}
            />
          </Modal.Body>
        </Modal>
        <Modal show={this.state.previewImageDetails.display} centered dialogClassName="image-preview-container bg-transparent">
          <ImagePreview previewImageDetails={this.state.previewImageDetails} previewImage={this.previewImage} />
        </Modal>  
      </div>
    )
  }
}
