import React, { Component } from 'react';
import avatar from '../../assets/images/avatar/8.jpg';
import { CARDS } from '../../utils/images.utils';
import { HEADING } from '../../utils/strings.utils';

export default class Card extends Component {
   
    constructor(props) {
        super(props);
        this.state = {
            dashboardDetails: props.dashboardDetails
        };
    }

    componentWillReceiveProps(newProps){
        if(newProps){
            this.setState({
                dashboardDetails: newProps.dashboardDetails
            });
        }
    }
 

    render() {
        const imgs = [CARDS.TEAMS, CARDS.OPERATORS, CARDS.DEN_USERS, CARDS.TEAMS];
        
       // console.log("CARD DashboardDetails------------",this.state.dashboardDetails)

        return(
            <section className='card-section'>
                <div className='row'>
                    {this.state.dashboardDetails?.usersCount.map((element, i) => {
                        return(
                            <div className="col-xl-3 col-sm-6 mb-xl-0 my-2" key={i}>
                                <div className="card transperent-card text-white">
                                    <div className="card-body p-3">
                                        <div className="row">
                                            <div className="col-8">
                                                <div className="numbers">
                                                    <p className="text-sm mb-1 text-uppercase font-weight-bold">{element.name}</p>
                                                    <h2 className="font-weight-bolder text-white">{element.count}</h2>
                                                    <p className="mb-0">
                                                        <span className="text-success text-sm font-weight-bolder">+{(element.lastmonthcount/(element.count/100)).toFixed(2)}% </span>
                                                        last month
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-4 text-end">
                                                <div className="icon icon-shape bg-gradient-primary shadow-primary text-center rounded-circle">
                                                    <i className="ni ni-money-coins text-lg opacity-10" aria-hidden="true"></i>
                                                    <img src={CARDS[element.type]} alt='' className='avatar rounded-circle' />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                    
                </div>

                <div className="row">
                    {this.state.dashboardDetails?.tournamentsCount.map((element, i) => {
                        return(
                            <div className="col-md-6 col-lg-3 text-white" key={i}>
                                <div className={`card card-bg-${i+1}`}>
                                    <div className="card-body p-3">
                                        <div className="row d-flex align-items-center">
                                            <div className="col-8">
                                                <div className="numbers">
                                                    <h2 className="font-weight-bolder text-white">{element.type === "AVERAGE" ? `${element.time.hours} hr ${element.time.minutes} min`   : element.count}</h2>
                                                    <p className="text-sm text-uppercase font-weight-bold" style={i === 3 ? {} : {width:"52%"}}>{HEADING[element.name]}</p>
                                                </div>
                                            </div>
                                            <div className="col-4 text-end">
                                                <div className="icon icon-shape bg-gradient-primary shadow-primary text-center">
                                                    <i className="ni ni-money-coins text-lg opacity-10" aria-hidden="true"></i>
                                                    <img src={CARDS[element.type]} alt=''  height='50px' />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </section>
        )
    }
}
