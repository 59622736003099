import { Component } from 'react'
import { Outlet } from 'react-router-dom'

export default class UserIndex extends Component {
  render() {
    return (
      <div className='es-page-height'><Outlet></Outlet></div>
    )
  }
}
