export const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

export const getDateMonthYear = (dateString) => {
    try {
        const matchSchedule = new Date(dateString);
        return [`${matchSchedule.getDate()} ${months[matchSchedule.getMonth()]} ${matchSchedule.getFullYear()}`];
    } catch (error) {
        console.log("Error : ", error)
        return [`TBD`, `TBD`];
    }
}

export const createdAt = (dateString) => {
    try {

        const currentDate = new Date()
        const receivedDate = new Date(dateString)

        var difference = currentDate - receivedDate;  
        var diffDays = Math.floor(difference / 86400000); // days
        var diffHrs = Math.floor((difference % 86400000) / 3600000) + (24 * diffDays); // hours
        var diffMins = Math.round(((difference % 86400000) % 3600000) / 60000); // minutes
        
        if(diffHrs > 24){
            return [`${diffDays+1} days`];
        }else if(diffHrs > 0){
            return [`${diffHrs} hours`];
        }else{
            return [`${diffMins} minutes`];
        }

    } catch (error) {
        console.log('error::', error)
        return [`TBD`, `TBD`];
    }
}

export const dateFormat = (dateString, key?) => {
    try {
        const givenDate = new Date(dateString);
        const dataArray = givenDate.toString().split(' ')
        //const time = givenDate.toLocaleTimeString();
        if(key){
            return `${dataArray[2]} ${dataArray[1]} ${dataArray[3]}`
        }else{
            return `${dataArray[4]}`
        }
    } catch (error) {
        console.log('error::', error)
        return 'Select Date';
    }
}