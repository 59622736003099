import { Component } from 'react'
import { TableComponent } from '../common/DataTable';
import WalletService from '../../services/wallet.service';
import { BREADCRUMBS } from '../../utils/breadcrumbs.utils';
import { errorHandler, getScrollingText, routeTo, updateLoader, withRouter } from '../../utils/common.utils';
import Dropdown from 'react-bootstrap/Dropdown';
import { DebounceInput } from 'react-debounce-input';
import { HEADING } from '../../utils/strings.utils';

const DROPDOWN_OPTIONS = [
  { name: 'Tournament Name', term: 'tournamentName' },
  { name: 'Game', term: 'gameName' },
]
class TournamentTransaction extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tournaments: [],
      totalResult: 0,
      params: {
        pageNo: 0,
        limit: 10
      },
      selectedIndex: 0,
    }
    this.walletApi = new WalletService();
    this.columns = [
      {
        name: HEADING.TOURNAMENTS.toUpperCase(),
        selector: (row) => [row.tournamentName],
        sortable: false,
      },
      {
        name: HEADING.GAME.toUpperCase(),
        selector: (row) => [row.gameName],
        sortable: false,
      },
      {
        name: getScrollingText(HEADING.TOTAL_REG_USERS.toUpperCase()),
        selector: (row) => [row.totalRegisteredUsers],
        sortable: false,
      },
      {
        name: HEADING.TOTAL_DISPUTES.toUpperCase(),
        selector: (row) => [row.totalDisputes],
        sortable: false,
      },
      {
        name: getScrollingText(HEADING.RESOLVED_DISPUTES.toUpperCase()),
        selector: (row) => [row.resolvedDisputes],
        sortable: false,
      },
      {
        name: getScrollingText(HEADING.TOURNAMENTS_ENTRY_FEES.toUpperCase()),
        selector: (row) => [row.tournamentEntryFee],
        sortable: false,
      },
      {
        name: getScrollingText(HEADING.MIN_TICKET_FEE.toUpperCase()),
        selector: (row) => [row.minBettingTicketFee],
        sortable: false,
      },
      {
        name: getScrollingText(HEADING.TO_TOURNAMENT_DEPOSIT.toUpperCase()),
        selector: (row) => [row.toTournamentDeposit],
        sortable: false,
      },
      {
        name: getScrollingText(HEADING.TO_BET_DEPOSIT.toUpperCase()),
        selector: (row) => [row.toBettingDeposit],
        sortable: true,
      },
      {
        name: getScrollingText(HEADING.BET_COLLECTION.toUpperCase()),
        selector: (row) => [row.bettingCollection],
        sortable: false,
      },
      {
        name: getScrollingText(HEADING.TOURNAMENTS_PRICE_POOL.toUpperCase()),
        selector: (row) => [row.tournamentPrizePool],
        sortable: false,
      },
      {
        name: getScrollingText(HEADING.BET_PRICE_POOL.toUpperCase()),
        selector: (row) => [row.bettingPrizePool],
        sortable: false,
      },
      // {
      //   name: "REFUNDS",
      //   selector: (row) => [row.refunds],
      //   sortable: false,
      // },
      {
        name: getScrollingText("TOURNAMENT WINNERS"),
        cell: (row) => <span> <button className='mr-2 btn btn-link' onClick={() => { this.viewAllTransaction(row?.tournamentId) }} id={row.id}>View all</button></span>,
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      },
      {
        name: getScrollingText("BETTING WINNERS"),
        cell: (row) => <span> <button className='mr-2 btn btn-link' onClick={() => { this.viewAllBetting(row?.tournamentId) }} id={row.id}>View all</button></span>,
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      },
    ]
  }
  componentDidMount() {
    this.getTournament(this.state.params)
  }
  viewAllTransaction = (id) => {
    this.props.router.navigate(`/wallet/tournament-winners/${id}`)
  }
  viewAllBetting = (id) => {
    console.log(id)
    this.props.router.navigate(`/wallet/betting-winners/${id}`)
  }
  onPagination = (i, key) => {
    let paramsTemp = this.state.params
    paramsTemp[key] = i
    this.setState({ params: paramsTemp })
    console.log(`${key}`, paramsTemp)
    this.getTournament(paramsTemp)
  }
  onSearch = (val) => {
    let params = {
      [DROPDOWN_OPTIONS[this.state.selectedIndex].term]: val,
      pageNo: 0,
      limit: this.state?.params?.limit ?this.state.params.limit : 10
    }
    if (!val) delete params[DROPDOWN_OPTIONS[this.state.selectedIndex].term];
    this.setState({ params: params })
    this.searchTournament(params)
  }
  getTournament = (params) => {
    updateLoader(true)
    this.walletApi.getAllTournament(params).then(
      (res) => {
        let tournaments = res.data.result.map((el, i) => { el.index = i; return el })
        this.setState({ tournaments: tournaments, totalResult: res.data.totalResults })
        console.log("res - ", res.data.result)
        updateLoader(false)
      }
    ).catch(
      (err) => {
        console.error(err)
        updateLoader(false)
        errorHandler(err)
      }
    )
  };
  searchTournament = (params) => {
    updateLoader(true)
    this.walletApi.getAllTournament(params).then(
      (res) => {
        let tournaments = res.data.result.map((el, i) => { el.index = i; return el })
        this.setState({ tournaments: tournaments, totalResult: res.data.totalResults })
        updateLoader(false)
      }
    ).catch(
      (err) => {
        console.error(err)
        updateLoader(false)
        errorHandler(err)
      }
    )
  };
  render() {
    return (
      <div className="app-main__inner">
        <div className="d-flex flex-column flex-lg-row">
          <div className="flex-grow-1">
            <h3 className='text-white'>{BREADCRUMBS.WALLETS_TOURNAMENTS.HEADING}</h3>


          </div>
          <div className="">
            <ol className="breadcrumb color-white" style={{ background: 'none', padding: '0px' }}>
              {BREADCRUMBS.WALLETS_TOURNAMENTS.PATH.map((route, i) => {
                return <li key={i} className="breadcrumb-item cursor-pointer" onClick={() => { routeTo('/', 'dashboard') }}>{route}</li>
              })}
            </ol>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="main-card mb-3 card transperent-card text-white p-4">
              <div className="card-header no-background p-0 mb-2">
                <div className="btn-actions-pane-right">
                  <div className="input-group icons search-box">
                    <Dropdown>
                      <Dropdown.Toggle variant="success" className='h-45 transperent-box light-border-around text-white' id="dropdown-basic">
                        <b className='px-5'>{DROPDOWN_OPTIONS[this.state.selectedIndex].name}</b>

                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {
                          DROPDOWN_OPTIONS.map((game, i) => {
                            return <Dropdown.Item key={i} onClick={() => this.setState({ selectedIndex: i })}>{game.name} </Dropdown.Item>
                          })
                        }
                      </Dropdown.Menu>
                    </Dropdown>
                    <DebounceInput minLength={3}
                      debounceTimeout={500}
                      type="search"
                      maxLength={100}
                      className="ml-2 form-control rounded text-white transperent-box light-border-around"
                      placeholder="Search in table ...."
                      onChange={(e) => { this.onSearch(e.target.value) }} />
                  </div>
                </div>
              </div>
              <div className='row'>
                <TableComponent column={this.columns} data={this.state.tournaments} paginationTotalRows={this.state.totalResult} onPagination={this.onPagination} />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default withRouter(TournamentTransaction)