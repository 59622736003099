import { Component } from 'react'
import UserService from '../../services/user.service';
import { TableComponent } from '../common/DataTable';
import { BREADCRUMBS } from '../../utils/breadcrumbs.utils';
import { convertDateTime, errorHandler, routeTo, updateLoader } from '../../utils/common.utils';
import Dropdown from 'react-bootstrap/Dropdown';
import { DebounceInput } from 'react-debounce-input';
import { dateFormat } from '../../utils/dateTime.utils';
import ViewUserDetails from './ViewUserDetails';

const DROPDOWN_OPTIONS = [
    // { name: 'User ID', term: 'userId' },
    { name: 'Name', term: 'name' },
    { name: 'AKA', term: 'username' },
    { name: 'Email', term: 'email' },
    { name: 'Discord', term: 'discordUsername' },
]

export default class UsersList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            users: [],
            totalResult: 0,
            params: {
                pageNo: 0,
                limit: 10
            },
            selectedIndex: 0,
            viewUserDetailsId: null,
            viewUserDetails: null
        }
        this.authApi = new UserService();
        this.columns = [
            // {
            //     name: "USER ID",
            //     selector: (row) => [row._id],
            //     sortable: false,
            // },
            {
                name: "NAME",
                selector: (row) => [row.name],
                sortable: false,
            },
            {
                name: "EMAIL",
                selector: (row) => [row.email],
                sortable: false,
            },
            {
                name: "PHONE",
                selector: (row) => [row.countryCode?.number ? `${row.countryCode.code}-${row.countryCode.number}` : 'NA'],
                sortable: true,
            },
            {
                name: "AKA",
                selector: (row) => [row.username],
                sortable: false,
            },
            {
                name: "DEN",
                selector: (row) => [row.den ? 'Yes' : 'No'],
                sortable: false,
            },
            {
                name: "KYC",
                selector: (row) => [row.kycVerified ? 'Yes' : 'No'],
                sortable: false,
            },
            {
                name: "VERIFIED",
                selector: (row) => [(row.verificationStatus === 'VERIFIED') ? 'Yes' : 'No'],
                sortable: false,
            },
            {
                name: "TOURNAMEN WON/PLAYED",
                selector: (row) => [<span><b style={{color: '#13f745'}}>{row.participatedTournamensWins}</b> / {row.participatedTournamens.length} <i onClick={()=>this.viewDetails(row)} className='mx-1 cursor-pointer' style={{color:'#8e7ffb'}}>(View all)</i></span>],
                sortable: false,
            },
            {
                name: "DISCORD",
                selector: (row) => [row?.discordProfile?.discordUsername? row?.discordProfile?.discordUsername : 'N/A'],
                sortable: false,
            },
            {
                name: "DATE",
                selector: (row) => [dateFormat(row.createdAt, true)],
                sortable: false,
            },
            {
                name: "TIME",
                selector: (row) => [dateFormat(row.createdAt, false)],
                sortable: false,
            }
        ]
    }

    viewDetails = (row) => {
        this.setState({
            viewUserDetailsId: row._id,
            viewUserDetails: row
        });
    }

    componentDidMount() {
        this.getUsers(this.state.params)
    }

    onPagination = (i, key) => {
        console.log('i, key', i, key);
        let paramsTemp = this.state.params
        paramsTemp[key] = i
        this.setState({ params: paramsTemp })
        console.log(`${key}`, paramsTemp)
        this.getUsers(paramsTemp)
    }

    onSearch = (val) => {
        let params = {
            [DROPDOWN_OPTIONS[this.state.selectedIndex].term]: val,
            pageNo: 0,
            limit: this.state?.params?.limit ?this.state.params.limit : 10
        }
        //console.log('Before params ::', params);
        if (!val) delete params[DROPDOWN_OPTIONS[this.state.selectedIndex].term];
        //console.log('After params ::', params);
        this.setState({ params: params })
        this.searchUsers(params)
    }

    getUsers = (params) => {
        updateLoader(true)
        this.authApi.getAllUsersStats(params).then(
            (res) => {
                let users = res.data.result.map((el, i) => { el.index = i; return el })
                this.setState({ users: users, totalResult: res.data.totalResults })
                console.log("res - ", res.data.result)
                updateLoader(false)
            }
        ).catch(
            (err) => {
                console.error(err)
                updateLoader(false)
                errorHandler(err)
            }
        )
    };

    searchUsers = (params) => {
        //console.log("searchUsers params ::", params);
        updateLoader(true)
        this.authApi.getAllUsersStats(params).then(
            (res) => {
                let users = res.data.result.map((el, i) => { el.index = i; return el })
                this.setState({ users: users, totalResult: res.data.totalResults })
                updateLoader(false)
            }
        ).catch(
            (err) => {
                console.error(err)
                updateLoader(false)
                errorHandler(err)
            }
        )
    };

    updateState = (obj) => {
        this.setState(obj)
    }
    
    render() {
        return (
            <div className="app-main__inner">
                <div className="d-flex flex-column flex-lg-row">
                    <div className="flex-grow-1">
                        <h3 className='text-white'>{BREADCRUMBS.USERS_USERS.HEADING}</h3>
                    </div>
                    <div className="">
                        <ol className="breadcrumb color-white" style={{ background: 'none', padding: '0px' }}>
                            {BREADCRUMBS.USERS_USERS.PATH.map((route, i) => {
                                return <li key={i} className="breadcrumb-item cursor-pointer" onClick={() => { routeTo('/', 'dashboard') }}>{route}</li>
                            })}
                        </ol>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        {this.state.viewUserDetailsId === null? 
                            <div className="main-card mb-3 card transperent-card text-white p-4">
                                <div className="card-header no-background p-0 mb-2">
                                    <div className="btn-actions-pane-right">

                                        <div className="input-group icons search-box">
                                            <Dropdown>
                                                <Dropdown.Toggle variant="success" className='h-45 transperent-box light-border-around text-white' id="dropdown-basic">
                                                    <b className='px-5'>{DROPDOWN_OPTIONS[this.state.selectedIndex].name}</b>

                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    {
                                                        DROPDOWN_OPTIONS.map((game, i) => {
                                                            return <Dropdown.Item key={i} onClick={() => this.setState({ selectedIndex: i })}>{game.name} </Dropdown.Item>
                                                        })
                                                    }
                                                </Dropdown.Menu>
                                            </Dropdown>
                                            <DebounceInput minLength={3}
                                                debounceTimeout={500}
                                                type="search"
                                                className="ml-2 form-control rounded text-white transperent-box light-border-around"
                                                placeholder="Search in table ...."
                                                maxLength={100}
                                                onChange={(e) => { this.onSearch(e.target.value) }} />
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <TableComponent column={this.columns} data={this.state.users} paginationTotalRows={this.state.totalResult} onPagination={this.onPagination} />
                                </div>
                            </div>
                        :
                            <ViewUserDetails viewUserDetailsId={this.state.viewUserDetailsId} viewUserDetails={this.state.viewUserDetails} updateState={this.updateState}/>
                        }
                     </div>
                </div>
            </div>

        )
    }
}
