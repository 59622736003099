import { Component } from 'react'
import { TableComponent } from '../common/DataTable';
import { getBettingOrgDetails, routeTo } from '../../utils/common.utils';
import { BREADCRUMBS } from '../../utils/breadcrumbs.utils';
import { Button, Dropdown } from 'react-bootstrap';
import VerificationService from '../../services/verification.service';
import { DebounceInput } from 'react-debounce-input';

const DROPDOWN_OPTIONS = [
    // { name: 'Org ID', term: '_id' },
    { name: 'Org Name', term: 'name' },
]

export default class Organisation extends Component {
    constructor(props) {
        super(props)
        this.state = {
            organisations: [],
            totalResult: 0,
            params: {
                pageNo: 0,
                limit: 10
            },
            selectedIndex: 0
        }
        
        this.columns = [
            // {
            //     name: "ORG ID",
            //     selector: (row) => [row.organizationId],
            //     sortable: true,
            // },
            {
                name: "ORG's NAME",
                selector: (row) => [row.organizationName],
                sortable: true,
            },
            {
                name: "MEMBERS",
                selector: (row) => [row.members],
                sortable: true,
            },
            {
                name: "TOURNAMENTS",
                selector: (row) => [row.tournamentCount],
                sortable: true,
            },
            {
                name: "RATING",
                selector: (row) => [row.rating],
                sortable: true,
            },
            {
                name: "VERIFIED",
                selector: (row) => [row.verified === 'VERIFIED' ? 'Yes' : 'No'],
                sortable: true,
            },
            {
              name: "ORG's AGE",
              selector: (row) => [`${row.organizationAge} days`],
              sortable: true,
            },
            {
              name: "BETTING STATUS",
              cell: (row) => <span className="d-flex"> 
                  <Button size="sm" onClick={() => this.updateOrgStatus(row.organizationId, true)} style={{opacity: row.isBettingEnabled ? 1 : 0.25}}>ON</Button>
                  <Button size="sm" variant="info" className="ms-2" onClick={() => this.updateOrgStatus(row.organizationId, false)} style={{opacity: !row.isBettingEnabled ? 1 : 0.25}}>OFF</Button>
                </span>,
                ignoreRowClick: true,
                allowOverflow: true
            },
        ];
        this.verificationApi = new VerificationService();
    }

    //isBettingEnabled

    componentDidMount() {
      getBettingOrgDetails(this.state.params, this.setBettingOrgDetails)
    }

    updateOrgStatus = (id, key) => { 
        let data = {isBettingEnabled : key};
        this.verificationApi.updateOrg(id, data).then(
            (res) => {
                getBettingOrgDetails(this.state.params, this.setBettingOrgDetails)
            }
          ).catch(
            (err) => {
              console.error(err)
            }
          )
    }

    setBettingOrgDetails = (res) => {
      let organisations = res.data.result.map((el, i) => { el.index = i; return el })
      this.setState({ organisations: organisations, totalResult: res.data.totalResults })
    }

    onPagination = (i, key) => {
      let paramsTemp = this.state.params
      paramsTemp[key] = i
      this.setState({ params: paramsTemp })
      //console.log(`${key}`, paramsTemp)
      getBettingOrgDetails(paramsTemp, this.setBettingOrgDetails)
    }

    onSearch = (val) => {
        let params = {
            [DROPDOWN_OPTIONS[this.state.selectedIndex].term]: val,
            pageNo: 0,
            limit: this.state?.params?.limit ?this.state.params.limit : 10
        }
        if (!val) delete params[DROPDOWN_OPTIONS[this.state.selectedIndex].term];
        this.setState({ params: params })
        //this.searchOrgs(params)
        getBettingOrgDetails(params, this.setBettingOrgDetails)
    }

    render() {
        return (
            <div className="app-main__inner">
                <div className="d-flex flex-column flex-lg-row">
                    <div className="flex-grow-1">
                        <h3 className='text-white'>{BREADCRUMBS.ORGANISATION.HEADING}</h3>


                    </div>
                    <div className="">
                        <ol className="breadcrumb color-white" style={{ background: 'none', padding: '0px' }}>
                            {BREADCRUMBS.ORGANISATION.PATH.map((route, i) => {
                                return <li key={i} className="breadcrumb-item cursor-pointer" onClick={() => { routeTo('/', 'dashboard') }}>{route}</li>
                            })}
                        </ol>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="main-card mb-3 card transperent-card text-white p-4">
                            <div className="card-header no-background p-0 mb-2">
                                <div className="btn-actions-pane-right">
                                    {/* <div className="input-group icons search-box">
                                        <input type="search" className="form-control rounded text-white transperent-box light-border-around" placeholder="Search in table ...." />
                                    </div> */}
                                    <div className="input-group icons search-box">
                                        <Dropdown>
                                            <Dropdown.Toggle variant="success" className='h-45 transperent-box light-border-around text-white' id="dropdown-basic">
                                                <b className='px-5'>{DROPDOWN_OPTIONS[this.state.selectedIndex].name}</b>

                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                {
                                                    DROPDOWN_OPTIONS.map((game, i) => {
                                                        return <Dropdown.Item key={i} onClick={() => this.setState({ selectedIndex: i })}>{game.name} </Dropdown.Item>
                                                    })
                                                }
                                            </Dropdown.Menu>
                                        </Dropdown>
                                        <DebounceInput minLength={3}
                                            debounceTimeout={500}
                                            type="search"
                                            maxLength={100}
                                            className="ml-2 form-control rounded text-white transperent-box light-border-around"
                                            placeholder="Search in table ...."
                                            onChange={(e) => { this.onSearch(e.target.value) }} />
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <TableComponent column={this.columns} data={this.state.organisations} paginationTotalRows={this.state.totalResult} onPagination={this.onPagination} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
