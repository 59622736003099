
export const HEADING = {
    DASHBOARD: 'Dashboard',
    TOURNAMENTS: "Tournaments",
    GAME: "Game",
    TOTAL_REG_USERS: "Total Registered Users",
    TOTAL_DISPUTES: "Total Disputes",
    RESOLVED_DISPUTES: "Resolved Disputes",
    TOURNAMENTS_ENTRY_FEES: "Tournament Entry Fee",
    MIN_TICKET_FEE: "Min. Betting Ticket Fee",
    TO_TOURNAMENT_DEPOSIT: "To's Tournaments Deposit",
    TO_BET_DEPOSIT: "To's Betting Deposit",
    BET_COLLECTION: "Betting Collection",
    TOURNAMENTS_PRICE_POOL: "Tournament Price Pool",
    BET_PRICE_POOL: "Betting Price Pool",
    REFUND: "Refund",
    RATING: "Rating",
    TOURNAMENT_WINNER: "Tournament Winner",
    BET_WINNER: "Betting Winner",
    RAISED_DISPUTE: "Raised Dispute",
    AVERAGE_DISPUTE_TIME: "Average Dispute Resolved Time",
    PREMIUM: "Premium Tournament",
    REGULAR_TOURNAMENT: "Regular Tournament"
}

export const GAMES_ENUM = {
    ALL_GAMES: 'isGameEnabled',
    POINT_MATRIX: 'pointMatrix',
    BETTING: 'isBettingEnabled',
    ENTRY_FEE: 'isEntryFeeEnabled',
    PRICE_POOL: 'isPrizePoolEnabled',
}

export const PLACEHOLDER = {
    MAX_LENGTH: 'Enter max length',
    HASHTAG: 'Enter a hashtag',
    ENTER_STATEMENT: 'Enter a betting statement'
}

export const FORM_DATATYPES = ["Uppercase", "Lowercase", "Numeric", "Special", "Alpha-numeric"];

export const MONTS = ["JAN", "FEB", "MAR", "APR", "MAY", "JUNE", "JULY", "AUG", "SEP", "OCT", "NOV", "DEC"];

export const PARTICIPANTS_TYPE = {
    CUSTOM: "Custom",
    TEAM: "Teams",
    PLAYER: "Players"
};

export const WEBSITE = {
    INFORMATION_TEXT: {
        CLAN_ORG: {
            NAME: 'Clan & Organisation',
            CLAN_DESCRIPTION: "Clan's short description",
            ORG_DESCRIPTION: "Organisation's short description"
        },
        CREATE_TOURNAMENT: {
            NAME: 'Create Tournament',
            CATEGORY: 'Category',
            COMPETITION_TYPE: "Competition Type",
            PARTICIPANTS_TYPE: "Participation Type"
        },
        STAGE_DETAILS: {
            NAME: "Stage Detail's description",
            MAX_PARTICIPANTS: "Max number of participants",
            ENABLE_GRAND_FINALE: "Enable grand finale's description",
            MAXPARTICIPANTS: "Maximum participant's description",
            MAX_NO_OF_GROUPS: "Max number of group's description",
            PLAY_OFF_FORMAT: "Playoff format's description",
            QUALIFICATION_THRESHOLD: "Qualification threshold's description"
        }
    },
    STATIC_PAGES: {
        PRIVACY_POLICY: {
            NAME: 'Privacy policy',
            SET_UP_PRIVACY_POLICY: "Setup privacy policy"
        },
        FAQS: { NAME: "FAQ's" },
        BETTING_RULES: {
            NAME: "Betting Rules",
            SET_UP_BETTING_RULES: "Set up betting's rules"
        },
        CONTACT_ESPOTZ: {
            NAME: "Contact Espotz",
            SET_UP_CONTACT_ESPOTZ: " Setup Espotz Contact"
        },
        DISCLAIMER: {
            NAME: "Disclaimer",
            SET_UP_DISCLAIMER: "Setup disclaimerl"
        },
        PAYMENTS_OPTIONS: {
            NAME: "Paymet Options",
            SET_UP_PAYMENTS_OPTIONSY: "Setup Payment Options"
        },
        RULES: {
            NAME: "Rules",
            SET_UP_RULES: "Setup Rules"
        },
        SOCIAL_MEDIA_POLICY: {
            NAME: "Social Media Policy",
            SET_UP_SOCIAL_MEDIA_POLICY: "Setup Social Media Policy"
        },
        TERMS_AND_CONDITIONS: {
            NAME: "Terms and Conditions",
            SET_UP_TERMS_AND_CONDITIONS: "Setup terms and conditions"
        }
    },
    NEWS: {
        NAME: "News",
        ADD_MORE: "+  Add another news",
        NEWS_BANNER: "Banner",
        NEWS_HEADING: "Heading",
        NEWS_SOURCE: "Source Link",
        NEWS_SUMMARY: "Summary",
    },
    CONTACT: {
        NAME: "Contact Us",
        ADD_MORE: "+  Add another FAQ",
        CONTACT_US_EMAIL: "Contact Us Email ID",
        ESPOTZ_FACEBOOK_LINK: "Espotz's Facebook Page Address Link",
        ESPOTZ_TELEGRAM_LINK: "Espotz's Telegram Page Address Link",
        ESPOTZ_TWITTER_LINK: "Espotz's Twitter Page Address Link",
        ESPOTZ_WHATSAPP_LINK: "Espotz's WhatsApp group Link"
    },
    NOTIFICATIONS: {
        ALL_USERS: {
            NAME: "Set up user's notifications message",
            FORGET_PASSWORD: "Forgot password (Sent on Email)",
            ONBOARDING: "First time user onboarding"
        },
        CLAN: {
            NAME: "Set up clan's notifications message",
            DEACTIVATION_CLAN: "Deactivation of clan",
            DELETION_OF_CLAN: "Deletion of clan",
            INVITE_ACCEPTANCE: "Invitation acceptance",
            INVITE_REJECTION: "Invitation rejection",
            NEWLY_CLANS_WM: "Newly clans without members (sent on notification mail)",
            NEWLY_CLANS_WT: "Newly clans without team (sent on notification mail)",
            ROLE_DEMOTION: "Role Change",
            ROLE_INVITATION: "Role invitation",
        },
        TEAM: {
            NAME: "Set up team's notifications message",
            DEACTIVATION_OF_TEAM: "Deactivation of team",
            DELETION_OF_TEAM: "Deletion of team",
            MATCH_REMINDER: "Match reminder",
            ROLE_DEMOTION: "Role Demotion",
            TEAM_MEMBER_REASSIGN: "Team member's reassign",
            ROLE_INVITATION: "Role invitation",
            INVITE_ACCEPTANCE: "Invitation acceptance",
            INVITE_REJECTION: "Invitation rejection",
        },
        ORG: {
            NAME: "Set up organisation's notifications message",
            BUILD_NEW_TOURNAMENT: "Prompt to build new tournament",
            DEACTIVE_OF_ORG: "Deactivation of organisation",
            DELETION_OF_ORG: "Deletion of organisation",
            INVITE_ACCEPTANCE: "Invitation Acceptance",
            INVITE_REJECTION: "Invitation Rejection",
            ROLE_DEMOTION: "Role demotion",
            ROLE_INVITATION: "Role invitation",
            TOURNAMENT_NOT_HAPPENED_IN_48TH: "Tournament not happened in 48Hr",
            ROLE_REMOVAL: "Role Removel"
        },
        TOURNAMENT: {
            NAME: "Set up tournament's notifications message",
            NOTIFY_DISPUTES: "Notify disputes",
            NOTIFY_SOLVED_DISPUTES: "Notify solved disputes",
            NOTIFY_TEAM_PLAYS_SAME_GAME: "Notify team player's same game",
            NOTIFY_TO_NOT_UPDATE_SCORE: "Notify TO & player if score not updated",
            NOTIFY_TO_UPDATE_SCORE: "Notify TO to update score",
            NOTIFY_WINNER: "Notify winner's",
            TOURNAMENT_REMINDER: "Tournament reminder",
        },
        BETTING: {
            NAME: "Set up betting's notifications message",
            BET_LOST: "Bet lost",
            BET_WON: "Bet won",
            BUILD_NEW_TOURNAMENT: "Prompt to build new tournament"
        },
        DEN: {
            NAME: "Set up den's notifications message",
            FAILED_SPOTLIGHT_TXN: "Failed spotlight transaction",
            NOTIFY_FOLLOWER: "Notify user on new follower",
            NOTIFY_SPOTLIGHT: "Notify user when they spotlighted",
            NOTIFY_TAG: "Notify when someone tagged the user",
            SOMEONE_REACT_ON_POST: "Notify user when someone reacts on a post",
            TOP_FAN_ACHIEVEMENT: "Top fan achivement"
        },
        WALLET: {
            NAME: "Set up wallet's notifications message",
            BETTING_POOL_WON: "Betting pool won",
            CASH_DEPOSIT: "Cash deposite",
            CASH_WITHDRAW: "Cash withdraw",
            ERROR_WHILE_TXN: "Error while transaction",
            PRIZE_POOL_WON: "Price pool won"
        }
    },
    FORMS: {
        NAME: "Forms",
        ADD_MORE: "+  Add another form field",
    },
    BETTING: {
        BETTING_STATEMENTS: {
            ADD_MORE: "Add another statement"
        }
    },
    DEN:{
        HASHTAG: {ADD_MORE :"Add another hashtag"}
    }

}

export const POINTS_RANK = ["1st Rank", "2nd Rank", "3rd Rank", "4th Rank", "5th Rank", "6th Rank", "7th Rank", "8 - 12th Rank", "13 - 16th Rank", "Each kill",]

export const MANAGE_CRITERIA_ENUM = {
    CLAN: [{ title: 'Minimum tournaments played by the clan*', key: 'minMatchesPlayedByClan', maxLength: 5, maxValue: 9999, minValue: 0 }],
    ORGANISATION: [
        { title: 'Average star rating to qualify betting*', key: 'avgStarRatingToQualifyBetting', maxLength: 5, maxValue: 5, minValue: 0, isDecimal: true},
        { title: 'Sum of reviews recieved during tournaments*', key: 'sumOfReviewsRecievedDuringTournament', maxLength: 6, maxValue: 99999, minValue: 0},
        { title: 'Number of tournamnt arranged by a TO*', key: 'numberOfTournamentArrangedByTo', maxLength: 5, maxValue: 9999, minValue: 0 },
        { title: 'Verified tags required for betting*', key: 'verfiedTagRequiredForBetting', isDropdown: true }
    ],
    USER: [
        { title: 'Number of post in den*', key: 'noOfPostsInDen', maxLength: 6, maxValue: 9999, minValue: 0 },
        //{ title: 'Number of followers in den', key: 'noOfFollowerInDen', maxLength: 6, maxValue: 999999, minValue: 0 },
        { title: 'Verified tags required for betting*', key: 'verfiedTagRequiredForBetting', isDropdown: true }
    ],
    DEN: [
        { title: 'Number of post in den*', key: 'noOfPostsInDen', maxLength: 6, maxValue: 9999, minValue: 0 },
        { title: 'Number of followers in den*', key: 'noOfFollowerInDen', maxLength: 6, maxValue: 999999, minValue: 0 },
        { title: 'Verified tags required for betting*', key: 'verfiedTagRequiredForBetting', isDropdown: true }
    ]
}

export const COMMISSION_ENUM = {
    ENTRY_FEE_PRICE_POOL: [
        { title: 'Espotz commission on regular price pool(in %)', key: 'ESPOTZ_COMMMISSION_ON_RPC' },
        { title: 'Espotz commission on premium price pool(in %)', key: 'ESPOTZ_COMMMISSION_ON_PPC' },
        { title: 'Espotz commission on entry ticket for regular tournament(in %)', key: 'ESPOTZ_COMMMISSION_ON_TFRT' },
        { title: 'Espotz commission on entry ticket for premium tournament(in %)', key: 'ESPOTZ_COMMMISSION_ON_TFPT', isDropdown: true }
    ],
    WALLET: [
        { title: 'Espotz commission on deposite in Espotz wallet(in %)', key: 'ESPOTZ_COMMMISSION_ON_DIEW' },
        { title: 'Espotz commission on withdrawl in Espotz wallet(in %)', key: 'ESPOTZ_COMMMISSION_ON_WIEW' }
    ],
    DEN: [
        { title: 'Espotz commission on spotlight donation(in %)', key: 'ESPOTZ_COMMMISSION_ON_SD' },
    ],
    TOURNAMENT_CANCELLATION: [
        { title: 'Before registration begins(regular tournament)(in %)', key: 'BF_REG_BEGINS' },
        { title: 'In-between registration and tournament start date(regular tournament)(in %)', key: 'IN_BW_REG_TOURNAMENT_SD' },
        { title: 'Post tournament start date(regular tournament)(in %)', key: 'POST_TOURNAMENT_SD' },
        { title: 'Before registration begins(premium tournament)(in %)', key: 'BEFORE_REG_BEGIN' },
        { title: 'In-between registration and tournament start date(premium tournament)(in %)', key: 'IN_BW_REG_TOUR_SD', isDropdown: true },
        { title: 'Post tournament start date(premium tournament)(in %)', key: 'POST_TOURNAMENT_SD_PM' },
        { title: 'Player refund on entry tickets(in %)', key: 'PLAYER_REFUND_ET' }
    ],
    BETTING: [
        { title: 'Espotz betting pool convenience fee(Betting pool > Ticket collection)(in %)', key: 'ESPOTZ_BETTING_POOL_CF' },
        { title: 'Espotz betting pool convenience fee on extra collection(Betting pool < Ticket collection)(in %)', key: 'ESPOTZ_BETTING_POOL_COEC' }
    ],
}

export const EC_CURRENCY = [
    { title: 'Esport currency value(1 EC)', key: 'value' }
]

export const DEN_ENUM = {

    DEN_STAR: [
        { title: 'Den stars for comment upon a post', key: 'DS_COMMENT_POST' },
        { title: 'Den stars for daily login bonus', key: 'DS_DAILY_LOGIN_BONUS' },
        { title: 'Den stars for creating den post', key: 'DS_CREATE_DPOST' },
        { title: 'Den stars for creating Espotz TV post', key: 'DS_CREATE_ES_TVPOST' },
        { title: 'Den stars for interaction/reaction upon a post', key: 'DS_REACTION_POST' },
        { title: 'Den stars for giving spotlight', key: 'DS_SPOTLIGHT' },
        { title: 'Den stars for sharing a post', key: 'DS_SHARED_POST' },
        { title: 'Den stars for spinning daily spin wheel', key: 'DS_SPIN_WHEEL' }
    ],
    SPOTLIGHT: [
        { title: "Spotlight's first value(in EC)", key: 0 },
        { title: "Spotlight's second value(in EC)", key: 1 },
        { title: "Spotlight's third value(in EC)", key: 2 },
        { title: "Spotlight's fourth value(in EC)", key: 3 },
        { title: "Spotlight's fifth value(in EC)", key: 4 }
    ],
    SPIN_WHEEL: [
        { title: 'Spark', key: 'SPARK' },
        { title: 'Bomb', key: 'BOMB' },
        { title: 'Pan', key: 'PAN' },
        { title: 'Gun', key: 'GUN' },
        { title: 'Fire', key: 'FIRE' },
        { title: 'Hammer', key: 'HAMMER' }
    ],
    JOINING_INTERACTION_BONUS: [
        { title: 'Spark', key: 'SPARK' },
        { title: 'Bomb', key: 'BOMB' },
        { title: 'Pan', key: 'PAN' },
        { title: 'Gun', key: 'GUN' },
        { title: 'Fire', key: 'FIRE' },
        { title: 'Hammer', key: 'HAMMER' }
    ],
    ACHIEVEMENT_RANK_CRITERIA: [
        { title: 'Comments needed to get 1st rank', key: 'COMMENT_FIRST_RANK' },
        { title: 'Comments needed to get 2nd rank', key: 'COMMENT_SECOND_RANK' },
        { title: 'Comments needed to get 3rd rank', key: 'COMMENT_THIRD_RANK' },
        { title: 'Comments needed to get 4th rank', key: 'COMMENT_FORTH_RANK' },
        { title: 'Comments needed to get 5th rank', key: 'COMMENT_FIFTH_RANK' },
        { title: 'Spotlight needed to be donated to get 1st rank', key: 'SPOTLIGHT_FIRST_RANK' },
        { title: 'Spotlight needed to be donated to get 2nd rank', key: 'SPOTLIGHT_SECOND_RANK' },
        { title: 'Spotlight needed to be donated to get 3rd rank', key: 'SPOTLIGHT_THIRD_RANK' },
        { title: 'Spotlight needed to be donated to get 4th rank', key: 'SPOTLIGHT_FORTH_RANK' },
        { title: 'Spotlight needed to be donated to get 5th rank', key: 'SPOTLIGHT_FIFTH_RANK' }
    ],
    DEN_PROFILE_CRITERIA: [
        // { title: 'Comments needed to get 1st rank', key: 'COMMENT_FIRST_RANK' },
        // { title: 'Comments needed to get 2nd rank', key: 'COMMENT_SECOND_RANK' },
        // { title: 'Comments needed to get 3rd rank', key: 'COMMENT_THIRD_RANK' },
        // { title: 'Comments needed to get 4th rank', key: 'COMMENT_FORTH_RANK' },
        // { title: 'Comments needed to get 5th rank', key: 'COMMENT_FIFTH_RANK' },
        { title: 'Posts needed to get 1st rank', key: 'POST_FIRST_RANK' },
        { title: 'Posts needed to get 2nd rank', key: 'POST_SECOND_RANK' },
        { title: 'Posts needed to get 3rd rank', key: 'POST_THIRD_RANK' },
        { title: 'Posts needed to get 4th rank', key: 'POST_FORTH_RANK' },
        { title: 'Posts needed to get 5th rank', key: 'POST_FIFTH_RANK' }
    ],
    HASHTAGS: {
        ADD_HASHTAG: "+ Add another hashtag"
    },
    ACHIEVE_RANK_TITLE: {
        DEN_USERS: {
            NAME: 'Den Users',
            FIRST_LVL_ACHIEVE: "1st Level of achievement",
            SECOND_LVL_ACHIEVE: "2nd Level of achievement",
            THIRD_LVL_ACHIEVE: "3rd Level of achievement",
            FORTH_LVL_ACHIEVE: "4th Level of achievement",
            FIFTH_LVL_ACHIEVE: "5th Level of achievement",
        },
        CLANS: {
            NAME: 'Clans',
            FIRST_LVL_ACHIEVE: "1st Level of achievement",
            SECOND_LVL_ACHIEVE: "2nd Level of achievement",
            THIRD_LVL_ACHIEVE: "3rd Level of achievement",
            FORTH_LVL_ACHIEVE: "4th Level of achievement",
            FIFTH_LVL_ACHIEVE: "5th Level of achievement",
        },
        ORG: {
            NAME: 'Organisations',
            FIRST_LVL_ACHIEVE: "1st Level of achievement",
            SECOND_LVL_ACHIEVE: "2nd Level of achievement",
            THIRD_LVL_ACHIEVE: "3rd Level of achievement",
            FORTH_LVL_ACHIEVE: "4th Level of achievement",
            FIFTH_LVL_ACHIEVE: "5th Level of achievement",
        }
    },
    DEN_PROFILE_RANK_TITLE: {
        DEN_USERS: {
            NAME: 'Den Users',
            FIRST_LVL_RANK: "1st Level of rank",
            SECOND_LVL_RANK: "2nd Level of rank",
            THIRD_LVL_RANK: "3rd Level of rank",
            FORTH_LVL_RANK: "4th Level of rank",
            FIFTH_LVL_RANK: "5th Level of rank",
        },
        CLANS: {
            NAME: 'Clans',
            FIRST_LVL_RANK: "1st Level of rank",
            SECOND_LVL_RANK: "2nd Level of rank",
            THIRD_LVL_RANK: "3rd Level of rank",
            FORTH_LVL_RANK: "4th Level of rank",
            FIFTH_LVL_RANK: "5th Level of rank",
        },
        ORG: {
            NAME: 'Organisations',
            FIRST_LVL_RANK: "1st Level of rank",
            SECOND_LVL_RANK: "2nd Level of rank",
            THIRD_LVL_RANK: "3rd Level of rank",
            FORTH_LVL_RANK: "4th Level of rank",
            FIFTH_LVL_RANK: "5th Level of rank",
        }
    }

}

export const BETTING = {

    BETTING_CRITERIA: [
        { title: 'Average rating', key: 'averageRating' },
        { title: 'Number of reviews', key: 'noOfReviews' },
        { title: 'Number of tournaments organised', key: 'noOfTournamentsOrganised' },
        { title: 'Verification required', key: 'verficationRequired' }
    ]

}

export const PROFILE_ENUM = [
    {label: 'Name', key: 'name'},
    {label: 'Email', key: 'email'},
    {label: 'Role', key: 'role'},
]

export const CLAN_VIEWS = {
    CLANS: 'CLANS',
    TOURNAMENT_STATS: 'TOURNAMENT_STATS',
    MEMBERS: 'MEMBERS',
    TEAMS: 'TEAMS',
}

