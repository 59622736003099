import { Component } from 'react'
import CommissionService from '../../services/commission.service';
import { BREADCRUMBS } from '../../utils/breadcrumbs.utils';
import { errorHandler, routeTo, updateLoader } from '../../utils/common.utils';
import { EC_CURRENCY } from '../../utils/strings.utils';
import Confirmation from '../common/Confirmation';
import { Button, Modal } from 'react-bootstrap'
import { toast } from 'material-react-toastify';

export default class TournamentCancellation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reasons: [],
      addModal: false,
      newReason: '',
      deleteIndex: '',
      editId: ''
    }
    this.commissionApis = new CommissionService();

  }

  componentDidMount() {
    this.getData()
  }
  getData = () => {
    updateLoader(true)
    this.commissionApis.getReasons().then(
      (res) => {
        console.log(res.data.result)
        this.setState({ reasons: res.data.result, deleteIndex: '', newReason: '', addModal: false, editId: '' })
        updateLoader(false)
      }
    ).catch(
      (err) => {
        console.error(err)
        updateLoader(false)
        errorHandler(err)
      }
    )
  }

  createReason = () => {
    const data = {
      REASONS: this.state.newReason
    }
    updateLoader(true)
    if (this.state.editId) {
      this.commissionApis.updateReason(this.state.editId, data).then(
        (res) => {
          updateLoader(false)
          this.getData()
          toast.success('Reason updated successfully')
        }
      ).catch(
        (err) => {
          console.error(err)
          updateLoader(false)
          errorHandler(err)
        }
      )
    } else {
      this.commissionApis.createReason(data).then(
        (res) => {
          updateLoader(false)
          this.getData()
          toast.success('Reason added successfully')
        }
      ).catch(
        (err) => {
          console.error(err)
          updateLoader(false)
          errorHandler(err)
        }
      )
    }

  }

  deleteReason = () => {
    let id = this.state.reasons[(this.state.deleteIndex - 1)]._id;
    updateLoader(true)
    this.commissionApis.deleteReason(id).then(
      (res) => {
        updateLoader(false)
        this.getData()
        toast.success('Reason deleted successfully')
      }
    ).catch(
      (err) => {
        console.error(err)
        updateLoader(false)
        errorHandler(err)
      }
    )
  }
  changeValue = (val) => {
    if (val.length < 201) {
      this.setState({ newReason: val })
    }
  }
  render() {
    return (
      <div className="app-main__inner">
        <div className="d-flex flex-column flex-lg-row">
          <div className="flex-grow-1">
            <h3 className='text-white'>{BREADCRUMBS.CANCEL_TOURNAMENT.HEADING}</h3>


          </div>
          <div className="">
            <ol className="breadcrumb color-white" style={{ background: 'none', padding: '0px' }}>
              {BREADCRUMBS.CANCEL_TOURNAMENT.PATH.map((route, i) => {
                return <li key={i} className="breadcrumb-item cursor-pointer" onClick={() => { routeTo('/', 'dashboard') }}>{route}</li>
              })}
            </ol>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="main-card mb-3 card transperent-card text-white p-4">
              <div className="card-header no-background p-0 mb-2 ">Manage the list of tournaments cancellation reasons
                <div className="btn-actions-pane-right">


                </div>
              </div>

              <div className='row'>
                {
                  this.state.reasons.map((el, i) => {
                    return (
                      <div key={i} className='col-12 col-md-12 my-2'>
                        <label><span className='font-bold'>Reason #{i + 1}</span></label>
                        <div className="input-group">
                          <input type="text"
                            value={el.REASONS}
                            className="form-control transperent-box light-border-around text-gray"
                            aria-label="Recipient's username" aria-describedby="basic-addon2"
                            style={{ borderTopLeftRadius: '10px', borderBottomLeftRadius: '10px' }}
                            disabled={true}
                          />
                          <div className="input-group-append">
                            <button onClick={() => { this.setState({ editId: el._id, newReason: el.REASONS }) }} className="btn edt-btn light-border-around" type="button">
                              Edit
                            </button>
                            <button className="btn edt-btn light-border-around" onClick={() => this.setState({ deleteIndex: (i + 1) })} type="button" style={{ borderTopRightRadius: '10px', borderBottomRightRadius: '10px' }}>
                              Delete
                            </button>
                          </div>
                        </div>
                      </div>
                    )
                  })
                }

              </div>
              <div>
                <button onClick={() => { this.setState({ addModal: true }) }} className="btn edt-btn light-border-around" type="button">Add New Reason</button>
              </div>
            </div>
          </div>
        </div>
        <Modal show={this.state.addModal || this.state.editId} centered dialogClassName=''>
          <Modal.Body>
            <div className='modal-content' style={{ border: 'none', borderRadius: '0' }}>
              <h3 className='text-center text-white fw-bold'>{this.state.editId ? 'Update' : 'Add'} Reason</h3>
              <div className='my-2 text-center text-white fw-500'>
                <div className="input-group">
                  <input type="text"
                    value={this.state.newReason}
                    onChange={(e) => { this.changeValue(e.target.value) }}
                    placeholder='Enter reason'
                    className="form-control transperent-box light-border-around text-gray"
                    aria-label="Recipient's username" aria-describedby="basic-addon2"
                  // style={{ borderTopLeftRadius: '10px', borderBottomLeftRadius: '10px' }}
                  />
                </div>
              </div>
              <div className='d-flex justify-content-center my-3'>
                <Button disabled={!this.state.newReason} onClick={this.createReason} className="btn btn-primary fw-bold me-1">
                  {this.state.editId ? 'Update' : 'Add'}
                </Button>
                <Button onClick={() => { this.setState({ addModal: false, editId: '', newReason: '' }) }} className="btn-primary fw-bold me-1">
                  Close
                </Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal show={this.state.deleteIndex} centered dialogClassName=''>
          <Modal.Body>
            <Confirmation
              message={`Are you sure you want to delete this reason no. ${this.state.deleteIndex} from reason's ?`}
              onConfirm={this.deleteReason} onClose={() => this.setState({ deleteIndex: '' })}
            />
          </Modal.Body>
        </Modal>
      </div>
    )
  }
}
