
import React, { Component } from 'react';
import { FORM_DATATYPES, HEADING, PLACEHOLDER, POINTS_RANK, WEBSITE } from '../../utils/strings.utils';
import { getFormsDetails, getGamesDetails, getWebsiteDetails, routeTo, updateFormsDetails, updateLoader, updateWebsiteDetails } from '../../utils/common.utils';
import { BREADCRUMBS } from '../../utils/breadcrumbs.utils';

import "../../assets/css/switch.css";
import "../../assets/css/game.css";
import { toast } from 'material-react-toastify';
import Dropdown from 'react-bootstrap/Dropdown';
import { Modal } from 'react-bootstrap';
import FormField from '../popup/FormField';
import Confirmation from '../common/Confirmation';

var selectedForm;
export default class Forms extends Component {

    constructor(props) {
        super(props);
        this.state = { 
          gameDetails : null,
          formsDetails : null,
          selectedChoice : 0,
          addNewField : false,
          openConfirmationModal : false,
          deleteIndex : -1,
          editIndex: -1,
          updateValue : null
        };

        this.cases = ["Uppercase","Lowercase","Numeric","Special","Alpha-numeric"]
    }
    
  
    componentDidMount(){
      getGamesDetails(this.setGamesDetails);
      getFormsDetails(this.setFormsDetails);
    }

    setGamesDetails = (gameDetails) =>{
      this.setState({
        gameDetails: gameDetails
      });
    }

    setFormsDetails = (formsDetails) =>{
      this.setState({
        formsDetails: formsDetails,
        addNewField : false,
        deleteIndex : -1,
        openConfirmationModal : false,
        editIndex: -1,
        updateValue : null
      });
    }

    // Save chaged value in state (temporary)
    changeValue = (val) =>{
      this.setState({
        updateValue: (val ? val : 0)
      });
    }

    onSelectOption = (index) =>{
      this.setState({
        selectedChoice : index
      });
    }

    
    addNewField = (updatedForm) => {
      updateFormsDetails(updatedForm._id, {DESCRIPTION : updatedForm.DESCRIPTION}, this.updateGamesDetails);
    }

    updateGamesDetails = (formDetails) =>{
      const updatedFormsDetails = this.state.formsDetails;
      updatedFormsDetails[this.selectedChoice] = formDetails;
      this.setFormsDetails(updatedFormsDetails);
    }

     // On Delete confirmation
     confirmDelete = () => {
      var DESCRIPTION = selectedForm[0].DESCRIPTION;
      DESCRIPTION.splice((this.state.deleteIndex), 1);
      updateFormsDetails(selectedForm[0]._id, {DESCRIPTION : DESCRIPTION}, this.updateGamesDetails);
    }

    enableEditOption = (index) =>{
      this.setState({
        editIndex: index,
        updateValue : null
      });
    }

    saveAndCloseEditOption = () =>{
      var DESCRIPTION = selectedForm[0].DESCRIPTION;

      if(DESCRIPTION[this.state.editIndex].MAX_CHAR){
        DESCRIPTION[this.state.editIndex].MAX_CHAR = parseInt(this.state.updateValue)
      }else{
        DESCRIPTION[this.state.editIndex].FIX_CHAR = parseInt(this.state.updateValue)
      }

      updateFormsDetails(selectedForm[0]._id, {DESCRIPTION : DESCRIPTION}, this.updateGamesDetails);
    }
  
    onCheckChange = (label, index) => {
      var DESCRIPTION = selectedForm[0].DESCRIPTION;
      if(DESCRIPTION[index].CASE.includes(label)){
        DESCRIPTION[index].CASE = DESCRIPTION[index].CASE.filter(item => item !== label);
      }else{
        DESCRIPTION[index].CASE.push(label);
      }
      updateFormsDetails(selectedForm[0]._id, {DESCRIPTION : DESCRIPTION}, this.updateGamesDetails);
    }

    showLoader = (param) =>{
      updateLoader(param)
    }

  

    render() {

        if(this.state.gameDetails && this.state.formsDetails){

          selectedForm = this.state.formsDetails.filter((form, i) => form.gameId === this.state.gameDetails[this.state.selectedChoice]?._id )
          const {editIndex} = this.state;
          
          return (
            <div className="app-main__inner">
                {/* HEAD */}
                <div className="d-flex flex-column flex-lg-row">
                    {/* MAIN-HEAD */}
                    <div className="flex-grow-1">
                        <h3 className='text-light-white'>{BREADCRUMBS.FORMS.HEADING}</h3>
                    </div>
                    {/* BRAD-CRUMB */}
                    <div className="">
                        <ol className="breadcrumb color-white" style={{ background: 'none', padding: '0px' }}>
                            {BREADCRUMBS.FORMS.PATH.map((route,i)=>{
                                return <li key={i} className="breadcrumb-item cursor-pointer" onClick={() => { routeTo('/','dashboard')  }}>{route}</li>
                            })}
                        </ol>
                    </div>
                </div>
                {/* BODY */}
                <div className="row">
                    <div className="col-md-12 d-flex">
                        <div className="main-card mb-3 card transperent-card text-white p-4 max-width-1000 mx-auto">
                            {/* SUB-HEAD */}
                            <div className="card-header no-background p-0 mb-2 ">  Registartion form setup
                                {/* FILTER */}
                                <div className="btn-actions-pane-right">
                                 
                                  <Dropdown>
                                    <Dropdown.Toggle variant="success" className='transperent-box light-border-around text-white' id="dropdown-basic">
                                      <b className='px-5'>{this.state.gameDetails[this.state.selectedChoice].name}</b>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                      {this.state.gameDetails?.map((game,i)=>{
                                        if(this.state.selectedChoice !== i){
                                          return (
                                            <Dropdown.Item key={i} onClick={()=>this.onSelectOption(i)}>
                                              <b className='px-4'>{game.name}</b> 
                                            </Dropdown.Item>
                                          )
                                        }
                                      })}
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                            </div>
                            {/* MAIN_BODY */}
                            <div className="container">
                              <div className="row">
                                {selectedForm[0].DESCRIPTION.map((field, i)=>{

                                  const isEditEnabled = (editIndex === i);
                                  const actionLabel = isEditEnabled ? 'Save' : 'Edit';
                                  const action = isEditEnabled ? this.saveAndCloseEditOption : this.enableEditOption;
                                  const onCheckChange = this.onCheckChange;
                                  return (
                                  <div className="col-12 col-md-6" key={i}>
                                    <div className='my-3' key={1}>
                                      <label><span className=' font-bold'>{field.LABEL}</span></label>
                                      <div className="input-group">
                                      {/* MAX LENGTH */}
                                      <input type="number" 
                                          className="form-control transperent-box light-border-around text-gray"
                                          style={{borderTopLeftRadius:'10px', borderBottomLeftRadius: '10px'}}
                                          defaultValue={field.MAX_CHAR ? field.MAX_CHAR : field.FIX_CHAR }
                                          placeholder={PLACEHOLDER.MAX_LENGTH}
                                          autoComplete="off"
                                          readOnly={!isEditEnabled}
                                          onChange={(e)=>this.changeValue(e.target.value)} 
                                        />
                                        <div className="input-group-append ">
                                          <button onClick={()=> action(i) } className="btn btn-primary light-border-around fw-bold" type="button">{actionLabel}</button>
                                          <button onClick={()=>this.setState({ deleteIndex: i, openConfirmationModal: true })} className="btn btn-primary light-border-around fw-bold rounded-border-right" type="button">Delete</button>
                                        </div>
                                      </div>
                                      {/* INPUT TYPES */}
                                      
                                      <div className='row m-2'>
                                      {FORM_DATATYPES.map((type,index)=>{
                                        return (
                                            <div className="col-12 col-md-6 col-lg-4 form-check" key={index}>
                                              <input className="form-check-input" type="checkbox" onClick={()=>onCheckChange(type,i)} checked={field.CASE.includes(type)} value="" id={type} />
                                              <label className="form-check-label fs-12 check-text" htmlFor="flexCheckChecked">
                                                {type} Character
                                              </label>
                                            </div>
                                          )
                                      })}
                                      </div>
                                    </div>
                                  </div>
                                  )
                                })}
                              </div>
                              {/* ADD MORE FIELDS */}
                              <div className='d-flex '>
                                <div className="my-2 ms-2" >
                                  <button type="button" onClick={()=>this.setState({addNewField : true})} className="btn btn-primary">{WEBSITE.FORMS.ADD_MORE}</button>
                                </div>
                              </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal show={this.state.addNewField} centered dialogClassName=''>
                  <Modal.Body>
                    <FormField
                      message={`Are you sure you want to delete this reason no.  from reason's ?`}
                      gameDetails={this.state.gameDetails[this.state.selectedChoice]}
                      selectedForm={selectedForm[0]}
                      addNewField={this.addNewField}
                      onConfirm={this.deleteReason} onClose={() => this.setState({ addNewField : false })}
                    />
                  </Modal.Body>
                </Modal>
                <Modal show={this.state.openConfirmationModal} centered dialogClassName=''>
                  <Modal.Body>
                    <Confirmation  
                      message={`Are you sure you want to delete field '${selectedForm[0]?.DESCRIPTION[this.state.deleteIndex]?.LABEL}' from registration form ?`} 
                      onConfirm={this.confirmDelete} onClose={()=>this.setState({openConfirmationModal: false, deleteIndex: -1})} 
                    />
                  </Modal.Body>
                </Modal>
            </div>
          );
        }else{
          return null;
        }
       
    }
}